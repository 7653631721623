/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import useAutosizeTextArea from "./useAutosizeTextArea";

function Pengaturan() {
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [komisi, setKomisi] = useState({
    activeindex: null,
    merchant: null,
    driver: null,
    upline: null,
    jmlupline: null,
    typehitungDriver: null,
    typehitungMerchant: null,
    typehitungUpline: null,
    nolkanPotonganMerchantCash: null,
    biayaLayanan: null,
  });
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");

  const [typeKomisi, setTypeKomisi] = useState({
    driver: null,
    merchant: null,
    upline: null,
  });

  const [typeHitung, setTypeHitung] = useState({
    typehitungDriver: null,
    typehitungMerchant: null,
    typehitungUpline: null,
  });

  const handleChangeTypeKomisi = (key, value) => {
    setTypeKomisi({ ...typeKomisi, [key]: value });
  };

  console.log(typeKomisi);

  const handleChangeTypeHitung = (key, value) => {
    setTypeHitung({ ...typeHitung, [key]: value });
    setKomisi({ ...komisi, [key]: value });
  };

  const [resKomisi, setResKomisi] = useState(null);
  const [activeEditKomisi, setActiveEditKomisi] = useState(null);
  const [isActiveEditKomisi, setIsActiveEditKomisi] = useState(null);

  const [innerValue, setInnerValue] = useState("");

  useAutosizeTextArea(textAreaRef.current, innerValue);

  console.log(innerValue);

  useEffect(() => {
    getSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSetting = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbDelivery({ key: "allsetting" }))
    );
    let newData = [];
    let result = res?.data?.lobj || [];
    result.map((item) => {
      return newData.push({ ...item, isEditing: false });
    });
    let oldData = newData;
    const excludes = [
      "komisi.ride",
      "komisi.send",
      "komisi.market",
      "komisi.jasa",
      "komisi.food",
      "system.splash",
      "apps.whitelist",
      "system.menu",
      "system.kategori.buku",
      "system.topup",
      "apps.whitelist",
      "system.voucher.bulk",
      "system.menu.group.block",
      "system.kata",
      "system.maintenance.apk",
      "system.downline",
      "system.home",
    ];
    const data = oldData.filter((item) => !excludes.includes(item.idapps));
    setResult(data);
    console.log("data filter", data);
    let parsingKomisi = oldData?.filter((res) => res.idapps.includes("komisi"));
    let newKomisi = [];
    parsingKomisi.map((item) => {
      const jsonValue = JSON.parse(item.value);
      //change all jsonValue value to String format example {driver: 10, merchant: 10, upline: 10} to {driver: "10", merchant: "10", upline: "10"}
      //with looping all key in jsonValue
      let value = {};
      Object.keys(jsonValue).map((key) => {
        return (value = { ...value, [key]: String(jsonValue[key]) });
      });
      return newKomisi.push({ ...item, value: JSON.stringify(value) });
    });

    console.log(parsingKomisi);
    console.warn(newKomisi);
    setOldItem(newKomisi);
    setResKomisi(newKomisi);
  };

  const saveAction = async (payload) => {
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "savesetting",
          payload,
        })
      )
    );
    getSetting();
    setIsActiveEditKomisi(null);
    setTypeHitung({
      typehitungDriver: null,
      typehitungMerchant: null,
      typehitungUpline: null,
    });
    setTypeKomisi({
      driver: null,
      merchant: null,
      upline: null,
    });
  };

  const editAction = async (item, index) => {
    setInnerValue(item.value);
    const newArr = result.map((p) =>
      p.idapps === item.idapps ? { ...p, isEditing: !p.isEditing } : p
    );
    setResult(newArr);
    if (item.isEditing === true) {
      let value;
      value = item.value;
      const payload = JSON.stringify({
        list: [{ idapps: item.idapps, desc: item.desc, value }],
      });
      saveAction(payload);
    }
  };

  const editActionKomisi = async (item, index) => {
    setIsActiveEditKomisi(true);
    console.log(item, index);
    const newArr = resKomisi.map((p) =>
      p.idapps === item.idapps ? { ...p, isEditing: !p.isEditing } : p
    );
    setResKomisi(newArr);

    if (item.isEditing === true) {
      let value;
      let lastIndex = resKomisi.length - 1;
      let LastTwoIndex = resKomisi.length - 2;
      if (item.idapps === "komisi.food" || item.idapps === "komisi.jasa") {
        value = JSON.stringify({
          driver: typeKomisi?.driver == 0 ? komisi.driver : komisi.driver + "%",
          merchant:
            typeKomisi?.merchant == 0 ? komisi.merchant : komisi.merchant + "%",
          upline: typeKomisi?.upline == 0 ? komisi.upline : komisi.upline + "%",
          jmlupline: komisi.jmlupline,
          typehitungDriver: parseInt(komisi.typehitungDriver),
          typehitungMerchant: parseInt(komisi.typehitungMerchant),
          typehitungUpline: parseInt(komisi.typehitungUpline),
          biayaLayanan: parseInt(komisi.biayaLayanan),
          nolkanPotonganMerchantCash:
            komisi.nolkanPotonganMerchantCash == "true" ? true : false,
        });
      } else {
        if (index === lastIndex || index === LastTwoIndex) {
          value = JSON.stringify({
            driver:
              typeKomisi?.driver == 0 ? komisi.driver : komisi.driver + "%",
            upline:
              typeKomisi?.upline == 0 ? komisi.upline : komisi.upline + "%",
            jmlupline: komisi.jmlupline,
            typehitungDriver: parseInt(komisi.typehitungDriver),
            biayaLayanan: parseInt(komisi.biayaLayanan),
            typehitungUpline: parseInt(komisi.typehitungUpline),
          });
        } else {
          value = JSON.stringify({
            driver:
              typeKomisi?.driver == 0 ? komisi.driver : komisi.driver + "%",
            merchant:
              typeKomisi?.merchant == 0
                ? komisi.merchant
                : komisi.merchant + "%",
            upline:
              typeKomisi?.upline == 0 ? komisi.upline : komisi.upline + "%",
            jmlupline: komisi.jmlupline,
            typehitungDriver: parseInt(komisi.typehitungDriver),
            typehitungMerchant: parseInt(komisi.typehitungMerchant),
            biayaLayanan: parseInt(komisi.biayaLayanan),
            typehitungUpline: parseInt(komisi.typehitungUpline),
          });
        }
      }
      const payload = JSON.stringify({
        list: [{ idapps: item.idapps, desc: item.desc, value }],
      });

      saveAction(payload);
    }
  };

  const handleChange = async (item, value) => {
    const newArr = result.map((p) =>
      p.idapps === item.idapps ? { ...p, value: value } : p
    );
    setResult(newArr);
  };

  const handleChangeKomisi = async (key, value, index) => {
    setKomisi({ ...komisi, [key]: value, activeindex: index });
  };

  console.table("KOMISI", komisi);

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.desc?.toLowerCase().includes(query.toLowerCase())
            );
            setResKomisi(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="setting" />
          <Content>
            <PageHeading title="Pengaturan" />
            <div className="row">
              <div className="col-12">
                <div className="row clearfix">
                  {resKomisi?.map((it, id) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-12 col-sm-6"
                        key={id}
                      >
                        <div className="card card-topline-yellow">
                          <div className="card-body no-padding height-9 mt-1">
                            <ul className="list-group list-group-unbordered">
                              <li
                                className="list-group-item"
                                style={{ borderTop: "0px none" }}
                              >
                                <b>{it.desc}</b>
                              </li>
                              <li className="list-group-item">
                                {activeEditKomisi === it.idapps &&
                                it.isEditing ? (
                                  <div>
                                    Driver:{" "}
                                    <select
                                      className="form-control"
                                      name="type"
                                      onChange={(e) =>
                                        handleChangeTypeKomisi(
                                          "driver",
                                          e.target.value
                                        )
                                      }
                                      value={typeKomisi?.driver}
                                    >
                                      <option value="0">Value</option>
                                      <option value="1">Presentase</option>
                                    </select>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={komisi.driver}
                                      onChange={(e) =>
                                        handleChangeKomisi(
                                          "driver",
                                          e.target.value,
                                          id
                                        )
                                      }
                                    />
                                    <div className="bg-info d-flex flex-column p-2 mt-2">
                                      <small>
                                        - Jika minus, contoh -500 , berapapun
                                        ongkir dari user maka driver dipotong
                                        flat 500
                                      </small>
                                      <small>
                                        - Jika positif, contoh 1500 maka
                                        berapapun ongkirnya, penghasilan driver
                                        flat 1500
                                      </small>
                                      <small>
                                        - Jika persentase, misal 5% maka
                                        pendapatan driver 5% dari Ongkir
                                      </small>
                                    </div>
                                    <br />
                                    {JSON.parse(it.value).merchant && (
                                      <>
                                        Merchant:{" "}
                                        <select
                                          className="form-control"
                                          name="type"
                                          onChange={(e) =>
                                            handleChangeTypeKomisi(
                                              "merchant",
                                              e.target.value
                                            )
                                          }
                                          value={typeKomisi?.merchant}
                                        >
                                          <option value="0">Value</option>
                                          <option value="1">Presentase</option>
                                        </select>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={komisi.merchant}
                                          onChange={(e) =>
                                            handleChangeKomisi(
                                              "merchant",
                                              e.target.value,
                                              id
                                            )
                                          }
                                        />
                                        <div className="bg-info d-flex flex-column p-2 mt-2">
                                          <small>
                                            - Jika minus, contoh -500 maka
                                            berapapun nilai transaksi barang
                                            maka merchant dipotong flat 500
                                          </small>
                                          <small>
                                            - Jika positif, contoh 1500 maka
                                            berapapun nilai transaksi barang,
                                            penghasilan merchant flat 1500
                                          </small>
                                          <small>
                                            - Jika persentase, misal 5% maka
                                            pendapatan merchant 5% dari Barang
                                          </small>
                                        </div>
                                        <br />
                                      </>
                                    )}
                                    Upline:{" "}
                                    <select
                                      className="form-control"
                                      name="type"
                                      onChange={(e) =>
                                        handleChangeTypeKomisi(
                                          "upline",
                                          e.target.value
                                        )
                                      }
                                      value={typeKomisi?.upline}
                                    >
                                      <option value="0">Value</option>
                                      <option value="1">Presentase</option>
                                    </select>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={komisi.upline}
                                      onChange={(e) =>
                                        handleChangeKomisi(
                                          "upline",
                                          e.target.value,
                                          id
                                        )
                                      }
                                    />
                                    <br />
                                    Jumlah Upline:{" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={komisi.jmlupline}
                                      onChange={(e) =>
                                        handleChangeKomisi(
                                          "jmlupline",
                                          e.target.value,
                                          id
                                        )
                                      }
                                    />
                                    <br />
                                    Type Hitung Driver:{" "}
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      value={komisi.typehitungDriver}
                                      onChange={(e) =>
                                        handleChangeKomisi(
                                          "typehitungDriver",
                                          e.target.value,
                                          id
                                        )
                                      }
                                    /> */}
                                    <select
                                      className="form-control"
                                      name="type"
                                      onChange={(e) =>
                                        handleChangeTypeHitung(
                                          "typehitungDriver",
                                          e.target.value
                                        )
                                      }
                                      readOnly
                                      disabled
                                      value={typeHitung?.typehitungDriver}
                                    >
                                      <option value="0">DARI_ONGKIR</option>
                                      <option value="1">BARANG</option>
                                      <option value="2">ONGKIR_BARANG</option>
                                    </select>
                                    <br />
                                    {JSON.parse(it.value)
                                      .typehitungMerchant && (
                                      <>
                                        Type Hitung Merchant:{" "}
                                        {/* <input
                                          type="text"
                                          className="form-control"
                                          value={komisi.typehitungMerchant}
                                          onChange={(e) =>
                                            handleChangeKomisi(
                                              "typeHitungMerchant",
                                              e.target.value,
                                              id
                                            )
                                          }
                                        /> */}
                                        <select
                                          className="form-control"
                                          name="type"
                                          onChange={(e) =>
                                            handleChangeTypeHitung(
                                              "typehitungMerchant",
                                              e.target.value
                                            )
                                          }
                                          readOnly
                                          disabled
                                          value={typeHitung?.typehitungMerchant}
                                        >
                                          <option value="0">DARI_ONGKIR</option>
                                          <option value="1">BARANG</option>
                                          <option value="2">
                                            ONGKIR_BARANG
                                          </option>
                                        </select>
                                        <br />
                                      </>
                                    )}
                                    Biaya Layanan:{" "}
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={komisi.biayaLayanan}
                                      onChange={(e) =>
                                        handleChangeKomisi(
                                          "biayaLayanan",
                                          e.target.value,
                                          id
                                        )
                                      }
                                    />
                                    <br />
                                    Type Hitung Upline:{" "}
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      value={komisi.typehitungUpline}
                                      onChange={(e) =>
                                        handleChangeKomisi(
                                          "typehitungUpline",
                                          e.target.value,
                                          id
                                        )
                                      }
                                    /> */}
                                    <select
                                      className="form-control"
                                      name="type"
                                      onChange={(e) =>
                                        handleChangeTypeHitung(
                                          "typehitungUpline",
                                          e.target.value
                                        )
                                      }
                                      value={typeHitung?.typehitungUpline}
                                    >
                                      <option value="0">DARI_ONGKIR</option>
                                      <option value="1">BARANG</option>
                                      <option value="2">ONGKIR_BARANG</option>
                                    </select>
                                    {(it.idapps === "komisi.food" ||
                                      it.idapps === "komisi.jasa") && (
                                      <>
                                        <br />
                                        <div className="form-group">
                                          <label>
                                            Nolkan Potongan Merchant Cash:{" "}
                                          </label>
                                          <select
                                            className="form-control"
                                            name="type"
                                            onChange={(e) =>
                                              handleChangeKomisi(
                                                "nolkanPotonganMerchantCash",
                                                e.target.value,
                                                id
                                              )
                                            }
                                            value={
                                              komisi?.nolkanPotonganMerchantCash
                                            }
                                          >
                                            <option value="true">TRUE</option>
                                            <option value="false">FALSE</option>
                                          </select>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className="pull-left"
                                    style={{
                                      inlineSize: "100%",
                                      overflowWrap: "break-word",
                                    }}
                                  >
                                    Driver: {JSON.parse(it.value).driver} <br />
                                    <div className="bg-info d-flex flex-column p-2">
                                      {!JSON.parse(it.value).driver.includes(
                                        "%"
                                      ) &&
                                        JSON.parse(it.value).driver < 0 && (
                                          <small>
                                            Berapapun ongkir dari user maka
                                            driver dipotong flat{" "}
                                            {JSON.parse(it.value).driver}
                                          </small>
                                        )}
                                      {!JSON.parse(it.value).driver.includes(
                                        "%"
                                      ) &&
                                        JSON.parse(it.value).driver > 0 && (
                                          <small>
                                            Berapapun ongkirnya, penghasilan
                                            driver flat{" "}
                                            {JSON.parse(it.value).driver}
                                          </small>
                                        )}
                                      {JSON.parse(it.value).driver.includes(
                                        "%"
                                      ) && (
                                        <small>
                                          Pendapatan driver{" "}
                                          {JSON.parse(it.value).driver} dari
                                          Ongkir
                                        </small>
                                      )}
                                    </div>
                                    {JSON.parse(it.value).merchant && (
                                      <>
                                        Merchant:{" "}
                                        {JSON.parse(it.value).merchant} <br />
                                        <div className="bg-info d-flex flex-column p-2">
                                          {!JSON.parse(
                                            it.value
                                          ).merchant.includes("%") &&
                                            JSON.parse(it.value).merchant <
                                              0 && (
                                              <small>
                                                Berapapun ongkir dari user maka
                                                merchant dipotong flat{" "}
                                                {JSON.parse(it.value).merchant}
                                              </small>
                                            )}
                                          {!JSON.parse(
                                            it.value
                                          ).merchant.includes("%") &&
                                            JSON.parse(it.value).merchant >
                                              0 && (
                                              <small>
                                                Berapapun ongkirnya, penghasilan
                                                merchant flat{" "}
                                                {JSON.parse(it.value).merchant}
                                              </small>
                                            )}
                                          {JSON.parse(
                                            it.value
                                          ).merchant.includes("%") && (
                                            <small>
                                              Pendapatan merchant{" "}
                                              {JSON.parse(it.value).merchant}{" "}
                                              dari Ongkir
                                            </small>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    Upline: {JSON.parse(it.value).upline}
                                    <br />
                                    Jumlah Upline:{" "}
                                    {JSON.parse(it.value).jmlupline}
                                    <br />
                                    Type Hitung Driver:{" "}
                                    {JSON.parse(it.value).typehitungDriver}
                                    <br />
                                    {/* {JSON.parse(it.value)
                                      .typehitungMerchant && (
                                      <>
                                        Type Hitung Merchant:{" "}
                                        {
                                          JSON.parse(it.value)
                                            .typehitungMerchant
                                        }
                                        <br />
                                      </>
                                    )} */}
                                    {JSON.parse(it?.value)
                                      ?.typehitungMerchant && (
                                      <div>
                                        Type Hitung Merchant:{" "}
                                        {
                                          JSON.parse(it.value)
                                            .typehitungMerchant
                                        }
                                        <br />
                                      </div>
                                    )}
                                    Type Hitung Upline:{" "}
                                    {JSON.parse(it.value).typehitungUpline}
                                    <br />
                                    {/* {it.idapps !== "komisi.market" && ( */}
                                    <>
                                      Biaya Layanan:{" "}
                                      {JSON.parse(it.value).biayaLayanan}
                                      <br />
                                    </>
                                    {/* )} */}
                                    {(it.idapps === "komisi.food" ||
                                      it.idapps === "komisi.jasa") && (
                                      <>
                                        Nolkan Potongan Merchant Cash:{" "}
                                        {String(
                                          JSON.parse(it.value)
                                            ?.nolkanPotonganMerchantCash ?? "-"
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </li>
                            </ul>
                            <div className="profile-userbuttons">
                              <button
                                type="button"
                                className={`mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle ${
                                  activeEditKomisi === it.idapps && it.isEditing
                                    ? "btn-danger"
                                    : "btn-warning"
                                }`}
                                data-upgraded=",MaterialButton,MaterialRipple"
                                onClick={() => {
                                  if (
                                    isActiveEditKomisi &&
                                    it.idapps !== activeEditKomisi
                                  )
                                    return;
                                  setActiveEditKomisi(it.idapps);
                                  // if (setActiveEditKomisi !== null) return;
                                  // if (activeEditKomisi === it.idapps) {
                                  let lastIndex = resKomisi.length - 1;
                                  let LastTwoIndex = resKomisi.length - 2;

                                  let driver = JSON.parse(it.value).driver;
                                  let upline = JSON.parse(it.value).upline;
                                  let jmlupline = JSON.parse(
                                    it.value
                                  ).jmlupline;
                                  let typehitungDriver = JSON.parse(
                                    it.value
                                  ).typehitungDriver;
                                  let typehitungUpline = JSON.parse(
                                    it.value
                                  ).typehitungUpline;

                                  let biayaLayanan =
                                    JSON.parse(it.value).biayaLayanan || 0;

                                  //SET TYPE KOMISI IF INCLUDES % THEN CHANGE VALUE TO 1 ELSE 0

                                  let type_driver;
                                  let type_upline;
                                  if (
                                    JSON.parse(it.value).driver.includes("%")
                                  ) {
                                    type_driver = 1;
                                    // handleChangeTypeKomisi("driver", 1);
                                  } else {
                                    type_driver = 0;
                                    // handleChangeTypeKomisi("driver", 0);
                                  }

                                  if (
                                    JSON.parse(it.value).upline.includes("%")
                                  ) {
                                    type_upline = 1;
                                    // handleChangeTypeKomisi("upline", 1);
                                  } else {
                                    type_upline = 0;
                                    // handleChangeTypeKomisi("upline", 0);
                                  }

                                  let type_merchant;
                                  if (
                                    JSON.parse(it.value)?.merchant?.includes(
                                      "%"
                                    )
                                  ) {
                                    type_merchant = 1;
                                    // handleChangeTypeKomisi("merchant", 1);
                                  } else {
                                    type_merchant = 0;
                                    // handleChangeTypeKomisi("merchant", 0);
                                  }

                                  setTypeKomisi({
                                    ...typeKomisi,
                                    driver: type_driver,
                                    upline: type_upline,
                                    merchant: type_merchant,
                                  });

                                  //SET TYPE HITUNG
                                  setTypeHitung({
                                    typehitungDriver,
                                    typehitungMerchant: JSON.parse(it.value)
                                      .typehitungMerchant,
                                    typehitungUpline,
                                  });

                                  driver = driver.replace("%", "");
                                  upline = upline.replace("%", "");

                                  if (
                                    it.idapps === "komisi.food" ||
                                    it.idapps === "komisi.jasa"
                                  ) {
                                    let nolkanPotonganMerchantCash = JSON.parse(
                                      it.value
                                    ).nolkanPotonganMerchantCash;
                                    let merchant = JSON.parse(
                                      it?.value
                                    )?.merchant;
                                    let typehitungMerchant = JSON.parse(
                                      it?.value
                                    )?.typehitungMerchant;

                                    merchant = merchant?.replace("%", "");

                                    setKomisi({
                                      activeindex: id,
                                      driver,
                                      merchant,
                                      upline,
                                      jmlupline,
                                      typehitungDriver,
                                      typehitungMerchant,
                                      typehitungUpline,
                                      nolkanPotonganMerchantCash,
                                      biayaLayanan,
                                    });
                                  } else {
                                    if (
                                      id === lastIndex ||
                                      id === LastTwoIndex
                                    ) {
                                      setKomisi({
                                        activeindex: id,
                                        driver,
                                        upline,
                                        jmlupline,
                                        typehitungDriver,
                                        typehitungUpline,
                                        biayaLayanan,
                                      });
                                    } else {
                                      let merchant = JSON.parse(
                                        it.value
                                      ).merchant;
                                      let typehitungMerchant = JSON.parse(
                                        it.value
                                      ).typehitungMerchant;

                                      merchant = merchant.replace("%", "");

                                      let type_merchant;
                                      if (
                                        JSON.parse(it.value).merchant.includes(
                                          "%"
                                        )
                                      ) {
                                        type_merchant = 1;
                                        // handleChangeTypeKomisi("merchant", 1);
                                      } else {
                                        type_merchant = 0;
                                        // handleChangeTypeKomisi("merchant", 0);
                                      }

                                      setTypeKomisi({
                                        ...typeKomisi,
                                        merchant: type_merchant,
                                      });

                                      setKomisi({
                                        activeindex: id,
                                        driver,
                                        merchant,
                                        upline,
                                        jmlupline,
                                        typehitungDriver,
                                        typehitungMerchant,
                                        typehitungUpline,
                                        biayaLayanan,
                                      });
                                    }
                                  }

                                  //validasi if any typeKomisi == 1 then input has % throw error "Masukkan angka saja tanpa %"
                                  if (
                                    typeKomisi?.driver == 1 ||
                                    typeKomisi?.merchant == 1 ||
                                    typeKomisi?.upline == 1
                                  ) {
                                    if (komisi?.driver?.includes("%")) {
                                      return toast.error(
                                        "Masukkan angka saja tanpa %"
                                      );
                                    }
                                    if (komisi?.merchant?.includes("%")) {
                                      return toast.error(
                                        "Masukkan angka saja tanpa %"
                                      );
                                    }
                                    if (komisi?.upline?.includes("%")) {
                                      return toast.error(
                                        "Masukkan angka saja tanpa %"
                                      );
                                    }
                                  }
                                  editActionKomisi(it, id);
                                  // }
                                }}
                              >
                                {it.isEditing ? "Simpan" : "Edit"}
                                <span className="mdl-button__ripple-container">
                                  <span className="mdl-ripple"></span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {result?.map((it, id) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-12 col-sm-6"
                        key={id}
                      >
                        <div className="card card-topline-yellow">
                          <div className="card-body no-padding height-9 mt-1">
                            <ul className="list-group list-group-unbordered">
                              <li
                                className="list-group-item"
                                style={{ borderTop: "0px none" }}
                              >
                                <b>{it.desc}</b>
                              </li>
                              <li className="list-group-item">
                                {it.isEditing ? (
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    value={it.value}
                                    rows={6}
                                    ref={textAreaRef}
                                    onChange={(e) =>
                                      handleChange(it, e.target.value)
                                    }
                                  />
                                ) : (
                                  <div
                                    className="pull-left"
                                    style={{
                                      inlineSize: "100%",
                                      overflowWrap: "break-word",
                                      whiteSpace: "pre-wrap",
                                    }}
                                  >
                                    {it.value}
                                  </div>
                                )}
                              </li>
                            </ul>
                            <div className="profile-userbuttons">
                              <button
                                type="button"
                                className={`mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle ${
                                  it.isEditing ? "btn-danger" : "btn-warning"
                                }`}
                                data-upgraded=",MaterialButton,MaterialRipple"
                                onClick={() => {
                                  editAction(it, id);
                                }}
                              >
                                {it.isEditing ? "Simpan" : "Edit"}
                                <span className="mdl-button__ripple-container">
                                  <span className="mdl-ripple"></span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default Pengaturan;
