export const defaultConfig = {
    Operator_Produk_Pay: {
      jenis: "pulsa",
      update: "false",
    },
    SubmenuMulti: {
      update: "false",
      posisi: "6",
      frame: "0",
    },
    ListProdukGrouped: {
      jenis: "data",
      update: "false",
    },
    TokenPlnInq: {
      kodeprodukpinq: "CEKPLNT",
      kodeprodukpay: "PLNT",
      jenisinq: "5",
      operator: "PLNT",
      jenispay: "6",
      update: "false",
    },
    InjectScreen: {
      jenis: "",
      update: "false",
      operator: "",
    },
    InjectScreenModif: {
      jenis: "",
      update: "true",
      operator: "",
    },
    ListProduk: {
      update: "false",
      operator: "",
    },
    InqPay: {
      kodeprodukpinq: "CEKTELKOM",
      jenisinq: "5",
      kodeprodukpay: "BYRTELKOM",
      jenispay: "6",
      update: "false",
    },
    PajakPbb: {
      fee: "1000",
      update: "false",
      moduleid: "331",
      frame: "0",
    },
    Samsat: {
      fee: "1000",
      update: "false",
      moduleid: "331",
    },
    PDAMForm: {
      update: "false",
      operator: "CPAMJT",
    },
    EMoneyAmount: {
      operator: "",
      jenispay: "",
      update: "false",
    },
    EcommerceInqPay: {
      kodeprodukpinq: "CEKBRIVA",
      jenisinq: "5",
      kodeprodukpay: "BRIVA",
      jenispay: "6",
      update: "false",
    },
    TransferBankNew: {
      update: "false",
      operator: "CBANK",
      minTransaction: "5000",
      posisi: "3",
      notes: `<h1>Keterangan</h1>
              <p>Silahkan pergi ke ATM untuk melakukan pengecekan mutasi transfer</p>
              <p>Atau bisa dari internet banking / mobile banking anda. </p>`,
      gambar: "",
      key: "bank",
    },
    TransferBankQu: {
      update: "false",
      minTransaction: "2000",
      moduleid: "331",
      kdProduk: "TRFB",
    },
    DonasiScreen: {
      update: "true",
      idrs: "OX003",
      notes: `<h1>Keterangan</h1>
              <p>Setelah memasukan pin utama, OTP akan dikirim ke Whatsapp anda</p>
              <p>Silahkan klik lanjut jika sudah menerima OTP, kemudian masukkan OTP pada kolom pin.</p>`,
    },
    whatsapp: {
      update: "true",
      wa: "",
      posisi: "17",
    },
    BulkVoucher: {
      update: "true",
      operator: "VKI",
      jenis: "",
      kodeproduk: "",
    },
    IndosatInqPay: {
      update: "false",
      jenisinq: "5",
      jenispay: "6",
      kodeprodukpinq: "CEKOMNI",
      kodeprodukpay: "BYROMNI",
      tipe: "elektrik",
      idoperator: "DIGI",
      pretik: "",
      opr: "telkomsel",
      apps: "apps.terbaik.omni",
      params: "kodebeli="
    },
    webview: {
      update: "false",
      idoperator: "OMNI",
      tipe: "elektrik",
      pretik: "",
      opr: "telkomsel",
      apps: "apps.terbaik.omni",
      params: "kodebeli="
    },
    KeretaForm: {
      fee: "1000",
      update: "true",
      moduleid: "331",
    },
    PesawatForm: {
      fee: "1000",
      update: "true",
      moduleid: "331",
    },
    HotelLocation: {
      fee: "1000",
      update: "true",
      moduleid: "331",
      frame: "1",
    },
    PelniForm: {
      fee: "1000",
      update: "true",
      moduleid: "331",
    },
    Markets: {
      fee: "1000",
      update: "true",
      moduleid: "331",
      type: "market",
      posisi: "18",
    },
    BottomNav: {
      update: "false",
      posisi: "14"
    },
    JasaEkspedisi:{
      fee: "1000",
      update: "false",
      moduleid: "331",
      type: "send",
      posisi: "13",
    },
    Drawer: {
      idlayanan: "jbf01",
      update: "true",
      moduleid: "331",
      type: "food",
      posisi: "16",
    },
    DrawerCab: {
      update: "false",
      moduleid: "331",
      type: "ride",
      posisi: "15",
    },
    LayananHome: {
      update: "true",
      moduleid: "331",
      type: "t04002"
    },
    McFoodishSplash: {
      update: "true"
    },
    McLayananSplash: {
      update: "true",
      type: "t04002"
    }
  };
  