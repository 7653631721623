import axios from "axios";
import { generateHeaders, uuid, baseHostUrl } from "../config";

export default {
  async JbDelivery(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return axios.post(
      `${baseUrl}/apps/jbdelivery/post`,
      { ...payload, uuid },
      config
    );
  },
  async JbMarket(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return axios.post(
      `${baseUrl}/apps/jbmarket/post`,
      { ...payload, uuid },
      config
    );
  },
  async JbMarketCustom(url, payload) {
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return axios.post(
      `${url}/apps/jbmarket/post`,
      { ...payload, uuid },
      config
    );
  },

  async JbMarketUUID(payload, uuidN) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return axios.post(
      `${baseUrl}/apps/jbmarket/post`,
      { ...payload, uuid: uuidN },
      config
    );
  },

  async SocketAPI(url, payload) {
    const baseUrlSocket = localStorage.getItem("urlSocket");
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return axios.post(`${baseUrlSocket}/${url}`, { ...payload, uuid }, config);
  },
};
