/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA } from "../../helpers";
// import AutocompletePaket from "./AutoComplete";

function SetPaketMenu({ history, location }) {
  const [result, setResult] = useState(null);
  const [form, setForm] = useState({
    client: "",
    selectedPaket: "",
  });
  const [menu, setMenu] = useState([]);
  const [modal, setModal] = useState(false);
  // const [query, setQuery] = useState("");
  const [clickQuery, setClickQuery] = useState(null);

  useEffect(() => {
    getPaketMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPaketMenu = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      idapps: "",
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/getpaket/?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = resultUrl?.data?.lobj || [];
    //append to the result variabel
    const newData = {
      idapps: "bts.paket.all",
      desc: "Semua Paket Menu",
      value: "",
      imgs: "",
    };
    //combine the result variabel with newData variabel
    const combine = [newData, ...result];
    setMenu(combine);
  };

  console.log(form);

  const getPaket = async () => {
    if (form.client === "") {
      toast.error("Client harus diisi");
      return;
    }
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.get(
      `https://api.setting.berkah-ts.my.id/juber/auth/paket/${form.client}?access_token=${token}`
    );
    const data = resultUrl.data;
    setResult(data?.msg);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      value: form?.selectedPaket?.idapps,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/paket/create/${form.client}?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success("Success Update Paket");
      setForm({
        client: "",
        selectedPaket: "",
      });
      setClickQuery(null);
    }
  };

  const [clients, setClients] = useState([]);

  useEffect(() => {
    getCLient();
  }, []);

  const getCLient = async () => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      setClients(client);
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="paket-menu-client" />
          <Content>
            <PageHeading title="Set Paket Menu Client" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="d-flex align-items-end">
                    <div className="form-group">
                      <label>Klien</label>
                      <select
                        className="form-control"
                        name="type"
                        onChange={handleChange("client")}
                        value={form?.client}
                      >
                        <option value="">Pilih Client</option>
                        {clients?.map((item, index) => {
                          return (
                            <option key={index} value={item?.username}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <Button
                      title="Lihat Paket Client"
                      type="danger"
                      onClick={() => getPaket()}
                      block={false}
                      style={{ marginLeft: "1rem" }}
                    />
                  </div>
                  <div onClick={() => setModal(true)}>
                    <Input
                      label="Paket"
                      placeholder="Pilih Paket"
                      value={form?.selectedPaket?.desc}
                      readOnly={true}
                      style={{ cursor: "pointer", width: "40%" }}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
                {result && (
                  <Card>
                    <div className="alert alert-success">{result}</div>
                  </Card>
                )}
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <div className="form-group">
            <label>Paket</label>
            <select
              className="form-control"
              name="type"
              onChange={(e) => setClickQuery(e.target.value)}
              value={form?.selectedPaket.desc}
            >
              <option value="">Pilih Paket</option>
              {menu?.map((item, index) => {
                return (
                  <option key={index} value={JSON.stringify(item)}>
                    {item?.desc}
                  </option>
                );
              })}
            </select>
          </div>
          {/* <AutocompletePaket
            suggestions={menu}
            onChangeText={(val) => setQuery(val)}
            onClickText={(val) => setClickQuery(val)}
          /> */}
          <div className="d-flex justify-content-center align-items-end mt-4">
            <Button
              title="Pilih"
              type="warning"
              onClick={() => {
                setForm({
                  ...form,
                  selectedPaket: {
                    ...JSON.parse(clickQuery),
                  },
                });
                setModal(false);
              }}
            />
            <Button
              title="Batal"
              type="danger"
              onClick={() => setModal(false)}
              style={{ width: 130, marginLeft: 10 }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(SetPaketMenu);
