/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
// import { formatRupiah } from "../../helpers";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function SimulasiTransaksi() {
  const dispatch = useDispatch();
  const [kodepesanan, setKodepesanan] = useState("");
  const [result, setResult] = useState({
    type: "",
    pembeli: {
      nama: "",
      alamatasal: "",
      alamatantar: "",
      total: "",
      status: "",
      job: "",
      no_telp: "",
    },
    driver: "",
    dokumen: {
      foto_terima: "",
      foto_antar: "",
    },
    merchant: {
      nama: "",
      alamat: "",
      telp: "",
    },
    barang: [],
  });

  const checkAction = async () => {
    if (kodepesanan.length === 0)
      return toast.error("Kode pesanan tidak boleh kosong");
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "simulasi",
          method: "POST",
          payload: JSON.stringify({ kodePesanan: kodepesanan }),
        }),
        false
      )
    );
    if (res.data.code != 200) return toast.error(res.data.msg);
    setResult(res?.data?.data);
  };

  console.log("??? ", result);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="simulasi-transaksi" />
          <Content>
            <PageHeading title="Simulasi Transaksi" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Kode Transaksi"
                    onChange={(e) => setKodepesanan(e.target.value)}
                    placeholder="Masukkan Kode Pesanan"
                  />
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => checkAction()}
                  />
                </Card>
              </div>
            </div>
            {result?.type?.length !== 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-warning">
                    Simulasi {result.kodePesanan}
                  </div>
                  <Card>
                    <h5>Pendapatan Driver : {result.pendapatanDriver}</h5>
                    <h5>Fee Upline : {result.feeUpline}</h5>
                    <h5>Harga Beli : {result.hargaBeli}</h5>
                    <h5>Biaya Layanan : {result.biayaLayanan}</h5>
                    <h5>
                      Pendapatan Server dari Driver :{" "}
                      {result.pendapatanServerDariDriver}
                    </h5>
                  </Card>
                </div>
              </div>
            )}
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default SimulasiTransaksi;
