/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";
function Hadiah({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kode: "",
    nama: "",
    jml_poin: "",
    gambar: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "daftarhadiah",
          method: "post",
        })
      )
    );
    console.log(res);
    const results = res?.data?.data?.lobj;
    setResult(results);
    setOldItem(results);
  };

  const updateData = async () => {
    const payload = {
      kode: form?.kode,
      gambar: form?.gambar,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "daftarhadiahutil/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      kode: "",
      nama: "",
      jml_poin: "",
      gambar: "",
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const saveAction = async () => {
    const payload = {
      name: form.name,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "ref_courier",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({ name: "" });
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, gambar: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(_fetch(GlobalService.deleteFoto(form?.gambar)));
    if (!res?.success) return;
    setForm({ ...form, gambar: "" });
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.name?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="hadiah" />
          <Content>
            <PageHeading title="List Hadiah" />
            <div className="row">
              <div className="col-12">
                {isEdit && (
                  <Card>
                    <Input label="Kode" value={form?.kode} readOnly disabled />
                    {form?.gambar?.length === 0 ? (
                      <Input
                        accept="image/*"
                        label="Ubah Gambar (Ukuran 1:1)"
                        type="file"
                        onChange={(e) => uploadImage(e?.target?.files[0])}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "9.5rem",
                          padding: 10,
                          border: "2px solid #d2d6de",
                          backgroundColor: "rgba(210, 210, 210, 0.25)",
                          color: "#ffffff",
                        }}
                        className="mb-3 d-flex flex-column"
                      >
                        <img
                          src={form.gambar}
                          alt=""
                          className="img-thumbnail mb-2"
                          style={{
                            height: "8rem",
                            width: "8rem",
                            objectFit: "contain",
                          }}
                        />
                        <div
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            borderRadius: 99,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="text-center d-flex justify-content-center align-items-center"
                          onClick={() => deleteImage()}
                        >
                          <i
                            className="material-icons"
                            style={{ color: "#E44F56", cursor: "pointer" }}
                          >
                            cancel
                          </i>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Simpan"}
                        type="warning"
                        onClick={() => (isEdit ? updateData() : saveAction())}
                      />
                      {isEdit && (
                        <Button
                          title="Batal Edit"
                          type="danger"
                          onClick={() => cancelEdit()}
                          style={{ width: 130, marginLeft: 10 }}
                        />
                      )}
                    </div>
                  </Card>
                )}
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Kode </th>
                        <th className=""> Nama </th>
                        <th className=""> Jml Poin </th>
                        <th className=""> Gambar </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item?.kode}</td>
                            <td className="">{item?.nama}</td>
                            <td className="">{item?.jml_poin}</td>
                            <td className="">
                              <img
                                src={item.gambar}
                                alt=""
                                style={{
                                  width: 90,
                                  height: 90,
                                  objectFit: "contain",
                                }}
                              />
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    kode: item?.kode,
                                    nama: item?.nama,
                                    jml_poin: item?.jml_poin,
                                    gambar: item?.gambar,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              {/* <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item?.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Hadiah);
