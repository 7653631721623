/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { BtoA } from "../../helpers";

function VAMitra() {
  const user = useSelector((state) => state.auth.user);
  const userSave = localStorage.getItem("credential");
  const btoa = BtoA("kocak123");
  const username = btoa(userSave);

  const idrs = user?.idrs;

  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kode_agen: idrs,
    customer_phone: "",
    customer_email: "",
    bank_code: "",
    clientid: username,
    urlimg: "dev",
    nmva: "",
    notes: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  const [banks, setBanks] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    getData();
    getBanks();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "virtualacc",
          method: "post",
          payload: JSON.stringify({ accnumber: "all", stsaktif: true }),
        })
      )
    );
    const result = res?.data?.data;
    console.log(result);
    setResult(result);
  };

  const getBanks = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "masterbanksupportva",
          method: "get",
        })
      )
    );
    const result = res?.data?.data;
    const banks = result;
    console.log(result);
    setBanks(banks || []);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      ...form,
    };

    // return;
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "createvadedicated",
          payload: JSON.stringify(payload),
        })
      )
    );
    clearForm();
    getData();
  };

  const clearForm = () => {
    setForm({
      kode_agen: idrs,
      customer_phone: "",
      customer_email: "",
      bank_code: "",
      clientid: username,
      urlimg: "dev",
      nmva: "",
      notes: "",
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    clearForm();
  };

  // const deleteAction = async (id) => {
  //   const payload = {
  //     id,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "post",
  //         key: "virtualacc/delete",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   clearForm();
  //   getData();
  // };

  const updateAction = async () => {
    const searchedBank = banks?.find((item) => item.kode == form?.bank_code);
    const payload = {
      id: selectedData?.idvir,
      value: {
        stsaktif: selectedData?.value?.stsaktif,
        accnumber: form?.nova,
        nmva: form?.nmva,
        //how to set the bank code
        "bank code": form?.bank_code,
        bank: searchedBank?.nama,
        supporttopup: selectedData?.value?.supporttopup,
        phone: form?.customer_phone,
      },
    };

    console.log(payload);
    // return;
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "virtualacc/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    setIsEdit(false);
    clearForm();
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="va-mitra" />
          <Content>
            <PageHeading title="Virtual Account Mitra" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Bank</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={handleChange("bank_code")}
                      value={form?.bank_code}
                    >
                      <option value="">Pilih Bank</option>
                      {banks?.map((item, index) => {
                        return (
                          <option key={index} value={item?.kode}>
                            {item?.nama}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Input
                    label="Nama Virtual Account"
                    onChange={handleChange("nmva")}
                    placeholder="Nama Virtual Account"
                    value={form?.nmva}
                  />
                  {isEdit && (
                    <Input
                      label="No VA"
                      onChange={handleChange("nova")}
                      placeholder="No VA"
                      value={form?.nova}
                      readOnly
                    />
                  )}
                  {!isEdit && (
                    <Input
                      label="Email"
                      onChange={handleChange("customer_email")}
                      placeholder="Email"
                      value={form?.customer_email}
                    />
                  )}
                  <Input
                    label="No Telp"
                    onChange={handleChange("customer_phone")}
                    placeholder="No Telp"
                    value={form?.customer_phone}
                  />
                  {!isEdit && (
                    <Input
                      label="Notes"
                      onChange={handleChange("notes")}
                      placeholder="Notes"
                      value={form?.notes}
                    />
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={isEdit ? "Update" : "Buat"}
                      type="warning"
                      onClick={() => (isEdit ? updateAction() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""># </th>
                        <th className=""> Nama VA </th>
                        <th className=""> No VA </th>
                        <th className=""> Bank </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">{item?.value?.nmva}</td>
                            <td className="">{item?.value?.accnumber}</td>
                            <td className="">{item?.value?.bank}</td>
                            <td className="">{item.kodebank}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    kode_agen: idrs,
                                    customer_phone: item?.value?.phone,
                                    customer_email: "",
                                    bank_code: item?.value["bank code"],
                                    clientid: username,
                                    urlimg: "dev",
                                    nmva: item?.value?.nmva,
                                    notes: "",
                                    nova: item?.value?.accnumber,
                                  });

                                  setSelectedData({ ...item });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              {/* <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item?.idvir);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(VAMitra);
