/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";
import axios from "axios";
import VirtualizedSelect from "react-virtualized-select";
import { BtoA } from "../../helpers";
function KategoriProduk09({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    nama: "",
    img: "",
    nextpage: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
    getSetting();
  }, []);

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, img: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(
      _fetch_noerror(GlobalService.deleteFoto(form?.image))
    );
    console.log(res);
    // if (!res?.success) return;
    setForm({ ...form, img: "" });
  };

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "kategoriproduk",
          method: "get",
        })
      )
    );
    console.log(res);
    setResult(res?.data?.data || []);
    setOldItem(res?.data?.data || []);
  };

  const updateCategory = async () => {
    const payload = {
      id: form?.id,
      nama: form?.nama,
      img: form?.img,
      nextpage: form?.nextpage,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "put",
          key: "kategoriproduk/updatenew",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      id: "",
      nama: "",
      img: "",
      nextpage: "",
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      img: "",
      nextpage: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      nama: form?.nama,
      img: form?.img,
      nextpage: form?.nextpage,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "kategoriproduk/create",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  const deleteAction = async (id) => {
    const payload = {
      id,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "delete",
          key: "kategoriproduk/delete",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  const [nextPage, setNextPage] = useState([]);

  const getSetting = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "systemmenu",
        value: JSON.stringify({ clientId: username }),
      }
    );
    let resultMextPage = resultUrl.data.lobj[0].nextpage;

    //split nextpage with delimiter -
    resultMextPage = resultMextPage.split("-");

    setNextPage(resultMextPage);
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.nama?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="kategori-produk-09" />
          <Content>
            <PageHeading title="Kategori Produk FE09" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Kategori Produk"
                    onChange={handleChange("nama")}
                    placeholder="contoh ewallet"
                    value={form?.nama}
                    readonly={true}
                  />
                  <VirtualizedSelect
                    id="nextpage"
                    name="nextpage"
                    // className="form-control"
                    placeholder="Next Page"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        nextpage: e.value,
                      });
                    }}
                    value={form?.nextpage}
                    options={nextPage.map((item, index) => ({
                      label: item,
                      value: item,
                    }))}
                  ></VirtualizedSelect>
                  {form?.img?.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Gambar"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={form.img}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama Kategori Produk </th>
                        <th className=""> Gambar </th>
                        <th className=""> Next Page </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.nama}</td>
                            <td className="">
                              <img
                                src={item.img}
                                alt=""
                                style={{ width: 100 }}
                              />
                            </td>
                            <td className="">{item.nextpage}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    id: item.id,
                                    nama: item.nama,
                                    img: item.img,
                                    nextpage: item.nextpage,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(KategoriProduk09);
