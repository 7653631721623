/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch_noerror, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function BulkVoucher({ history, location }) {
  let userAccount = useSelector((state) => state.auth);
  userAccount = userAccount?.user;
  console.table(userAccount);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    listProduk: "",
    maxTrx: "",
  });

  console.log(form);

  const getVoucherBulk = async () => {
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbMarket({
          method: "get",
          key: "voucherbulk",
        })
      )
    );

    if (res?.data?.code == 200) {
      setForm({
        listProduk: res?.data?.data?.listProduk,
        maxTrx: res?.data?.data?.maxTrx,
      });
    } else {
      toast.error("Gagal mengambil data");
    }
  };

  useEffect(() => {
    getVoucherBulk();
  }, []);

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const bulkAction = async () => {
    const res = await dispatch(
      _fetch_noerror(
        BridgeService.JbMarket({
          method: "post",
          key: "voucherbulk",
          payload: JSON.stringify(form),
        })
      )
    );

    if (res?.data?.code == 200) {
      toast.success("Berhasil atur produk bulk voucher");
    } else {
      toast.error(res?.data?.message);
    }

    getVoucherBulk();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="bulk-voucher" />
          <Content>
            <PageHeading title="Bulk Voucher" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <h4 style={{ fontWeight: "bold" }}>Produk Bulk Voucher</h4>
                  <label>
                    Silahkan masukkan kode kode produk yang dapat menggunakan
                    sistem transaksi bulk (massal). Biasanya digunakan untuk
                    produk aktivasi voucher/tembak voucher kosong massal.
                    Gunakan pemisah koma misal: VMN1,VMN2 dll
                  </label>
                  <Input
                    label="List Kode Produk Bulk Voucher"
                    onChange={handleChange("listProduk")}
                    placeholder=""
                    value={form?.listProduk}
                  />
                  <Input
                    label="Batas Jumlah Per Transaksi"
                    onChange={handleChange("maxTrx")}
                    placeholder="50"
                    value={form?.maxTrx}
                  />
                  <label>
                    Misalkan diset 50, maka jika agen menginput voucher awal
                    1827318300 dan voucher akhir 1827318400, maka transaksi akan
                    ditolak karena range voucher yang diinput 100. Transaksi
                    akan diproses jika range voucher yang diinput {`<= `}50,
                    misal voucher awal 1827318300, voucher akhir 1827318349.
                  </label>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Submit"}
                      type="warning"
                      onClick={() => bulkAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(BulkVoucher);
