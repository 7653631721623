/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
function HargaCoret({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kdproduk: "",
    hargapromo: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "hargacoret",
          method: "post",
          payload: JSON.stringify({ kdproduk: "all" }),
        })
      )
    );
    console.log(res);
    setResult(res?.data?.data || []);
    setOldItem(res?.data?.data || []);
  };

  const updateCategory = async () => {
    const payload = {
      kdproduk: form?.kdproduk,
      value: {
        hargapromo: form?.hargapromo,
      },
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "hargacoret/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      id: "",
      nama: "",
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      kdproduk: form?.kdproduk,
      value: {
        hargapromo: form?.hargapromo,
      },
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "hargacoret/create",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  const deleteAction = async (id) => {
    const payload = {
      kdproduk: id,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "hargacoret/delete",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.idapps?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="harga-coret" />
          <Content>
            <PageHeading title="Harga Coret" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Kode Produk"
                    onChange={handleChange("kdproduk")}
                    placeholder="contoh S10"
                    value={form?.kdproduk}
                    readOnly={isEdit ? true : false}
                  />
                  <label className="text-danger">
                    *Masukkan kode produk dan harga promo satu persatu
                  </label>
                  <Input
                    label="Harga Promo"
                    onChange={handleChange("hargapromo")}
                    placeholder="300"
                    value={form?.hargapromo}
                  />
                  <label className="text-danger">
                    *Harga promo bisa diisikan persen atau nominal
                  </label>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Kode Produk </th>
                        <th className=""> Value </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        const kode = item?.idapps.split(".")[2];
                        const harga = JSON.parse(item?.value);
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{kode}</td>
                            <td className="">{harga.hargapromo}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    kdproduk: kode,
                                    hargapromo: harga.hargapromo,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(kode);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(HargaCoret);
