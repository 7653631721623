/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { GlobalService } from "../../services";
import { _fetch } from "../../redux/actions/global";
import { useDispatch } from "react-redux";
import { BtoA } from "../../helpers";

function SettingLogo({ history, location }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    image: "",
    imageStorage: "",
  });

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    const storage = localStorage.getItem("logo") ?? "";
    setForm({
      image: "",
      imageStorage: storage,
    });
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, image: res.file });
  };

  const saveAction = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      desc: "setlogoclient",
      value: JSON.stringify({
        clientid: username,
        logourl: form.image,
      }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(`Success Update Logo`);
      const lobj = resultUrl?.data?.lobj[0];
      const parse = JSON.parse(lobj?.value);
      const logo = parse?.logo;
      localStorage.setItem("logo", logo);
      getSetting();
    }
  };

  useEffect(() => {
    getRegisterStatus();
  }, []);

  const [activeRegister, setActiveRegister] = useState(false);
  const [typeOtp, setTypeOtp] = useState({
    wa: false,
    sms: false,
  });
  const [otpExpInMinutes, setOtpExpInMinutes] = useState(0);
  const [otpExp, setOtpExp] = useState(0);
  const [groupRegister, setGroupRegister] = useState("");
  const [groupReg, setGroupReg] = useState("");
  const [activePin, setActivePin] = useState(true);

  const getRegisterStatus = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "getstatusregister",
        value: JSON.stringify({ clientid: username }),
      }
    );

    console.log(resultUrl.data);

    if (resultUrl.data.code == 500) {
      return toast.error(`Failed Get Status Register, ${resultUrl.data.msg}`);
    }
    const lobj = resultUrl?.data?.lobj[0];
    const visible = lobj?.isVisibleRegister;
    const typeOtp = lobj?.typeOtp;
    //split by comma and remove space then convert to array
    const split = typeOtp?.split(",").map((item) => item.trim());
    const wa = split?.includes("wa");
    const sms = split?.includes("sms");
    setTypeOtp({
      wa: wa,
      sms: sms,
    });

    setActiveRegister(visible);
    const otpExp = lobj?.otpExpInMinutes;
    const GroupRegister = lobj?.GroupRegister;
    const ActivePin = lobj?.activePin;

    setOtpExpInMinutes(otpExp);
    setOtpExp(otpExp);
    setGroupRegister(GroupRegister);
    setGroupReg(GroupRegister);
    setActivePin(ActivePin);
  };

  const changeRegister = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;
    const joinOtp = [];
    if (typeOtp?.wa) joinOtp.push("wa");
    if (typeOtp?.sms) joinOtp.push("sms");
    //join array with comma
    const otp = joinOtp.join(",");

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "setstatusregister",
        value: JSON.stringify({
          clientid: username,
          status: !activeRegister,
          typeOtp: otp,
          otpExpInMinutes: otpExpInMinutes,
          GroupRegister: groupRegister,
          activePin: activePin,
        }),
      }
    );

    if (resultUrl.data.code == 200) {
      toast.success(`Success Update Status Register`);
    } else {
      toast.error(`Failed Update Status Register`);
    }

    getRegisterStatus();
  };

  const changeOtp = async () => {
    if (!typeOtp?.wa && !typeOtp?.sms)
      return toast.error("Pilih Salah Satu Otp");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;
    const joinOtp = [];
    //if wa is true, then push to array, if wa and sms is true, then push to array too
    if (typeOtp?.wa) joinOtp.push("wa");
    if (typeOtp?.sms) joinOtp.push("sms");
    //join array with comma
    const otp = joinOtp.join(",");

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "setstatusregister",
        value: JSON.stringify({
          clientid: username,
          status: activeRegister,
          typeOtp: otp,
          otpExpInMinutes: otpExpInMinutes,
          GroupRegister: groupRegister,
          activePin: activePin,
        }),
      }
    );

    if (resultUrl.data.code == 200) {
      toast.success(`Success Update Otp`);
    } else {
      toast.error(`Failed Update Otp`);
    }

    getRegisterStatus();
  };

  const saveOtpExp = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;
    const joinOtp = [];
    //if wa is true, then push to array, if wa and sms is true, then push to array too
    if (typeOtp?.wa) joinOtp.push("wa");
    if (typeOtp?.sms) joinOtp.push("sms");
    //join array with comma
    const otp = joinOtp.join(",");

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "setstatusregister",
        value: JSON.stringify({
          clientid: username,
          status: activeRegister,
          typeOtp: otp,
          otpExpInMinutes: otpExp,
          GroupRegister: groupRegister,
          activePin: activePin,
        }),
      }
    );

    if (resultUrl.data.code == 200) {
      toast.success(`Success Update Otp`);
    } else {
      toast.error(`Failed Update Otp`);
    }

    getRegisterStatus();
  };

  const saveGroup = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;
    const joinOtp = [];
    //if wa is true, then push to array, if wa and sms is true, then push to array too
    if (typeOtp?.wa) joinOtp.push("wa");
    if (typeOtp?.sms) joinOtp.push("sms");
    //join array with comma
    const otp = joinOtp.join(",");

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "setstatusregister",
        value: JSON.stringify({
          clientid: username,
          status: activeRegister,
          typeOtp: otp,
          otpExpInMinutes: otpExpInMinutes,
          GroupRegister: groupReg,
          activePin: activePin,
        }),
      }
    );

    if (resultUrl.data.code == 200) {
      toast.success(`Success Update Group Register`);
    } else {
      toast.error(`Failed Update Group Register`);
    }

    getRegisterStatus();
  };

  const saveActivePin = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;
    const joinOtp = [];
    //if wa is true, then push to array, if wa and sms is true, then push to array too
    if (typeOtp?.wa) joinOtp.push("wa");
    if (typeOtp?.sms) joinOtp.push("sms");
    //join array with comma
    const otp = joinOtp.join(",");

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "setstatusregister",
        value: JSON.stringify({
          clientid: username,
          status: activeRegister,
          typeOtp: otp,
          otpExpInMinutes: otpExpInMinutes,
          GroupRegister: groupRegister,
          activePin: !activePin,
        }),
      }
    );

    if (resultUrl.data.code == 200) {
      toast.success(`Success Update Group Register`);
    } else {
      toast.error(`Failed Update Group Register`);
    }

    getRegisterStatus();
  };

  console.log(typeOtp);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="setting-logo" />
          <Content>
            <PageHeading title="Setting Logo Login Aplikasi" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {form?.imageStorage?.length !== 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={form.imageStorage}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  )}
                  <Input
                    accept="image/*"
                    label="Ubah Gambar"
                    type="file"
                    onChange={(e) => uploadImage(e?.target?.files[0])}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
                <Card>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="id"
                      value="option1"
                      checked={activeRegister}
                      onChange={() => {
                        changeRegister();
                      }}
                    />
                    <label className="custom-control-label" for="id">
                      Aktifkan Tombol Register?
                    </label>
                  </div>
                </Card>
                <Card>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="wa"
                      value="option1"
                      checked={typeOtp?.wa}
                      onChange={() => {
                        setTypeOtp({
                          ...typeOtp,
                          wa: !typeOtp?.wa,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="wa">
                      Aktifkan OTP WA
                    </label>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="sms"
                      value="option1"
                      checked={typeOtp?.sms}
                      onChange={() => {
                        setTypeOtp({
                          ...typeOtp,
                          sms: !typeOtp?.sms,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="sms">
                      Aktifkan OTP SMS
                    </label>
                  </div>
                  <Button
                    title={"Simpan"}
                    type="warning"
                    onClick={() => {
                      changeOtp();
                    }}
                  />
                </Card>
                <Card>
                  <Input
                    label="OTP Expired (detik)"
                    onChange={(e) => {
                      setOtpExp(e.target.value);
                    }}
                    placeholder="OTP Expired"
                    value={otpExp}
                  />
                  <Button
                    title={"Simpan"}
                    type="warning"
                    onClick={() => {
                      saveOtpExp();
                    }}
                  />
                </Card>
                <Card>
                  <Input
                    label="Group Register"
                    onChange={(e) => {
                      setGroupReg(e.target.value);
                    }}
                    placeholder="Group Register"
                    value={groupReg}
                  />
                  <Button
                    title={"Simpan"}
                    type="warning"
                    onClick={() => {
                      saveGroup();
                    }}
                  />
                </Card>
                <Card>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="activereg"
                      value="activereg"
                      checked={activePin}
                      onChange={() => {
                        saveActivePin();
                      }}
                    />
                    <label className="custom-control-label" for="activereg">
                      Aktifkan PIN?
                    </label>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(SettingLogo);
