/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA } from "../../helpers";

function Fee({ history, location }) {
  const [result, setResult] = useState([]);
  const [form, setForm] = useState({
    name: "",
    value: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getSetting();
  }, []);

  const [typeForm, setTypeForm] = useState(0);

  const getSetting = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.get(
      `https://api.setting.berkah-ts.my.id/juber/auth/fee/${username}?access_token=${token}`
    );
    const oldData = resultUrl.data.lobj;
    //change delimiter # to index 0 with key and index 1 with value
    let newData = [];
    newData = oldData.map((item) => {
      const split = item.split("#");
      return {
        name: split[0],
        value: ~~split[1],
      };
    });
    console.log(resultUrl.data.lobj);
    setResult(newData);
  };

  const setEdit = (data) => {
    setIsEdit(true);
    setForm(data);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  console.log(form);

  const saveAction = async () => {
    //check if typeForm is 1 then value contain % throw error "Masukkan value tanpa %"
    // if (typeForm == 1) {
    //   if (form?.value?.includes("%")) {
    //     return toast.error("Masukkan value tanpa %");
    //   }
    // }

    //add % if typeForm is 1
    if (typeForm == 1) {
      form.value = `${form.value}%`;
    }

    let newForm = form;
    if (isEdit) {
      const index = result.findIndex((item) => item.name == form.name);
      result[index] = form;
      newForm = result;
    } else {
      newForm = [...result, form];
    }

    //change array to string with delimiter #
    let newData = newForm?.map((item) => {
      return `${item.name}~${item.value}`;
    });

    // let newData = [
    //   "feepbb~600",
    //   "feesamsat~500",
    //   "feekurir~500",
    //   "feetrain~500",
    //   "feeflight~500",
    //   "feehotel~500",
    //   "feepelni~500",
    //   "feeqr~500",
    //   "feesap~500",
    //   "feetrfva~500",
    // ];

    //join with -
    const data = newData.join("#");
    // console.log("data #", data);

    // return;

    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      fee: data,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/updatefee/${username}?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success("Success Update Fee");
      getSetting();
      cancelEdit();
      setTypeForm(0);
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="fee" />
          <Content>
            <PageHeading title="Setting Fee" />
            <div className="row">
              <div className="col-12">
                {isEdit && (
                  <Card>
                    <Input
                      label="Nama"
                      onChange={handleChange("name")}
                      placeholder="contoh feetrain"
                      value={form?.name}
                      readonly={true}
                    />
                    Value
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => setTypeForm(e.target.value)}
                      value={typeForm}
                    >
                      <option value="0">Nominal</option>
                      {/* <option value="1">Presentase</option> */}
                    </select>
                    <Input
                      // label="Value"
                      onChange={handleChange("value")}
                      placeholder="contoh 10, tanpa % jika memilih presentase"
                      value={form?.value}
                    />
                    {/* <small className="text-danger">
                      Contoh: 10, tanpa % jika memilih presentase, Jika nominal
                      berupa desimal maka gunakan TITIK sebagai koma, misal 1.5
                    </small> */}
                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Simpan"}
                        type="warning"
                        onClick={() => saveAction()}
                      />
                      {isEdit && (
                        <Button
                          title="Batal Edit"
                          type="danger"
                          onClick={() => cancelEdit()}
                          style={{ width: 130, marginLeft: 10 }}
                        />
                      )}
                    </div>
                  </Card>
                )}
                <div className="alert alert-warning">
                  <strong>Info!</strong> Tidak bisa menambahkan fee baru, hanya
                  bisa mengedit fee yang sudah ada
                </div>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama </th>
                        <th className=""> Nominal </th>
                        <th className="center"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.name}</td>
                            <td className="">{item.value}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setEdit(item);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              {/* <a className="btn btn-tbl-delete btn-xs">
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Fee);
