/* eslint-disable no-control-regex */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";

function MasterBank({ history, location }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [form, setForm] = useState({
    nama: "",
    norek: "",
    owner: "",
    logo: "",
    instruksi: "1.XXX",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [temp, setTemp] = useState(null);

  useEffect(() => {
    getBank();
  }, []);

  const getBank = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "masterbank",
          method: "get",
        })
      )
    );
    const result = res?.data?.data[0] || [];
    //solve Bad control character in string literal
    const literal = result?.value;
    const parse = literal?.replace(/[\u0000-\u0019]+/g, "");
    const solved_literal = parse?.replace(/[\u0000-\u0019]+/g, "");
    let banks = JSON.parse(solved_literal)?.banks;
    //add index to array banks
    banks = banks.map((item, index) => {
      return { ...item, index };
    });
    console.log(banks);
    setResult(banks);
  };

  const setEdit = (data) => {
    setIsEdit(true);
    setForm(data);
    setTemp(data);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      nama: "",
      norek: "",
      owner: "",
      logo: "",
      instruksi: "1.XXX",
    });
    setTemp(null);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    let pload = {
      mindeposit: 10000,
      banks: [...result, form],
    };

    if (isEdit) {
      pload = {
        mindeposit: 10000,
        banks: result.map((item) => {
          if (item?.index === temp?.index) {
            return form;
          }
          return item;
        }),
      };
    }

    const payload = JSON.stringify({ value: JSON.stringify(pload) });
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "masterbank/update",
          method: "post",
          payload,
        })
      )
    );

    if (res?.data?.code == 200) {
      toast.success(`Success ${isEdit ? "Update" : "Tambah"} Bank`);
      getBank();
      if (isEdit) {
        cancelEdit();
      }
      setForm({
        nama: "",
        norek: "",
        owner: "",
        logo: "",
        instruksi: "1.XXX",
      });
    }
  };

  const deleteAction = async (index) => {
    const pload = {
      mindeposit: 10000,
      //remove index
      banks: result.filter((item, i) => i !== index),
    };

    const payload = JSON.stringify({ value: JSON.stringify(pload) });
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "masterbank/update",
          method: "post",
          payload,
        })
      )
    );

    if (res?.data?.code == 200) {
      toast.success("Berhasil Menghapus Bank");
      getBank();
    }
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, logo: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(_fetch(GlobalService.deleteFoto(form?.logo)));
    if (!res?.success) return;
    setForm({ ...form, logo: "" });
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="master-bank" />
          <Content>
            <PageHeading title="Master Bank" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Bank"
                    onChange={handleChange("nama")}
                    placeholder="BRI"
                    value={form?.nama}
                  />
                  <Input
                    label="Nomor Rekening"
                    onChange={handleChange("norek")}
                    placeholder="62222XXXXX"
                    value={form?.norek}
                  />
                  <Input
                    label="Owner/Atas Nama"
                    onChange={handleChange("owner")}
                    placeholder="John Doe"
                    value={form?.owner}
                  />
                  {form?.logo?.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Pilih Gambar"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={form.logo}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> # </th>
                        <th className=""> Nama Bank </th>
                        <th className=""> Logo </th>
                        <th className=""> Nomer Rekening </th>
                        <th className=""> Owner </th>
                        <th className="center"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">{item.nama}</td>
                            <td className="">
                              <img
                                src={item.logo}
                                alt="logo"
                                style={{ width: 50, height: 50 }}
                              />
                            </td>
                            <td className="">{item.norek}</td>
                            <td className="">{item.owner}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setEdit(item);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(index);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(MasterBank);
