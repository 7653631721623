import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import lokasiList from "../../constants/lokasi.json";

function ListLokasi() {
  const [query, setQuery] = React.useState("");
  const [newItem, setNewItem] = React.useState([]);
  const [oldItem, setOldItem] = React.useState([]);

  React.useEffect(() => {
    setOldItem(lokasiList);
    setNewItem(lokasiList);
  }, []);

  console.log(oldItem);

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.lokasi?.toLowerCase().includes(query.toLowerCase())
            );
            setNewItem(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="lokasi" />
          <Content>
            <PageHeading title="List Lokasi" />
            <div className="row">
              <div className="col-12">
                <div className="row clearfix">
                  {newItem?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        className="col-xl-2 col-lg-3 col-md-6 col-sm-12 text-center text-dark"
                        to={{
                          pathname: `/list-locmenu`,
                          search: `?idlokasi=${item.lokasi}`,
                        }}
                      >
                        <div className="card">
                          <div className="panel-body">
                            <h6>Lokasi {item.lokasi}</h6>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListLokasi;
