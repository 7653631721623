/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  // Button,
  Card,
  Container,
  Content,
  // Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
function MarketKurir({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();
  // const [form, setForm] = useState({
  //   name: "",
  // });
  // const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "ref_courier",
          method: "get",
        })
      )
    );
    console.log(res);
    const activeData = res?.data?.data?.data;
    const unactiveData = res?.data?.data?.unactive;
    const sortedResult = ([...activeData, ...unactiveData] || []).sort(
      (a, b) => a.id - b.id
    );
    setResult(sortedResult);
    setOldItem(sortedResult);
  };

  // const updateCategory = async () => {
  //   const payload = {
  //     id: form?.id,
  //     name: form?.name,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "put",
  //         key: "ref_courier",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  //   setForm({
  //     id: "",
  //     name: "",
  //   });
  // };

  // const cancelEdit = () => {
  //   setIsEdit(false);
  //   setForm({
  //     name: "",
  //     value: "",
  //   });
  // };

  // const handleChange = (name) => (e) => {
  //   setForm({
  //     ...form,
  //     [name]: e.target.value,
  //   });
  // };

  // const saveAction = async () => {
  //   const payload = {
  //     name: form.name,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "post",
  //         key: "ref_courier",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  //   setForm({ name: "" });
  // };

  // const deleteAction = async (id) => {
  //   const payload = {
  //     id,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "delete",
  //         key: "ref_courier",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  // };

  const setAction = async (id) => {
    const payload = {
      id,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "get",
          key: "ref_courier/set_active",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.name?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="market-kurir" />
          <Content>
            <PageHeading title="Market Kurir" />
            <div className="row">
              <div className="col-12">
                {/* <Card>
                  <Input
                    label="Nama"
                    onChange={handleChange("name")}
                    placeholder="contoh JNT"
                    value={form?.name}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card> */}
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama </th>
                        <th className=""> Status </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item?.name}</td>
                            <td className="">
                              <span
                                className={`badge badge-pill ${
                                  item.active == 1
                                    ? "badge-warning"
                                    : "badge-danger"
                                }`}
                              >
                                {item?.active == 1 ? "Aktif" : "Tidak Aktif"}
                              </span>
                            </td>
                            <td className="center">
                              {/* <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    id: item?.id,
                                    name: item?.name,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item?.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                              <a
                                className="btn btn-tbl-remove-no-round btn-xs"
                                onClick={() => {
                                  setAction(item?.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i> Set{" "}
                                {item?.active == 1 ? "Tidak Aktif" : "Aktif"}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(MarketKurir);
