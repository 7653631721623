/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ListKategoriDetail({ history, location }) {
  let query = useQuery();
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [q, setQ] = React.useState("");
  const jenis = query.get("type");
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    namakategori: "",
    urlImg: "",
    jnsmerchant: jenis,
    status: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, urlImg: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(
      _fetch_noerror(GlobalService.deleteFoto(form?.image))
    );
    console.log(res);
    // if (!res?.success) return;
    setForm({ ...form, urlImg: "" });
  };

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "kategoriall",
          payload: JSON.stringify({ jnsmerchant: jenis }),
        })
      )
    );
    console.log(res);
    setResult(res?.data?.lobj || []);
    setOldItem(res?.data?.lobj || []);
  };

  const updateCategory = async () => {
    const payload = {
      ...form,
    };
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "updatecategory",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      id: "",
      namakategori: "",
      urlImg: "",
      jnsmerchant: jenis,
      status: "",
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      namakategori: form?.namakategori,
      urlImg: form?.urlImg,
      jnsmerchant: jenis,
    };
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "createcategory",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      namakategori: "",
      urlImg: "",
      jnsmerchant: jenis,
    });
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.namakategori?.toLowerCase().includes(q.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQ(val)}
        />
        <Container>
          <Sidebar active="kategori" />
          <Content>
            <PageHeading title={`List Kategori ${jenis}`} />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Kategori"
                    onChange={handleChange("namakategori")}
                    placeholder="contoh feetrain"
                    value={form?.namakategori}
                    readonly={true}
                  />
                  {form?.urlImg?.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Gambar"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={form.urlImg}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      onChange={handleChange("status")}
                      value={form?.status}
                    >
                      <option value="1">AKTIF</option>
                      <option value="0">TIDAK AKTIF</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama Kategori </th>
                        <th className=""> Gambar </th>
                        <th>Status</th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.namakategori}</td>
                            <td className="">
                              <img
                                src={item.gburl}
                                alt=""
                                style={{ width: 100 }}
                              />
                            </td>
                            <td className="">
                              {item.status == "1" ? "AKTIF" : "TIDAK AKTIF"}
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({ ...item, urlImg: item.gburl });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              {/* <a className="btn btn-tbl-delete btn-xs">
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ListKategoriDetail);
