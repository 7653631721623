/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  // Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function ManagementCache({ history, location }) {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  // const [modal, setModal] = useState(false);
  // const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "redis",
          method: "get",
        })
      )
    );
    console.log(res);
    setResult(res?.data?.data || []);
  };

  const deleteAction = async (id) => {
    const payload = {
      id: id,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "delete",
          key: "redis/delete",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  const clearAll = async () => {
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "get",
          key: "redis/clearall",
        })
      )
    );
    getData();
  };

  const [query, setQuery] = useState({
    kodeagen: "",
  });

  const search = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "redis/search",
          method: "post",
          payload: JSON.stringify({ kodeagen: query?.kodeagen }),
        })
      )
    );
    console.log(res);
    setResult(res?.data?.data || []);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="management-cache" />
          <Content>
            <PageHeading title="Management Cache" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button
                      onClick={() => clearAll()}
                      title="Clear All"
                      block={false}
                    />
                    <div className="d-flex align-items-center">
                      <Input
                        placeholder="Cari..."
                        onChange={(e) => setQuery({ kodeagen: e.target.value })}
                        value={query?.kodeagen}
                      />
                      <Button
                        onClick={() => search()}
                        title="Cari"
                        block={false}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> Key </th>
                          <th className=""> Value </th>
                          <th className="text-center">Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td
                                style={{ width: 200, maxWidth: 200 }}
                                className=""
                              >
                                {item?.rediskey}
                              </td>
                              <td
                                style={{
                                  width: 500,
                                  maxWidth: 500,
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                                className=""
                              >
                                {JSON.stringify(item?.value)}
                              </td>
                              <td className="center">
                                {/* <a
                                  className="btn btn-tbl-edit btn-xs"
                                  onClick={() => {
                                    setSelectedValue(item);
                                    setModal(true);
                                  }}
                                >
                                  <i className="fa fa-eye "></i>
                                </a> */}
                                <a
                                  className="btn btn-tbl-delete btn-xs"
                                  onClick={() => {
                                    deleteAction(item?.rediskey);
                                  }}
                                >
                                  <i className="fa fa-trash-o "></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        {/* <Modal show={modal} onHide={() => setModal(false)}>
          <pre>{JSON.stringify(selectedValue, null, 2)}</pre>
        </Modal> */}
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ManagementCache);
