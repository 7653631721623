import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import kategoriList from "../../constants/lokasi_kategori.json";

function ListKategori() {
  const [result, setResult] = React.useState([]);
  const [oldItem, setOldItem] = React.useState([]);
  const [query, setQuery] = React.useState("");

  useEffect(() => {
    setOldItem(kategoriList);
    setResult(kategoriList);
  }, []);

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.nama?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="kategori" />
          <Content>
            <PageHeading title="List Kategori" />
            <div className="row">
              <div className="col-12">
                <div className="row clearfix">
                  {result.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        className="col-xl-2 col-lg-3 col-md-6 col-sm-12 text-center text-dark"
                        to={item.to + "?type=" + item.type}
                      >
                        <div className="card">
                          <div className="panel-body mt-3">
                            <img
                              src={item.img}
                              style={{
                                height: "3rem",
                                width: "3rem",
                                objectFit: "contain",
                              }}
                              alt="i"
                            />
                            <h6>{item.nama}</h6>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListKategori;
