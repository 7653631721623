/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  AutoComplete,
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";

function Slider({ history }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState({
    one: "",
    two: "",
    three: "",
  });
  const [running, setRunning] = useState("");
  const [slider, setSlider] = useState(null);
  const [topBanner, setTopBanner] = useState("");

  useEffect(() => {
    getSliders();
  }, []);

  const getSliders = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "getslider",
          payload: JSON.stringify({ setting: "slider" }),
        })
      )
    );
    const runningText = res?.data?.lobj.filter((r) => r.idapps == "slider1")[0];
    const bannerTop = res?.data?.lobj.filter((r) => r.idapps == "slider2")[0];
    const bannerBottom = res?.data?.lobj.filter(
      (r) => r.idapps !== "slider1" && r.idapps !== "slider2"
    );
    console.log(res.data.lobj);
    setRunning(runningText?.value);
    setTopBanner(bannerTop?.value);
    setSlider(bannerBottom);
  };

  const editRunningAction = async () => {
    setResult({ ...result, one: "" });
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "setslider",
          payload: JSON.stringify({ idapps: "slider1", value: running }),
        }),
        false
      )
    );
    setResult({ ...result, one: res?.data?.msg });
    getSliders();
  };

  const editBannerTop = async () => {
    setTopBanner("");
  };

  const editBannerTopAction = async () => {
    setResult({ ...result, two: "" });
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "setslider",
          payload: JSON.stringify({ idapps: "slider2", value: topBanner }),
        }),
        false
      )
    );
    setResult({ ...result, two: res?.data?.msg });
    getSliders();
  };

  const editBannerBottomAction = async (img) => {
    setResult({ ...result, three: "" });
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "setslider",
          payload: JSON.stringify({ value: img }),
        }),
        false
      )
    );
    setResult({ ...result, three: res?.data?.msg });
    getSliders();
  };

  console.log(result);
  console.log(slider);

  const deleteSlider = async (id) => {
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "deleteslider",
          payload: JSON.stringify({ idapps: id }),
        }),
        false
      )
    );
    getSliders();
  };

  const uploadImage = async (data, type) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    // setForm({ ...form, image: res.file });
    if (type == "top") {
      setTopBanner(res.file);
    } else {
      editBannerBottomAction(res.file);
    }
  };

  //   const deleteImage = async (val) => {
  //     const res = await dispatch(_fetch(GlobalService.deleteFoto(val)));
  //     if (!res?.success) return;
  //   };

  const [selectedSlider, setSelectedSlider] = useState(null);
  const [editImageSelected, setEditImageSelected] = useState(null);

  const editImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setEditImageSelected(res.file);
  };

  const handleChangeF = (type) => (val) => {
    setSelectedSlider({ ...selectedSlider, [type]: val });
  };

  const editBannerAction = async () => {
    const to =
      selectedSlider.action == "link"
        ? selectedSlider.to_link
        : menuEdit
        ? `${menuEdit?.to_menu}~${menuEdit?.name_menu}~${menuEdit?.nextpage_menu}`
        : `${selectedSlider.to_menu}~${selectedSlider.name_menu}~${selectedSlider.nextpage_menu}`;
    const payload = {
      idapps: selectedSlider.idapps,
      desc: `${selectedSlider.action}#${to}`,
      value: editImageSelected ? editImageSelected : selectedSlider.value,
    };
    console.log(payload);

    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "setslider",
          payload: JSON.stringify(payload),
        }),
        false
      )
    );

    if (res?.data?.code != "200") return;
    toast.success("Berhasil edit slider");
    setSelectedSlider(null);
    setMenuEdit(null);
    getSliders();
  };

  console.log("selectedSlider", selectedSlider);

  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [resultMenu, setResultMenu] = useState([]);
  const [clickQuery, setClickQuery] = useState(null);
  const [menuEdit, setMenuEdit] = useState(null);

  useEffect(() => {
    getMenuSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getMenuSearch = async () => {
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ nama: query }),
        }),
        false
      )
    );
    // console.log(res.data.lobj);
    setResultMenu(res?.data?.lobj);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="slider" />
          <Content>
            <PageHeading title={`Slider & Running Text`} />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Running Text"
                    onChange={(val) => setRunning(val.target.value)}
                    placeholder="Masukkan Running Text"
                    value={running}
                    multiline
                    rows="30"
                  />
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => editRunningAction()}
                  />
                  {result.one.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result.one}</div>
                    </>
                  )}
                </Card>
                <Card>
                  {topBanner.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Banner Home Atas (max 300kb) (Ukuran 1500 x 500px), .jpg atau .png"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0], "top")}
                    />
                  ) : (
                    <>
                      <label>Banner Home Atas</label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "9.5rem",
                          padding: 10,
                          border: "2px solid #d2d6de",
                          backgroundColor: "rgba(210, 210, 210, 0.25)",
                          color: "#ffffff",
                        }}
                        className="mb-3 d-flex flex-column"
                      >
                        <a href={topBanner} target="_blank">
                          <img
                            src={topBanner}
                            alt=""
                            className="img-thumbnail mb-2"
                            style={{
                              height: "8rem",
                              width: "8rem",
                              objectFit: "contain",
                            }}
                          />
                        </a>
                        <div
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            borderRadius: 99,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="text-center d-flex justify-content-center align-items-center"
                          onClick={() => editBannerTop()}
                        >
                          <i
                            className="material-icons"
                            style={{ color: "#E44F56", cursor: "pointer" }}
                          >
                            edit
                          </i>
                        </div>
                      </div>
                    </>
                  )}
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => editBannerTopAction()}
                  />
                  {result.two.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result.two}</div>
                    </>
                  )}
                </Card>
                {selectedSlider && (
                  <Card>
                    <label>Edit Banner</label>
                    <Input
                      accept="image/*"
                      label="Ubah Gambar"
                      type="file"
                      onChange={(e) => editImage(e?.target?.files[0])}
                    />
                    <small>
                      Jika tidak ingin mengubah gambar, abaikan/tidak usah
                      dipilih saja
                    </small>
                    <div className="form-group">
                      <label>Action</label>
                      <div
                        className="radio"
                        onClick={() => handleChangeF("action")("menu")}
                      >
                        <input
                          type="radio"
                          name="status"
                          id="menu"
                          value="menu"
                          checked={selectedSlider?.action === "menu"}
                          onChange={handleChangeF("action")}
                        />
                        <label htmlFor="optionsRadios1">Menu</label>
                      </div>
                      <div
                        className="radio"
                        onClick={() => handleChangeF("action")("link")}
                      >
                        <input
                          type="radio"
                          name="status"
                          id="link"
                          value="link"
                          checked={selectedSlider?.action === "link"}
                          onChange={handleChangeF("action")}
                        />
                        <label htmlFor="optionsRadios2">Link</label>
                      </div>
                    </div>
                    {selectedSlider?.action === "link" && (
                      <Input
                        label="Link"
                        onChange={(val) =>
                          handleChangeF("to_link")(val.target.value)
                        }
                        value={selectedSlider.to_link}
                      />
                    )}
                    {selectedSlider?.action === "menu" && (
                      <>
                        <h5
                          className="text-dark mt-0 mb-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setModal(true)}
                        >
                          Edit menu (Menu saat ini :{selectedSlider?.name_menu})
                        </h5>
                        <h5 className="text-dark mt-0 mb-3">
                          Menu Baru :{menuEdit?.name_menu}
                        </h5>
                      </>
                    )}
                    <Button
                      title="Submit"
                      type="warning"
                      onClick={() => editBannerAction()}
                    />
                  </Card>
                )}
                <Card>
                  <>
                    <label>
                      Banner Home Bawah (ukuran banner 1500x500 pixel)
                    </label>
                    <div className="d-flex">
                      {slider?.map((res, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "9.5rem",
                              padding: 10,
                              // border: "2px solid #d2d6de",
                              // backgroundColor: "rgba(210, 210, 210, 0.25)",
                              // color: "#ffffff",
                            }}
                            className="mb-3 d-flex flex-column"
                            key={index}
                          >
                            <a href={res.value} target="_blank">
                              <img
                                src={res.value}
                                alt=""
                                className="img-thumbnail mb-2"
                                style={{
                                  height: "8rem",
                                  width: "8rem",
                                  objectFit: "contain",
                                }}
                              />
                            </a>
                            <div
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                borderRadius: 99,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="text-center d-flex justify-content-center align-items-center"
                            >
                              <i
                                className="material-icons"
                                style={{ color: "#E44F56", cursor: "pointer" }}
                                onClick={() =>
                                  setSelectedSlider({
                                    ...res,
                                    action: res?.desc?.split("#")[0] ?? null,
                                    to_menu:
                                      res?.desc?.split("#")[0] == "menu"
                                        ? res?.desc?.split("#")[1].split("~")[0]
                                        : null,
                                    to_link:
                                      res?.desc?.split("#")[0] == "link"
                                        ? res?.desc?.split("#")[1]
                                        : null,
                                    name_menu:
                                      res?.desc?.split("#")[0] == "menu"
                                        ? res?.desc?.split("#")[1].split("~")[1]
                                        : null,
                                    nextpage_menu:
                                      res?.desc?.split("#")[0] == "menu"
                                        ? res?.desc?.split("#")[1].split("~")[2]
                                        : null,
                                  })
                                }
                              >
                                edit
                              </i>
                              <i
                                className="ml-2 material-icons"
                                style={{ color: "#E44F56", cursor: "pointer" }}
                                onClick={() => deleteSlider(res.idapps)}
                              >
                                cancel
                              </i>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <hr />
                    <Input
                      accept="image/*"
                      label="Tambah (max 200kb)"
                      type="file"
                      onChange={(e) =>
                        uploadImage(e?.target?.files[0], "bottom")
                      }
                      onClick={(e) => (e.target.value = null)}
                    />
                    <small>Untuk menambahkan silahkan klik tombol diatas</small>
                  </>
                  {result.three.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result.three}</div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <AutoComplete
            suggestions={resultMenu}
            onChangeText={(val) => setQuery(val)}
            onClickText={(val) => setClickQuery(val)}
          />
          <div className="d-flex justify-content-center align-items-end mt-4">
            <Button
              title="Simpan"
              type="warning"
              onClick={() => {
                console.log("clickquery", clickQuery);
                setMenuEdit({
                  to_menu: clickQuery?.id,
                  name_menu: clickQuery?.nama,
                  nextpage_menu: clickQuery?.nextpage,
                });
                setModal(false);
              }}
            />
            <Button
              title="Batal"
              type="danger"
              onClick={() => setModal(false)}
              style={{ width: 130, marginLeft: 10 }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Slider);
