/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import {
  AtoB,
  BtoA,
  changeSecondToDateFormatHms,
  formatRupiahBolehMinus,
} from "../../helpers";
// import { _fetch } from "../../redux/actions/global";
// import { TransactionService } from "../../services";
import "moment/locale/id";

function Deposit({ history, location }) {
  // const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [isadmin, setIsadmin] = useState(false);
  const [username, setUsername] = useState("");
  const [form, setForm] = useState({
    client: "",
    nominal: "",
    pin: "",
    keterangan: "",
  });
  const [formMutasi, setFormMutasi] = useState({
    clientid: "",
    tglStart: moment().add(-7, "days").format("yyyy-MM-DD"),
    tglEnd: moment().format("yyyy-MM-DD"),
  });

  useEffect(() => {
    let atob = AtoB("kocak123");
    let key = atob("isadmin");
    let isadmin = localStorage.getItem(key);
    let valueIya = atob("IYA ADMIN");
    // let valueTidak = atob("TIDAK ADMIN");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    setUsername(username);
    if (isadmin == valueIya) {
      console.log("IYA");
      setIsadmin(true);
    } else {
      setIsadmin(false);
      setFormMutasi({ ...formMutasi, clientid: username });
      getMutasi();
    }
  }, []);

  console.log(username);
  // useEffect(() => {
  //   getMutasi();
  // }, [formMutasi.tglStart, formMutasi.tglEnd]);

  const getMutasi = async () => {
    if (formMutasi?.clientid == "" && isadmin) {
      toast.error("Client ID tidak boleh kosong");
      return;
    }
    const tglStart = moment(formMutasi.tglStart).format("YYYYMMDD");
    const tglEnd = moment(formMutasi.tglEnd).format("YYYYMMDD");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    axios
      .get(
        `https://api.setting.berkah-ts.my.id/juber/auth/mutasi/${formMutasi.clientid}?access_token=${token}&tglStart=${tglStart}&tglEnd=${tglEnd}`
      )
      .then((resultUrl) => {
        try {
          if (resultUrl?.data?.code == 200) {
            toast.success("Sukses fetch mutasi");
            setResult(resultUrl.data.lobj);
          } else {
            toast.error(resultUrl.data.msg);
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        toast.error(error.response.data.msg);
      });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const handleChangeMutasi = (name) => (e) => {
    setFormMutasi({
      ...formMutasi,
      [name]: e.target.value,
    });
  };

  const forceTopUp = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    axios
      .get(
        `https://api.setting.berkah-ts.my.id/juber/auth/topup/${form.client}?access_token=${token}&value=${form.nominal}&pin=123456`
      )
      .then((resultUrl) => {
        console.log(resultUrl);
        toast.error(resultUrl.data.msg);
        const time = new Date().getTime();
        localStorage.setItem("time_pin", time);
        setPinShowed(true);
      })
      .catch((error) => {
        toast.error(error.response.data.msg);
      });
  };

  const [clients, setClients] = useState([]);

  const topupAction = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    axios
      .get(
        `https://api.setting.berkah-ts.my.id/juber/auth/topup/${form.client}?access_token=${token}&value=${form.nominal}&pin=${form.pin}&keterangan=${form.keterangan}`
      )
      .then((resultUrl) => {
        console.log(resultUrl);
        try {
          if (resultUrl?.data?.code == 200) {
            toast.success(resultUrl.data.msg);
            if (!isadmin) {
              getMutasi();
            }
            setForm({
              client: "",
              nominal: "",
              pin: "",
              keterangan: "",
            });
            localStorage.removeItem("time_pin");
            setPinShowed(false);
            setDifference(120);
          } else {
            toast.error(resultUrl.data.msg);
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        toast.error(error.response.data.msg);
      });
  };

  console.log(formMutasi);

  const getDifferenceTimeInHms = (time) => {
    const timeNow = new Date().getTime();
    const diff = timeNow - time;
    const diffInMinutes = diff / 1000;
    return diffInMinutes;
  };

  const [difference, setDifference] = useState(120);

  const [pinShowed, setPinShowed] = useState(localStorage.getItem("time_pin"));

  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("time_pin")) return;
      const between = getDifferenceTimeInHms(localStorage.getItem("time_pin"));
      //hitung mundur
      setDifference(120 - between);
      //if 2 minutes passed, remove the pin
      if (between > 120) {
        setPinShowed(false);
        localStorage.removeItem("time_pin");
        setDifference(120);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getCLient();
  }, []);

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  const [query, setQuery] = useState("");

  const cariMutasi = async () => {
    if (formMutasi?.clientid == "" && isadmin) {
      toast.error("Client ID tidak boleh kosong");
      return;
    }
    const tglStart = moment(formMutasi.tglStart).format("YYYYMMDD");
    const tglEnd = moment(formMutasi.tglEnd).format("YYYYMMDD");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    axios
      .post(
        `https://api.setting.berkah-ts.my.id/juber/auth/mutasi/search?access_token=${token}&tglStart=${tglStart}&tglEnd=${tglEnd}`,
        {
          desc: "mutasi/search",
          value: JSON.stringify({
            key: query,
            clientid: formMutasi.clientid,
          }),
        }
      )
      .then((resultUrl) => {
        try {
          if (resultUrl?.data?.code == 200) {
            toast.success("Sukses fetch mutasi");
            setMutasiSearch(resultUrl.data.lobj);
          } else {
            toast.error(resultUrl.data.msg);
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        toast.error(error.response.data.msg);
      });
  };

  const [mutasiSearch, setMutasiSearch] = useState([]);

  const calculateKredit = (array) => {
    const totalKredit = array.reduce((sum, currentItem) => {
      if (currentItem.value1?.includes("kredit")) {
        return sum + parseInt(currentItem.nominal);
        // or parseFloat(currentItem.nominal) if nominal can be a float
      }
      return sum;
    }, 0);

    return totalKredit;
  };

  const calculateDebet = (array) => {
    const totalDebet = array.reduce((sum, currentItem) => {
      if (currentItem.value1?.includes("debet")) {
        return sum + parseInt(currentItem.nominal);
        // or parseFloat(currentItem.nominal) if nominal can be a float
      }
      return sum;
    }, 0);

    return totalDebet;
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="deposit" />
          <Content>
            <PageHeading title="Mutasi & Deposit" />
            <div className="row">
              <div className="col-12">
                {isadmin && (
                  <Card>
                    <h5>Deposit</h5>
                    <div className="form-group">
                      <label>Klien</label>
                      <select
                        className="form-control"
                        name="type"
                        onChange={handleChange("client")}
                        value={form?.client}
                      >
                        <option value="">Pilih Client</option>
                        {clients?.map((item, index) => {
                          return (
                            <option key={index} value={item?.username}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <Input
                      label="Nominal"
                      onChange={handleChange("nominal")}
                      placeholder="contoh 100000"
                      value={form?.nomimnal}
                    />
                    <Input
                      label="Keterangan"
                      onChange={handleChange("keterangan")}
                      placeholder="Keterangan"
                      value={form?.keterangan}
                    />
                    <div className="d-flex align-items-end">
                      {pinShowed && (
                        <Input
                          label="OTP"
                          onChange={handleChange("pin")}
                          placeholder="contoh 083845"
                          value={form?.pin}
                        />
                      )}
                      <Button
                        title={`Kirim OTP (${changeSecondToDateFormatHms(
                          difference
                        )})`}
                        type="info"
                        onClick={() => forceTopUp()}
                        block={false}
                        disabled={difference < 120}
                        style={{ marginLeft: "1rem" }}
                      />
                    </div>

                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Topup"}
                        type="warning"
                        onClick={() => topupAction()}
                      />
                    </div>
                  </Card>
                )}
                <Card>
                  <h5>Mutasi By Tanggal</h5>
                  {isadmin && (
                    <div className="form-group">
                      <label>Klien</label>
                      <select
                        className="form-control"
                        name="type"
                        onChange={handleChangeMutasi("clientid")}
                        value={formMutasi?.clientid}
                      >
                        <option value="">Pilih Client</option>
                        <option value="all">Semua</option>
                        {clients?.map((item, index) => {
                          return (
                            <option key={index} value={item?.username}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-6">
                      <Input
                        label="Tanggal Mulai"
                        onChange={handleChangeMutasi("tglStart")}
                        placeholder="contoh 20221201"
                        value={formMutasi?.tglStart}
                        type="date"
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        label="Tanggal Akhir"
                        onChange={handleChangeMutasi("tglEnd")}
                        placeholder="contoh 20230101"
                        value={formMutasi?.tglEnd}
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Cek Mutasi"}
                      type="warning"
                      onClick={() => getMutasi()}
                    />
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> Transaksi </th>
                          <th className=""> Klien </th>
                          <th className=""> Nominal </th>
                          <th className=""> Type </th>
                          <th className=""> Deskripsi </th>
                          <th>Tanggal dan Waktu</th>
                          <th>Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          let date = moment(item.tgl);
                          let tipe =
                            formMutasi.clientid == "all"
                              ? item.value1?.split("#")[1]
                              : item?.value1;
                          date.locale("id");
                          date = date.format("dddd, DD MMMM YYYY, HH:mm");
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td
                                className=""
                                style={{ width: 200, maxWidth: 200 }}
                              >
                                {item.desc}
                              </td>
                              <td className="">
                                {formMutasi.clientid == "all"
                                  ? item.value1?.split("#")[0]
                                  : formMutasi?.clientid}
                              </td>
                              <td className="">
                                {formatRupiahBolehMinus(item.nominal)}
                              </td>
                              <td className="">
                                <span
                                  className={`badge badge-pill ${
                                    tipe == "debet"
                                      ? "badge-primary"
                                      : "badge-warning"
                                  }`}
                                >
                                  {tipe}
                                </span>
                              </td>
                              <td style={{ width: 200, maxWidth: 200 }}>
                                {item.value2}
                              </td>
                              <td className="">{date}</td>
                              <td>
                                Saldo sebelum{" "}
                                <b>{formatRupiahBolehMinus(item.saldoawal)}</b>,
                                Saldo akhir{" "}
                                <b>{formatRupiahBolehMinus(item.saldoakhir)}</b>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={7}>
                            Total Kredit :{" "}
                            {formatRupiahBolehMinus(calculateKredit(result))}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>
                            Total Debit :{" "}
                            {formatRupiahBolehMinus(calculateDebet(result))}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Card>
                <Card>
                  <h5>Cari Mutasi</h5>
                  {isadmin && (
                    <div className="form-group">
                      <label>Klien</label>
                      <select
                        className="form-control"
                        name="type"
                        onChange={handleChangeMutasi("clientid")}
                        value={formMutasi?.clientid}
                      >
                        <option value="">Pilih Client</option>
                        <option value="all">Semua</option>
                        {clients?.map((item, index) => {
                          return (
                            <option key={index} value={item?.username}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <Input
                    label="Kata Kunci"
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="contoh train"
                    value={query}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Cari Mutasi"}
                      type="warning"
                      onClick={() => cariMutasi()}
                    />
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th
                            className=""
                            style={{ width: 200, maxWidth: 200 }}
                          >
                            {" "}
                            Nama{" "}
                          </th>
                          <th className=""> Klien </th>
                          <th className=""> Nominal </th>
                          <th className=""> Type </th>
                          <th
                            className=""
                            style={{ width: 200, maxWidth: 200 }}
                          >
                            {" "}
                            Deskripsi{" "}
                          </th>
                          <th>Tanggal dan Waktu</th>
                          <th>Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mutasiSearch?.map((item, index) => {
                          let date = moment(item.tgl);
                          date.locale("id");
                          date = date.format("dddd, DD MMMM YYYY, HH:mm");
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td
                                className=""
                                style={{ width: 200, maxWidth: 200 }}
                              >
                                {item.desk}
                              </td>
                              <td className="">{item.value1?.split("#")[0]}</td>
                              <td className="">
                                {formatRupiahBolehMinus(item.nominal)}
                              </td>
                              <td className="">
                                <span
                                  className={`badge badge-pill ${
                                    item.value1.split("#")[1] == "debet"
                                      ? "badge-primary"
                                      : "badge-warning"
                                  }`}
                                >
                                  {item.value1?.split("#")[1]}
                                </span>
                              </td>
                              <td
                                className=""
                                style={{ width: 200, maxWidth: 200 }}
                              >
                                {item.value2}
                              </td>
                              <td className="">{date}</td>
                              <td>
                                Saldo sebelum{" "}
                                <b>{formatRupiahBolehMinus(item.saldoawal)}</b>,
                                Saldo akhir{" "}
                                <b>{formatRupiahBolehMinus(item.saldoakhir)}</b>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={7}>
                            Total Kredit :{" "}
                            {formatRupiahBolehMinus(
                              calculateKredit(mutasiSearch)
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7}>
                            Total Debit :{" "}
                            {formatRupiahBolehMinus(
                              calculateDebet(mutasiSearch)
                            )}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Deposit);
