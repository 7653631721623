/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { formatRupiah } from "../../helpers";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function CheckTransaction() {
  const dispatch = useDispatch();
  const [kodepesanan, setKodepesanan] = useState("");
  const [result, setResult] = useState({
    type: "",
    pembeli: {
      nama: "",
      alamatasal: "",
      alamatantar: "",
      total: "",
      status: "",
      job: "",
      no_telp: "",
    },
    driver: "",
    dokumen: {
      foto_terima: "",
      foto_antar: "",
    },
    merchant: {
      nama: "",
      alamat: "",
      telp: "",
    },
    barang: [],
  });

  const [barang, setBarang] = useState([]);

  const checkAction = async () => {
    let typePesanan = kodepesanan.toLocaleLowerCase().slice(2, 3);
    console.log("slice", typePesanan);
    if (kodepesanan.length === 0)
      return toast.error("Kode pesanan tidak boleh kosong");
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "trxdetil",
          payload: JSON.stringify({ kodepesanan: kodepesanan }),
        }),
        false
      )
    );
    if (res.data.code != 200) return toast.error(res.data.msg);
    const driver = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "getdriverbykodeagen",
          payload: JSON.stringify({ sim: res?.data?.lobj[0]?.id_driver }),
        }),
        false
      )
    );
    // console.log(typePesanan);
    switch (typePesanan) {
      case "r":
        setResult({
          type: "Ride",
          pembeli: {
            nama: res.data.lobj[1].nama,
            alamatasal: res.data.lobj[0].alamatAsal,
            alamatantar: res.data.lobj[0].alamatAntar,
            total: res.data.lobj[0].grandtotal,
            status: res.data.lobj[0].status,
            job: res.data.lobj[0].job,
            no_telp: res.data.lobj[1].telp,
          },
          driver: driver.data.lobj[0],
          dokumen: {
            foto_terima: "",
            foto_antar: "",
          },
          merchant: {
            nama: "",
            alamat: "",
            telp: "",
          },
          barang: [],
        });
        break;
      case "s":
        setResult({
          type: "Send",
          pembeli: {
            nama: res.data.lobj[1].nama,
            alamatasal: res.data.lobj[0].alamatAsal,
            alamatantar: res.data.lobj[0].alamatAntar,
            total: res.data.lobj[0].grandtotal,
            status: res.data.lobj[0].status,
            job: res.data.lobj[0].job,
            no_telp: res.data.lobj[1].telp,
          },
          driver: driver.data.lobj[0],
          dokumen: {
            foto_terima: res?.data?.lobj[2]?.picture,
            foto_antar: res?.data?.lobj[3]?.picture,
          },
          merchant: {
            nama: "",
            alamat: "",
            telp: "",
          },
          barang: [],
        });
        break;
      case "f":
        setResult({
          type: "Food",
          pembeli: {
            nama: res.data.lobj[1].nama,
            alamatasal: res.data.lobj[0].alamatAsal,
            alamatantar: res.data.lobj[0].alamatAntar,
            total: res.data.lobj[0].grandtotal,
            status: res.data.lobj[0].status,
            job: res.data.lobj[0].job,
            no_telp: res.data.lobj[1].telp,
          },
          driver: driver.data.lobj[0],
          dokumen: {
            foto_terima: "",
            foto_antar: "",
          },
          merchant: {
            nama: res.data.lobj[2].nama,
            alamat: res.data.lobj[2].alamat,
            telp: res.data.lobj[2].telp,
          },
          barang: [],
        });
        const oldBarang = res.data.lobj;
        oldBarang.pop();
        oldBarang.splice(0, 3);
        // setResult({ ...result, barang: oldBarang });
        setBarang(oldBarang);
        break;
      case "l":
        setResult({
          type: "Layanan & Jasa",
          pembeli: {
            nama: res.data.lobj[1].nama,
            alamatasal: res.data.lobj[0].alamatAsal,
            alamatantar: res.data.lobj[0].alamatAntar,
            total: res.data.lobj[0].grandtotal,
            status: res.data.lobj[0].status,
            job: res.data.lobj[0].job,
            no_telp: res.data.lobj[1].telp,
          },
          driver: driver.data.lobj[0],
          dokumen: {
            foto_terima: "",
            foto_antar: "",
          },
          merchant: {
            nama: res.data.lobj[2].nama,
            alamat: res.data.lobj[2].alamat,
            telp: res.data.lobj[2].telp,
          },
          barang: [],
        });
        const oldBarang2 = res.data.lobj;
        oldBarang.pop();
        oldBarang.splice(0, 3);
        // setResult({ ...result, barang: oldBarang });
        setBarang(oldBarang2);
        break;
      default:
        break;
    }
    console.log(res.data);
    console.log(driver.data);
    // setResult(res?.data?.msg);
  };

  console.log("??? ", result);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="check-transaction" />
          <Content>
            <PageHeading title="Cek Transaksi" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Kode Transaksi"
                    onChange={(e) => setKodepesanan(e.target.value)}
                    placeholder="Masukkan Kode Pesanan (JBR, JBF, JBL, JBS)"
                  />
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => checkAction()}
                  />
                </Card>
              </div>
            </div>
            {result?.type?.length !== 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="alert alert-danger font-bold text-center">
                    {result.type}
                  </div>
                  <div className="alert alert-warning">Data Pemesan</div>
                  <Card>
                    <h5>Nama Pemesan : {result.pembeli.nama}</h5>
                    <h5>Alamat Asal : {result.pembeli.alamatasal}</h5>
                    <h5>Alamat Antar : {result.pembeli.alamatantar}</h5>
                    <h5>No Telp : {result.pembeli.no_telp}</h5>
                    <h5>Status : {result.pembeli.status}</h5>
                    <h5>Job : {result.pembeli.job}</h5>
                    <h5>Total : {result.pembeli.total}</h5>
                  </Card>
                  {result?.dokumen?.foto_terima?.length != 0 &&
                    result?.dokumen?.foto_antar?.length != 0 && (
                      <>
                        <div className="alert alert-warning">Data Dokumen</div>
                        <Card>
                          <div className="row">
                            <div className="col-6">
                              <h5>Foto terima paket dari asal</h5>
                              <img
                                src={result?.dokumen?.foto_terima}
                                alt="antar"
                                style={{
                                  height: 300,
                                  width: 250,
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div className="col-6">
                              <h5>Foto serah terima paket ke tujuan</h5>
                              <img
                                src={result?.dokumen?.foto_antar}
                                alt="antar"
                                style={{
                                  height: 300,
                                  width: 250,
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      </>
                    )}
                  {result?.merchant?.nama?.length != 0 &&
                    result?.merchant?.alamat?.length != 0 &&
                    result?.merchant?.telp?.length != 0 && (
                      <>
                        <div className="alert alert-warning">Data Merchant</div>
                        <Card>
                          <h5>Nama Merchant : {result.merchant.nama}</h5>
                          <h5>Alamat Merchant : {result.merchant.alamat}</h5>
                          <h5>Telp Merchant : {result.merchant.telp}</h5>
                        </Card>
                      </>
                    )}
                  <div className="alert alert-warning">Data Driver</div>
                  <Card>
                    <h5>Nama Driver : {result?.driver?.nama}</h5>
                    <h5>Merk : {result?.driver?.merk}</h5>
                    <h5>Plat Nomor : {result?.driver?.nopol}</h5>
                    <h5>No Telp : {result?.driver?.telp}</h5>
                  </Card>
                  {barang?.length != 0 && (
                    <>
                      <div className="alert alert-warning">Data Barangs</div>
                      <Card>
                        {barang.map((item, index) => {
                          return (
                            <>
                              <h5>Nama Barang : {item?.barang?.nama}</h5>
                              <h5>
                                Harga Barang : {formatRupiah(item?.harga)}
                              </h5>
                              <hr />
                            </>
                          );
                        })}
                      </Card>
                    </>
                  )}
                </div>
              </div>
            )}
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default CheckTransaction;
