/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";

import "moment/locale/id";
import { BtoA, formatRupiah } from "../../helpers";

function LogTransaksi({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState("");

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  useEffect(() => {
    // getLog("all");
    console.log(location);
    getCLient();
  }, []);

  const getLog = async (u) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const tglStart = moment(formLog.tglStart).format("YYYYMMDD");
    const tglEnd = moment(formLog.tglEnd).format("YYYYMMDD");

    let resultUrl;
    let oldData;
    if (search.length == 0) {
      resultUrl = await axios.get(
        `https://api.setting.berkah-ts.my.id/juber/auth/logtrx/${u}?access_token=${token}&tglStart=${tglStart}&tglEnd=${tglEnd}`
      );
      oldData = resultUrl.data.lobj;
    } else {
      resultUrl = await axios.post(
        `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
        {
          desc: "serach/logtrx",
          value: JSON.stringify({ clientid: u, key: search }),
        }
      );
      oldData = resultUrl.data.lobj[0];
      oldData = oldData.map((item, index) => {
        return {
          ...item,
          desc: item.desk,
        };
      });
    }

    if (resultUrl.data.code == 200) {
      toast.success("Sukses menampilkan log transaksi");
    } else {
      toast.error("Gagal menampilkan log transaksi");
    }

    setResult(oldData);
    setOldItem(oldData);
  };

  const [formLog, setformLog] = useState({
    clientid: "all",
    tglStart: moment().add(-7, "days").format("yyyy-MM-DD"),
    tglEnd: moment().format("yyyy-MM-DD"),
  });

  const handleChangeMutasi = (name) => (e) => {
    setformLog({
      ...formLog,
      [name]: e.target.value,
    });
  };

  const [stateClient, setStateClient] = useState(0);

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.desc?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="log-transaksi" />
          <Content>
            <PageHeading title="Log Transaksi" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="row">
                    <div className="col-6">
                      <Input
                        label="Tanggal Mulai"
                        onChange={handleChangeMutasi("tglStart")}
                        placeholder="contoh 20221201"
                        value={formLog?.tglStart}
                        type="date"
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        label="Tanggal Akhir"
                        onChange={handleChangeMutasi("tglEnd")}
                        placeholder="contoh 20230101"
                        value={formLog?.tglEnd}
                        type="date"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Semua Client"}
                      type="info"
                      onClick={() => {
                        setformLog({ ...formLog, clientid: "all" });
                        setStateClient(0);
                      }}
                    />
                    <div className="ml-3" />
                    <Button
                      title={"Pilih Client"}
                      type="danger"
                      onClick={() => {
                        setformLog({ ...formLog, clientid: "" });
                        setStateClient(1);
                      }}
                    />
                  </div>
                  {stateClient === 1 && (
                    // <Input
                    //   label="Client"
                    //   onChange={handleChangeMutasi("clientid")}
                    //   placeholder="contoh OTO"
                    //   value={formLog?.clientid}
                    // />
                    <div className="form-group">
                      <label>Klien</label>
                      <select
                        className="form-control"
                        name="type"
                        onChange={handleChangeMutasi("clientid")}
                        value={formLog?.clientid}
                      >
                        <option value="">Pilih Client</option>
                        {clients?.map((item, index) => {
                          return (
                            <option key={index} value={item?.username}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                  <Input
                    label="Cari..."
                    onChange={(val) => setSearch(val.target.value)}
                    placeholder="Cari"
                    value={search}
                  />
                  <Button
                    title={
                      search.length
                        ? "Cek Log Transaksi dengan pencarian"
                        : "Cek Log Transaksi"
                    }
                    type="warning"
                    onClick={() => getLog(formLog.clientid)}
                  />
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> Deskripsi </th>
                          <th className=""> Keterangan </th>
                          <th className=""> Nominal </th>
                          <th className=""> Fee </th>
                          <th className=""> Tanggal </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td className="">{item.desc}</td>
                              <td
                                className=""
                                style={{ width: 600, maxWidth: 800 }}
                              >
                                {item.value2}
                              </td>
                              <td className="">{formatRupiah(item.nominal)}</td>
                              <td className="">{formatRupiah(item.fee)}</td>
                              <td className="">{item.tgl}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(LogTransaksi);
