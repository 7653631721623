/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/AuthService";
import "../../styles/css/extra_pages.scss";
import "../../styles/css/material-design-iconic-font.min.scss";
import { ToastContainer, toast } from "react-toastify";
import { _fetch } from "../../redux/actions/global";
import { login } from "../../redux/actions/auth";
import axios from "axios";
import { AtoB } from "../../helpers";
import { BridgeService } from "../../services";

function SignIn({ history }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    no_hp: null,
    otp: null,
    username: null,
    password: null,
  });
  const [loginState, setLoginState] = useState({
    step: 0,
    msg: null,
  });
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    checkUrl();
    getAccount();
  }, []);

  const checkUrl = () => {
    let url = localStorage.getItem("6969");

    setLoginState({
      step: url ? 1 : 0,
      msg: null,
    });
  };

  const getAccount = () => {
    let accounts = localStorage.getItem("account");
    accounts = JSON.parse(accounts);
    if (accounts) {
      setAccounts(accounts);
      localStorage.setItem("active_user", JSON.stringify(accounts[0]));
    }
  };

  const handleChange = (type) => (val) => {
    if (type == "no_hp") {
      const value = JSON.parse(val.target.value);
      const no_hp = value.hp;
      setForm({ ...form, [type]: no_hp });
      localStorage.setItem("active_user", JSON.stringify(value));
    } else {
      setForm({ ...form, [type]: val.target.value });
    }
  };

  const signinFirst = async () => {
    try {
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: form.username,
          password: form.password,
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      console.log(result.data);

      const token = result.data.access_token;

      const resultUrl = await axios.get(
        `https://api.setting.berkah-ts.my.id/juber/auth/webadmin/${form.username}?access_token=${token}`
      );

      let response = JSON.parse(resultUrl.data.lobj[0]);
      let urlNew = response.baseurl;
      let urlSocket = response.socketurl;
      let logo = response.logo;
      // let logo = response.logo;
      let asd = response.isadmin;
      console.log(asd);
      let saldo = resultUrl.data.value;
      let admin = JSON.stringify(response.admin);

      let atob = AtoB("kocak123");
      let keyasd = atob("isadmin");
      asd = atob(asd == 1 ? "IYA ADMIN" : "TIDAK ADMIN");
      localStorage.setItem(keyasd, asd);
      localStorage.setItem("access_token", token);

      const credential = atob(form.username);
      localStorage.setItem("credential", credential);

      localStorage.setItem("saldo", saldo);
      urlNew = atob(urlNew);
      localStorage.setItem("6969", urlNew);
      localStorage.setItem("urlSocket", urlSocket);
      // localStorage.setItem("logo", logo);
      localStorage.setItem("account", admin);
      localStorage.setItem("active_user", JSON.stringify(response.admin[0]));
      localStorage.setItem("logo_login", logo);
      setAccounts(response.admin);

      const resExpired = await axios.post(
        `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
        {
          desc: "getexpdate",
          value: JSON.stringify({
            clientid: form.username,
          }),
        }
      );
      let exp = resExpired.data.lobj[0].value;

      localStorage.setItem("expired", exp);

      setLoginState({ step: 1, msg: null });

      window.location.reload(false);
      // console.log(response);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const lanjutAction = async () => {
    if (form?.no_hp?.length === 0) return toast.error("Masukkan No HP");

    try {
      let accounts = localStorage.getItem("active_user");
      let uuid = JSON.parse(accounts).uuid;
      let phone = JSON.parse(accounts).hp;
      let res = await dispatch(_fetch(AuthService.loginOtp({ phone, uuid })));
      if (!res.data) return;
      setLoginState({ step: 2, msg: res.data.value });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const loginAction = async () => {
    try {
      let accounts = localStorage.getItem("active_user");
      let uuid = JSON.parse(accounts).uuid;
      let res = await dispatch(
        _fetch(AuthService.verifyRegisterOtp({ otp: String(form.otp), uuid }))
      );
      await dispatch(
        login({
          idrs: res.idrs,
          namars: res.namars,
          poin: res.poin || 0,
          saldo: res.saldo || 0,
          token: res.token,
          phone: form.no_hp,
        })
      );

      //LOG LOGIN
      await dispatch(
        _fetch(
          BridgeService.JbMarketUUID(
            {
              key: "audittrail/create",
              method: "post",
              payload: JSON.stringify({
                desk: "Log login webadmin",
                telp: accounts?.hp,
                kodeAgen: res?.idrs,
              }),
            },
            uuid
          )
        )
      );
      // history.push("/dashboard");
      history.push("/dashboard", { replace: true });
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };

  console.log(loginState);

  const logo_login = localStorage.getItem("logo_login");

  return (
    <>
      <div className="limiter">
        <div className="container-login100 page-background">
          <div className="wrap-login100">
            {/* {error.error && (
            <div className="alert alert-danger">
              <strong>Error!</strong> {error.msg}
            </div>
          )} */}
            {loginState.step == 0 ? (
              <div className="login100-form validate-form">
                <span className="login100-form-title p-b-34 p-t-27">
                  Log in
                </span>
                <div className="wrap-input100 validate-input">
                  <input
                    className="input100"
                    type="text"
                    name="username"
                    placeholder="Username"
                    onChange={handleChange("username")}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder="&#xf2c8;"
                  ></span>
                </div>
                <div className="wrap-input100 validate-input">
                  <input
                    className="input100"
                    type="password"
                    name="nohp"
                    placeholder="Password"
                    onChange={handleChange("password")}
                  />
                  <span
                    className="focus-input100"
                    data-placeholder="&#xf191;"
                  ></span>
                </div>
                <div className="d-flex">
                  <div className="container-login100-form-btn">
                    <button
                      className="login100-form-btn btn-block"
                      onClick={() => signinFirst()}
                    >
                      Masuk
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login100-form validate-form">
                <span className="login100-form-logo">
                  <img
                    alt=""
                    src={
                      logo_login != ""
                        ? logo_login
                        : require("../../assets/img/sup.jpg")
                    }
                    style={{
                      borderRadius: 0,
                      width: logo_login != "" ? 100 : 200,
                    }}
                  />
                </span>
                <span className="login100-form-title p-b-34 p-t-27">
                  Log in
                </span>
                <div className="wrap-input100 validate-input">
                  <label className="text-light">No HP</label>
                  <select
                    className="form-control"
                    onChange={handleChange("no_hp")}
                  >
                    {accounts?.map((item, index) => {
                      return (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.hp}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {loginState.step === 2 && (
                  <div className="wrap-input100 validate-input">
                    <input
                      className="input100"
                      type="text"
                      name="otp"
                      placeholder="OTP"
                      onChange={handleChange("otp")}
                    />
                    <span
                      className="focus-input100"
                      data-placeholder="&#xf191;"
                    ></span>
                  </div>
                )}
                {loginState.step === 1 ? (
                  <div className="container-login100-form-btn">
                    <button
                      className="login100-form-btn"
                      onClick={() => lanjutAction()}
                    >
                      Lanjutkan
                    </button>
                  </div>
                ) : (
                  <div className="d-flex">
                    <div className="container-login100-form-btn">
                      <button
                        className="login100-form-btn btn-block"
                        onClick={() => loginAction()}
                      >
                        Masuk
                      </button>
                    </div>
                    <div className="container-login100-form-btn justify-content-end">
                      <button
                        className="login100-form-btn"
                        onClick={() =>
                          setLoginState({ ...loginState, step: 1 })
                        }
                      >
                        Batalkan
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default withRouter(SignIn);
