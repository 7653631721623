/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function PengaturanTopup() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    enableTopupVA: true,
    enableTopupQris: true,
    enableTopupIndomaret: true,
    minTopupVA: 0,
    minTopupQris: 0,
    minTopupIndomaret: 0,
    enableTopupQrisNobu: false,
    timeoutTopupVAinMinutes: 120,
    pesanTopupVA: "",
  });
  const [asli, setAsli] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbDelivery({ key: "allsetting" }))
    );
    const result = res?.data?.lobj || [];
    const obj = result.filter((item) => item?.idapps == "system.topup")[0];
    setAsli(obj);
    const data = JSON.parse(obj?.value);
    console.table(data);
    setForm(data);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async (pload) => {
    const value = JSON.stringify({ ...form });
    const payload = JSON.stringify({
      list: [{ idapps: asli.idapps, desc: asli.desc, value }],
    });
    console.log(payload);

    // return;
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "savesetting",
          payload,
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="setting-topup" />
          <Content>
            <PageHeading title="Pengaturan Topup User" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="va"
                      value="option1"
                      checked={form.enableTopupVA}
                      onChange={() => {
                        setForm({
                          ...form,
                          enableTopupVA: !form.enableTopupVA,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="va">
                      Aktifkan Topup VA
                    </label>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="qr"
                      value="option1"
                      checked={form.enableTopupQris}
                      onChange={() => {
                        setForm({
                          ...form,
                          enableTopupQris: !form.enableTopupQris,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="qr">
                      Aktifkan Topup QRIS
                    </label>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="id"
                      value="option1"
                      checked={form.enableTopupIndomaret}
                      onChange={() => {
                        setForm({
                          ...form,
                          enableTopupIndomaret: !form.enableTopupIndomaret,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="id">
                      Aktifkan Topup Alfamart/Indomaret
                    </label>
                  </div>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="qrb"
                      value="option1"
                      checked={form.enableTopupQrisNobu}
                      onChange={() => {
                        setForm({
                          ...form,
                          enableTopupQrisNobu: !form.enableTopupQrisNobu,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="qrb">
                      Aktifkan Qrisbu
                    </label>
                  </div>
                  <Input
                    label="Minimal Topup VA"
                    onChange={handleChange("minTopupVA")}
                    placeholder="10000"
                    value={form?.minTopupVA}
                  />
                  <Input
                    label="Minimal Topup QRIS"
                    onChange={handleChange("minTopupQris")}
                    placeholder="10000"
                    value={form?.minTopupQris}
                  />
                  <Input
                    label="Minimal Withdraw Indomaret"
                    onChange={handleChange("minTopupIndomaret")}
                    placeholder="10000"
                    value={form?.minTopupIndomaret}
                  />
                  <Input
                    label="Timeout VA (menit)"
                    onChange={handleChange("timeoutTopupVAinMinutes")}
                    placeholder="120"
                    value={form?.timeoutTopupVAinMinutes}
                  />
                  <Input
                    label="Pesan Topup VA"
                    onChange={handleChange("pesanTopupVA")}
                    placeholder="120"
                    value={form?.pesanTopupVA}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(PengaturanTopup);
