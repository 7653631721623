/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA, changeSecondToDateFormatHms } from "../../helpers";
// import TEMP_PRODUK from "../../constants/produk.json";

function ThirdParty({ history, location }) {
  // const [result, setResult] = useState([]);
  const [form, setForm] = useState({
    pin: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  // const [temp, setTemp] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [stateEdit, setStateEdit] = useState(0);

  console.error("stateEdit", stateEdit);

  const [pinShowed, setPinShowed] = useState(localStorage.getItem("time_pin"));
  const [otp, setOtp] = useState("");

  const [product, setProduct] = useState([]);

  const [timeStatus, setTimeStatus] = useState(
    localStorage.getItem("time_pin")
  );

  const getDifferenceTimeInHms = (time) => {
    const timeNow = new Date().getTime();
    const diff = timeNow - time;
    const diffInMinutes = diff / 1000;
    return diffInMinutes;
  };

  const [difference, setDifference] = useState(120);

  useEffect(() => {
    // const data = TEMP_PRODUK;
    // const newData = [];
    // data.map((item) => {
    //   const value = JSON.parse(item.value);
    //   const fee = value?.JUBER_FEE;
    //   return newData.push({
    //     idapps: item.idapps,
    //     imgs: item.imgs,
    //     desc: item.desc,
    //     fee,
    //   });
    // });
    // setProduct(data);
  }, []);

  console.log(product);

  //loop in every second
  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("time_pin")) return;
      const between = getDifferenceTimeInHms(localStorage.getItem("time_pin"));
      //hitung mundur
      setDifference(120 - between);
      //if 2 minutes passed, remove the pin
      if (between > 120) {
        setPinShowed(false);
        setTimeStatus(false);
        localStorage.removeItem("time_pin");
        setDifference(120);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getThirdParty = async (forcePin) => {
    if (!forcePin) {
      if (form?.pin == "") return toast.error("PIN tidak boleh kosong");
    }
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.get(
      `https://api.setting.berkah-ts.my.id/juber/auth/produk/${
        forcePin ? "123" : form.pin
      }?access_token=${token}`
    );

    // if (resultUrl.data.code != 200) return toast.error(resultUrl.data.msg);
    toast.error(resultUrl.data.msg);
    const data = resultUrl.data;
    console.log(data);

    if (forcePin) {
      //get epoch time
      const time = new Date().getTime();
      localStorage.setItem("time_pin", time);
      setPinShowed(true);
      setTimeStatus(true);
    } else {
      setProduct(data?.lobj);
      // setPinShowed(false);
      // setTimeStatus(false);
      // setDifference(120);
      // localStorage.removeItem("time_pin");
    }
  };

  console.log("pinshowed", pinShowed);

  // const setEdit = (data) => {
  //   setIsEdit(true);
  //   setForm(data);
  //   setTemp(data);
  // };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
    // setTemp(null);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async (forcePin) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const payload = {
      idapps: selectedData?.idapps,
      imgs: selectedData?.imgs,
      desc: selectedData?.desc,
      value: JSON.stringify(form),
    };

    console.log(payload, forcePin);

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/produk/save/${
        forcePin ? "123" : otp
      }?access_token=${token}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("resultUrl save", resultUrl);
    toast.info(resultUrl.data.msg);

    if (resultUrl.data.code != 200) {
      toast.error(
        resultUrl.data.msg ??
          "Gagal menyimpan data (tidak ada pesan dari backend!!)"
      );
      return;
    }
    if (forcePin) {
      toast.success("PIN telah dikirim");
      setStateEdit(1);
    } else {
      toast.success("Data berhasil disimpan");
      const data = payload;
      // search and replace data
      const index = product.findIndex((item) => item.idapps === data.idapps);
      if (index > -1) {
        product[index] = data;
      }
      setProduct([...product]);
      setIsEdit(false);
      setForm(null);
      // setTemp(null);
      setStateEdit(0);
    }
  };

  const editAction = (data) => {
    // setEdit(true);
    setIsEdit(true);
    setSelectedData(data);
    const value = JSON.parse(data.value);
    setForm(value);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="product-thirdparty" />
          <Content>
            <PageHeading title="Produk Thirdparty" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {pinShowed && (
                    <Input
                      label="PIN"
                      onChange={handleChange("pin")}
                      placeholder="083845"
                      value={form?.pin}
                    />
                  )}
                  {difference < 120 ? (
                    <label>
                      OTP akan expired dalam{" "}
                      {changeSecondToDateFormatHms(difference)}
                    </label>
                  ) : (
                    <label>
                      Klik <b>Kirim Pin</b> untuk melihat Data
                    </label>
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={!pinShowed ? "Kirim PIN" : "Lihat Data"}
                      type="warning"
                      onClick={() => {
                        timeStatus ? getThirdParty(false) : getThirdParty(true);
                      }}
                    />
                  </div>
                </Card>
                {isEdit && selectedData && (
                  <Card>
                    {Object.keys(JSON.parse(selectedData.value)).map(
                      (key, index) => {
                        return (
                          <Input
                            label={key}
                            onChange={handleChange(key)}
                            placeholder={key}
                            value={form[key]}
                          />
                        );
                      }
                    )}

                    <div className="d-flex align-items-end">
                      <Input
                        label="OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="083845"
                        value={otp}
                      />
                      <Button
                        title={`Kirim OTP (${changeSecondToDateFormatHms(
                          difference
                        )})`}
                        type="info"
                        onClick={() => saveAction(true)}
                        block={false}
                        disabled={difference < 120}
                        style={{ marginLeft: "1rem" }}
                      />
                    </div>

                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Simpan"}
                        type="warning"
                        onClick={() => saveAction(false)}
                        disabled={otp == ""}
                      />
                      {isEdit && (
                        <Button
                          title="Batal Edit"
                          type="danger"
                          onClick={() => cancelEdit()}
                          style={{ width: 130, marginLeft: 10 }}
                        />
                      )}
                    </div>
                  </Card>
                )}
                <Card>
                  <div className="row">
                    <div className="col-12">
                      <div className="row clearfix">
                        {product?.map((it, id) => {
                          return (
                            <div
                              className="col-lg-4 col-md-6 col-12 col-sm-6"
                              key={id}
                            >
                              <div className="card card-topline-yellow">
                                <div className="card-body no-padding height-9 mt-1">
                                  <ul className="list-group list-group-unbordered">
                                    <li
                                      className="list-group-item"
                                      style={{ borderTop: "0px none" }}
                                    >
                                      <b>{it.desc}</b>
                                    </li>
                                    {/* change the it.value object to array based on key */}
                                    {Object.keys(JSON.parse(it.value)).map(
                                      (key, index) => {
                                        return (
                                          <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <b
                                              style={{
                                                overflowWrap: "break-word",
                                                overflow: "hidden",
                                              }}
                                            >
                                              {key}
                                            </b>
                                            <div
                                              className="ml-2 pull-right"
                                              style={{
                                                overflowWrap: "break-word",
                                                overflow: "hidden",
                                                float: "right",
                                              }}
                                            >
                                              {JSON.parse(it.value)[key]}
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                  <div className="profile-userbuttons">
                                    <button
                                      type="button"
                                      className={`mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle ${
                                        it.isEditing
                                          ? "btn-danger"
                                          : "btn-warning"
                                      }`}
                                      data-upgraded=",MaterialButton,MaterialRipple"
                                      onClick={() => editAction(it)}
                                    >
                                      {it.isEditing ? "Simpan" : "Edit"}
                                      <span className="mdl-button__ripple-container">
                                        <span className="mdl-ripple"></span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ThirdParty);
