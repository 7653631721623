/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function Layanan({ history, location }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const [form, setForm] = useState({
    idlayanan: "",
    desc: "",
    type: "",
    stsaktif: "AKTIF",
    paket: "",
    docs: "",
  });
  const [paket, setPaket] = useState([]);
  const [dokumen, setDokumen] = useState([]);
  const [dokumenEdit, setDokumenEdit] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [formHarga, setFormHarga] = useState({
    idsetting: "",
    ongkir: "",
    jaraktambahan: "",
    tambahan: "",
    find: false,
  });

  useEffect(() => {
    getLayanan();
    getDokumen();
  }, []);

  const getLayanan = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbDelivery({ key: "mlayanan" }))
    );
    console.log(res);
    setResult(res?.data?.lobj);
    setOldItem(res?.data?.lobj);
  };

  const deleteLayanan = async (id) => {
    const payload = {
      method: "post",
      key: "showlayanan/delete",
      payload: JSON.stringify({ id }),
    };
    await dispatch(_fetch(BridgeService.JbMarket(payload)));

    getLayanan();
    getDokumen();
  };

  const getHargaLayanan = async (id) => {
    setModal(true);
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbMarket({ key: "settinghargalayanan", method: "get" })
      )
    );
    console.log(res);
    const results = res?.data?.data;
    // find by idsetting in id
    const find = results?.find((x) => x?.idsetting === id);
    console.log("find", find);
    if (!find) {
      setFormHarga({
        idsetting: id,
        ongkir: "",
        jaraktambahan: "",
        tambahan: "",
        find: false,
      });
      return;
    }
    setFormHarga({
      ...find,
      find: true,
    });
  };

  const getDokumen = async () => {
    const datas = await dispatch(
      _fetch(BridgeService.JbMarket({ key: "refdokumen", method: "get" }))
    );
    console.log(datas?.data?.data);
    const dokumen = datas?.data?.data?.map((item) => {
      return {
        no: item?.iddok,
        name: item?.namadok,
      };
    });

    setDokumen(dokumen);
  };
  console.log(dokumen);
  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  // const handleChangePaket = (index) => (e) => {
  //   const newPaket = [...paket];
  //   newPaket[index] = e.target.value;
  //   setPaket(newPaket);
  // };

  const deleteDokumen = (index) => {
    const newDokumen = [...dokumenEdit];
    newDokumen.splice(index, 1);
    setDokumenEdit(newDokumen);
  };

  const saveAction = async () => {
    //remove space or '' in paket
    const oldPaket = paket?.filter((item) => item !== "");
    const newPaket = oldPaket?.join("#");
    //join the dokumenEdit in document.no then if required true add * in front of the no
    const newDokumen = dokumenEdit
      ?.map((item) => {
        if (item?.required) {
          return `${item?.document?.no}*`;
        } else {
          return item?.document?.no;
        }
      })
      .join("#");

    if (!isEdit) {
      const payload = {
        desc: form?.desc,
        type: form?.type,
        stsaktif: form?.stsaktif,
        paket: newPaket,
        docs: newDokumen,
      };

      await dispatch(
        _fetch(
          BridgeService.JbMarket({
            method: "post",
            key: "showlayanan/create",
            payload: JSON.stringify(payload),
          })
        )
      );
    } else {
      const payload = {
        ...form,
        paket: newPaket,
        docs: newDokumen,
      };
      await dispatch(
        _fetch(
          BridgeService.JbDelivery({
            key: "savelayanan",
            payload: JSON.stringify(payload),
          })
        )
      );
    }

    getLayanan();
    clearForm();
  };

  const cancelEdit = () => {
    clearForm();
  };

  const clearForm = () => {
    setForm({
      idlayanan: "",
      desc: "",
      type: "",
      stsaktif: "AKTIF",
      paket: "",
    });
    setIsEdit(false);
    setPaket([]);
    setDokumenEdit([]);
  };

  // const addPaket = () => {
  //   setPaket([...paket, ""]);
  // };

  const onAddDokumen = () => {
    const newDokumen = dokumenEdit?.length > 0 ? [...dokumenEdit] : [];
    newDokumen.push({
      required: false,
      document: {
        no: "",
        name: "",
      },
    });
    setDokumenEdit(newDokumen);
  };

  console.log(dokumenEdit);

  const simpanHarga = async () => {
    const payload = {
      id: formHarga?.idsetting,
      ongkir: formHarga?.ongkir,
      jaraktambahan: formHarga?.jaraktambahan,
      tambahan: formHarga?.tambahan,
    };

    let key;
    const find = formHarga?.find;
    if (find) {
      key = "settinghargalayanan/update";
    } else {
      key = "settinghargalayanan/create";
    }
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key,
          method: "post",
          payload: JSON.stringify(payload),
        })
      )
    );
    if (res?.data?.success) {
      toast.success("Berhasil menyimpan harga");
    } else {
      toast.error("Gagal menyimpan harga");
    }
    setModal(false);
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.desc?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="layanan" />
          <Content>
            <PageHeading title="Nama Layanan" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {isEdit && (
                    <Input
                      label="ID Layanan"
                      onChange={handleChange("idlayanan")}
                      placeholder="contoh jb001"
                      value={form?.idlayanan}
                    />
                  )}
                  <Input
                    label="Nama Layanan"
                    onChange={handleChange("desc")}
                    placeholder="contoh Nama Ride"
                    value={form?.desc}
                  />
                  <Input
                    label="Type"
                    onChange={handleChange("type")}
                    placeholder="contoh RIDE/SEND/FOOD/DLL"
                    value={form?.type}
                  />
                  <div className="form-group">
                    <label>Status</label>
                    <select
                      className="form-control"
                      onChange={handleChange("stsaktif")}
                      value={form?.stsaktif}
                    >
                      <option value="AKTIF">AKTIF</option>
                      <option value="TIDAK AKTIF">TIDAK AKTIF</option>
                    </select>
                  </div>
                  <div className="mt-3" />
                  {/* <Input
                    label="Paket"
                    onChange={handleChange("paket")}
                    placeholder="contoh jb001,jbs01"
                    value={form?.paket}
                  /> */}
                  <hr />
                  <div className="form-group d-flex flex-column">
                    <label>Paket</label>
                    <div>
                      {result?.map((item, index) => {
                        //checkbox
                        return (
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id={item?.idlayanan}
                              value="option1"
                              checked={paket?.includes(item?.idlayanan)}
                              onChange={() => {
                                if (paket?.includes(item?.idlayanan)) {
                                  const newPaket = paket?.filter(
                                    (x) => x != item?.idlayanan
                                  );
                                  setPaket(newPaket);
                                } else {
                                  setPaket([...paket, item?.idlayanan]);
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              for={item?.idlayanan}
                            >
                              {item?.idlayanan}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="form-group d-flex flex-column">
                    <label>Dokumen</label>
                  </div>
                  {dokumenEdit?.map((item, index) => {
                    return (
                      <div className="form-group d-flex flex-column">
                        <label>Dokumen {index + 1}</label>
                        <div className="d-flex">
                          <div className="d-flex flex-column mr-2">
                            <label>Nama Dokumen</label>
                            <select
                              className="form-control"
                              value={JSON.stringify(item?.document)}
                              onChange={(e) => {
                                console.log("e", e.target.value);
                                const newDokumen = [...dokumenEdit];
                                const parse = JSON.parse(e.target.value);
                                newDokumen[index].document.name =
                                  parse?.name || "";
                                newDokumen[index].document.no = parse?.no || "";
                                setDokumenEdit(newDokumen);
                              }}
                            >
                              <option value="">Pilih Dokumen</option>
                              {dokumen?.map((item, index) => {
                                return (
                                  <option value={JSON.stringify(item)}>
                                    {item?.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="d-flex flex-column">
                            <label>Required</label>
                            <select
                              className="form-control"
                              value={item?.required}
                              onChange={(e) => {
                                const newDokumen = [...dokumenEdit];
                                newDokumen[index].required =
                                  e.target.value === "true";
                                setDokumenEdit(newDokumen);
                              }}
                            >
                              <option value={true}>Ya</option>
                              <option value={false}>Tidak</option>
                            </select>
                          </div>
                          <div className="ml-2 d-flex align-items-end">
                            <a
                              className="btn btn-tbl-remove-no-round btn-xs"
                              onClick={() => deleteDokumen(index)}
                            >
                              <i className="fa fa-trash"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div
                    className="mb-3 btn btn-circle btn-sms btn-warning"
                    onClick={() => onAddDokumen()}
                  >
                    <i className="fa fa-plus"></i> Tambahkan Dokumen{" "}
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th>ID Layanan</th>
                          <th> Nama </th>
                          <th> Type </th>
                          <th> Status </th>
                          <th> Paket </th>
                          <th> Dokumen </th>
                          <th className="text-center"> Aksi </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          const p = item?.paket;
                          console.log("p", p);
                          //remove space or '' in paket
                          const oldPaket = p
                            ?.split("#")
                            ?.filter((item) => item !== "");
                          const newPaket = oldPaket?.join("#");
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td className="">{item.idlayanan}</td>
                              <td className="">{item.desc}</td>
                              <td className="">{item.type}</td>
                              <td className="">
                                <span
                                  className={`badge badge-pill ${
                                    item.stsaktif !== "AKTIF"
                                      ? "badge-primary"
                                      : "badge-warning"
                                  }`}
                                >
                                  {item.stsaktif}
                                </span>
                              </td>
                              <td className="">
                                {newPaket?.replace(/#/g, ",")}
                              </td>
                              <td className="">
                                {/* find the item.docs in dokumen and add the name */}
                                {item?.docs
                                  ?.split("#")
                                  ?.map((item) => {
                                    const required = item.includes("*");
                                    const find = dokumen?.find(
                                      (x) => x?.no === item.replace("*", "")
                                    );
                                    return required
                                      ? `${find?.name} (Wajib)`
                                      : find?.name;
                                  })
                                  ?.join(", ")}
                              </td>
                              <td className="center">
                                <a
                                  className="btn btn-tbl-remove-no-round btn-xs"
                                  onClick={() => {
                                    getHargaLayanan(item?.idlayanan);
                                  }}
                                >
                                  <i className="fa fa-dollar"></i> Set Harga
                                </a>
                                <a
                                  className="btn btn-tbl-edit-no-round btn-xs"
                                  onClick={() => {
                                    setIsEdit(true);
                                    setForm(item);
                                    setPaket(item?.paket?.split("#") || []);
                                    //set dokumenEdit to array of object then search the name in dokumen and add it to the object
                                    const newDokumen = item?.docs?.split("#");
                                    const newDokumenEdit = newDokumen?.map(
                                      (item) => {
                                        const find = dokumen?.find(
                                          (x) => x?.no === item.replace("*", "")
                                        );
                                        return {
                                          required: item.includes("*"),
                                          document: {
                                            no: item.replace("*", ""),
                                            name: find?.name || "",
                                          },
                                        };
                                      }
                                    );
                                    setDokumenEdit(newDokumenEdit);
                                  }}
                                >
                                  <i className="fa fa-pencil"></i> Edit
                                </a>
                                <a
                                  className="btn btn-tbl-remove-no-round btn-xs"
                                  onClick={() => {
                                    deleteLayanan(item?.idlayanan);
                                  }}
                                >
                                  <i className="fa fa-trash-o"></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <Input
            label="ID Layanan"
            placeholder="contoh jb001"
            value={formHarga?.idsetting}
            readOnly
          />
          <Input
            label="Ongkir"
            onChange={(val) => {
              setFormHarga({
                ...formHarga,
                ongkir: val.target.value,
              });
            }}
            placeholder="contoh 20000"
            value={formHarga?.ongkir}
          />
          <Input
            label="Jarak Tambahan"
            onChange={(val) => {
              setFormHarga({
                ...formHarga,
                jaraktambahan: val.target.value,
              });
            }}
            placeholder="contoh 3"
            value={formHarga?.jaraktambahan}
          />
          <Input
            label="Tambahan"
            onChange={(val) => {
              setFormHarga({
                ...formHarga,
                tambahan: val.target.value,
              });
            }}
            placeholder="contoh 1500"
            value={formHarga?.tambahan}
          />
          <Button title="Simpan" type="warning" onClick={() => simpanHarga()} />
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Layanan);
