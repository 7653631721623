/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import axios from "axios";
import { AtoB, BtoA, formatRupiah } from "../../helpers";

function HistoryWithdrawManual() {
  const [form, setForm] = useState({
    clientid: "",
    id: "",
    otp: "",
    type: "",
    reason: "",
  });
  const [results, setResults] = useState([]);
  const [modal, setModal] = useState(false);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    let client = "";

    let atob = AtoB("kocak123");
    let key = atob("isadmin");
    let isadmin = localStorage.getItem(key);
    let valueIya = atob("IYA ADMIN");

    if (isadmin == valueIya) {
      client = "all";
      setIsAdmin(true);
    } else {
      client = username;
      setIsAdmin(false);
    }

    const payload = {
      desc: "listwithdraw",
      value: JSON.stringify({ clientid: client }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );
    const datas = resultUrl.data.lobj[0];
    if (isadmin == valueIya) {
      setResults(datas);
      setOldItem(datas);
    } else {
      const newDatas = resultUrl?.data?.lobj[0].filter((item) =>
        item?.value1?.split("#")[0]?.includes(username)
      );
      setResults(newDatas);
      setOldItem(newDatas);
    }
  };

  const sendOtp = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      desc: "otpwithdraw",
      value: JSON.stringify({ clientid: form.clientid }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );
    const response = resultUrl.data;
    if (response?.code == 200) {
      toast.success("OTP berhasil dikirim");
    } else {
      toast.error("OTP gagal dikirim");
    }
  };

  console.log(form);

  const saveAction = async (pload) => {
    setModal(false);
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      ...form,
    };

    const body = {
      desc: "confirm/withdraw",
      value: JSON.stringify(payload),
    };

    console.log(body);

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      body
    );

    if (resultUrl.data.code == 200) {
      toast.success(
        `${form.type == "confirm" ? "Konfirmasi" : "Tolak"} Berhasil`
      );
      getData();
      return;
    } else {
      return toast.error(
        `${form.type == "confirm" ? "Konfirmasi" : "Tolak"} Gagal, ${
          resultUrl.data.msg
        }`
      );
    }
  };

  console.log(results?.filter((it) => it?.value1?.split("#")[1] == "pending"));

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter(
              (item) =>
                item?.value1?.toLowerCase().includes(query.toLowerCase()) ||
                item?.value2?.norek
                  ?.toLowerCase()
                  .includes(query.toLowerCase()) ||
                item?.value2?.namaPemilikRek
                  ?.toLowerCase()
                  .includes(query.toLowerCase())
            );
            setResults(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="history-withdraw-manual" />
          <Content>
            <PageHeading title="Riwayat Tarik Saldo Manual Mitra" />
            <div className="row">
              <div className="col-12">
                {!isAdmin && (
                  <Card>
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> Nama Client </th>
                          <th className=""> Nominal </th>
                          <th className=""> Tanggal & Waktu </th>
                          <th className=""> Bank </th>
                          <th className=""> Nomor Rekening </th>
                          <th className=""> Atas Nama </th>
                          <th className=""> Keterangan </th>
                          <th className=""> Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {results?.map((it, index) => {
                          const item = it;
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td className="">
                                {item?.value1?.split("#")[0]}
                              </td>
                              <td className="">
                                {formatRupiah(item?.nominal)}
                              </td>
                              <td className="">{item?.tgl}</td>
                              <td className="">{item?.value2?.namaBank}</td>
                              <td className="">{item?.value2?.norek}</td>
                              <td className="">
                                {item?.value2?.namaPemilikRek}
                              </td>
                              <td className="">{item?.desk}</td>
                              <td className="">
                                <span
                                  className={
                                    item.value1?.split("#")[1] == "pending"
                                      ? "badge badge-pill badge-warning"
                                      : item.value1?.split("#")[1] == "debet"
                                      ? "badge badge-pill badge-ijo"
                                      : "badge badge-pill badge-danger"
                                  }
                                >
                                  {item.value1?.split("#")[1] == "pending"
                                    ? "Pending"
                                    : item.value1?.split("#")[1] == "debet"
                                    ? "Sukses"
                                    : "Batal"}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card>
                )}

                {isAdmin && (
                  <>
                    <h3 className="mb-1">Pending</h3>
                    <Card>
                      <table
                        className="table table-hover table-checkable order-column full-width"
                        id="example4"
                      >
                        <thead>
                          <tr>
                            <th className=""> Nama Client </th>
                            <th className=""> Nominal </th>
                            <th className=""> Tanggal & Waktu </th>
                            <th className=""> Bank </th>
                            <th className=""> Nomor Rekening </th>
                            <th className=""> Atas Nama </th>
                            <th className=""> Keterangan </th>
                            <th className=""> Status </th>
                            <th className="text-center">Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results
                            ?.filter(
                              (it) => it?.value1?.split("#")[1] == "pending"
                            )
                            .map((it, index) => {
                              const item = it;
                              return (
                                <tr className="odd gradeX" key={index}>
                                  <td className="">
                                    {item?.value1?.split("#")[0]}
                                  </td>
                                  <td className="">
                                    {formatRupiah(item?.nominal)}
                                  </td>
                                  <td className="">{item?.tgl}</td>
                                  <td className="">{item?.value2?.namaBank}</td>
                                  <td className="">{item?.value2?.norek}</td>
                                  <td className="">
                                    {item?.value2?.namaPemilikRek}
                                  </td>
                                  <td className="">{item?.desk}</td>
                                  <td className="">
                                    <span
                                      className={`badge badge-pill badge-warning`}
                                    >
                                      {item.value1?.split("#")[1]}
                                    </span>
                                  </td>
                                  <td className="center">
                                    <a
                                      className="btn btn-tbl-edit-no-round btn-xs"
                                      onClick={async () => {
                                        setModal(true);
                                        setForm({
                                          ...form,
                                          type: "confirm",
                                          clientid: item?.value1?.split("#")[0],
                                          id: item?.idapps,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-check"></i> Terima
                                    </a>
                                    <a
                                      className="btn btn-tbl-remove-no-round btn-xs"
                                      onClick={() => {
                                        //   deleteAction(index);
                                        setModal(true);
                                        setForm({
                                          ...form,
                                          type: "cancel",
                                          clientid: item?.value1?.split("#")[0],
                                          id: item?.idapps,
                                        });
                                      }}
                                    >
                                      <i className="fa fa-times "></i> Tolak
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Card>
                    <h3 className="mb-1">Sukses</h3>
                    <Card>
                      <table
                        className="table table-hover table-checkable order-column full-width"
                        id="example4"
                      >
                        <thead>
                          <tr>
                            <th className=""> Nama Client </th>
                            <th className=""> Nominal </th>
                            <th className=""> Tanggal & Waktu </th>
                            <th className=""> Bank </th>
                            <th className=""> Nomor Rekening </th>
                            <th className=""> Atas Nama </th>
                            <th className=""> Keterangan </th>
                            <th className=""> Status </th>
                            <th className="text-center">Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results
                            ?.filter(
                              (it) => it?.value1?.split("#")[1] == "debet"
                            )
                            .map((it, index) => {
                              const item = it;
                              return (
                                <tr className="odd gradeX" key={index}>
                                  <td className="">
                                    {item?.value1?.split("#")[0]}
                                  </td>
                                  <td className="">
                                    {formatRupiah(item?.nominal)}
                                  </td>
                                  <td className="">{item?.tgl}</td>
                                  <td className="">{item?.value2?.namaBank}</td>
                                  <td className="">{item?.value2?.norek}</td>
                                  <td className="">
                                    {item?.value2?.namaPemilikRek}
                                  </td>
                                  <td className="">{item?.desk}</td>
                                  <td className="">
                                    <span
                                      className={`badge badge-pill badge-ijo`}
                                    >
                                      {item.value1?.split("#")[1]}
                                    </span>
                                  </td>
                                  <td className="center">
                                    {/* <a
                                  className="btn btn-tbl-edit-no-round btn-xs"
                                  onClick={async () => {
                                    setModal(true);
                                    setForm({
                                      ...form,
                                      type: "confirm",
                                      clientid: item?.value1?.split("#")[0],
                                      id: item?.idapps,
                                    });
                                  }}
                                >
                                  <i className="fa fa-check"></i> Terima
                                </a>
                                <a
                                  className="btn btn-tbl-remove-no-round btn-xs"
                                  onClick={() => {
                                    //   deleteAction(index);
                                    setModal(true);
                                    setForm({
                                      ...form,
                                      type: "cancel",
                                      clientid: item?.value1?.split("#")[0],
                                      id: item?.idapps,
                                    });
                                  }}
                                >
                                  <i className="fa fa-times "></i> Tolak
                                </a> */}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Card>
                    <h3 className="mb-1">Ditolak</h3>
                    <Card>
                      <table
                        className="table table-hover table-checkable order-column full-width"
                        id="example4"
                      >
                        <thead>
                          <tr>
                            <th className=""> Nama Client </th>
                            <th className=""> Nominal </th>
                            <th className=""> Tanggal & Waktu </th>
                            <th className=""> Bank </th>
                            <th className=""> Nomor Rekening </th>
                            <th className=""> Atas Nama </th>
                            <th className=""> Keterangan </th>
                            <th className=""> Status </th>
                            <th className="text-center">Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results
                            ?.filter(
                              (it) => it?.value1?.split("#")[1] == "cancel"
                            )
                            .map((it, index) => {
                              const item = it;
                              return (
                                <tr className="odd gradeX" key={index}>
                                  <td className="">
                                    {item?.value1?.split("#")[0]}
                                  </td>
                                  <td className="">
                                    {formatRupiah(item?.nominal)}
                                  </td>
                                  <td className="">{item?.tgl}</td>
                                  <td className="">{item?.value2?.namaBank}</td>
                                  <td className="">{item?.value2?.norek}</td>
                                  <td className="">
                                    {item?.value2?.namaPemilikRek}
                                  </td>
                                  <td className="">{item?.desk}</td>
                                  <td className="">
                                    <span
                                      className={`badge badge-pill badge-danger`}
                                    >
                                      {item.value1?.split("#")[1]}
                                    </span>
                                  </td>
                                  <td className="center">
                                    {/* <a
                                  className="btn btn-tbl-edit-no-round btn-xs"
                                  onClick={async () => {
                                    setModal(true);
                                    setForm({
                                      ...form,
                                      type: "confirm",
                                      clientid: item?.value1?.split("#")[0],
                                      id: item?.idapps,
                                    });
                                  }}
                                >
                                  <i className="fa fa-check"></i> Terima
                                </a>
                                <a
                                  className="btn btn-tbl-remove-no-round btn-xs"
                                  onClick={() => {
                                    //   deleteAction(index);
                                    setModal(true);
                                    setForm({
                                      ...form,
                                      type: "cancel",
                                      clientid: item?.value1?.split("#")[0],
                                      id: item?.idapps,
                                    });
                                  }}
                                >
                                  <i className="fa fa-times "></i> Tolak
                                </a> */}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <div className="d-flex align-items-end">
            <Input
              label="OTP"
              onChange={(e) => {
                setForm({
                  ...form,
                  otp: e.target.value,
                });
              }}
              value={form?.otp}
            />
            <Button
              title={`Kirim OTP`}
              type="info"
              onClick={() => sendOtp()}
              block={false}
              style={{ marginLeft: "1rem" }}
            />
          </div>
          <Input
            label="Alasan"
            onChange={(e) => {
              setForm({
                ...form,
                reason: e.target.value,
              });
            }}
            value={form?.reason}
          />
          <Button
            title={form.type == "confirm" ? "Konfirmasi" : "Tolak"}
            type="warning"
            onClick={() => saveAction()}
          />
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(HistoryWithdrawManual);
