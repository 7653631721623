/* eslint-disable eqeqeq */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA, formatDate } from "../../helpers";

function BuildApk() {
  const [client, setClient] = useState("");
  const [form, setForm] = useState({
    name: "Dynamic",
    home: "Test dari postman",
    client: "",
    package: "",
    version: "",
    type: "apk",
  });
  const [lastApk, setLastApk] = useState("");
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState(null);
  const [notif, setNotif] = useState(null);
  const [splash, setSplash] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkVersion();
    getSplash();
    getNotif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //interval every 5s
    const interval = setInterval(() => {
      getNotif();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const getSplash = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const res = await axios.get(
      `https://apps-build.berkah-ts.my.id/api/splashscreen?username=${username}`
    );
    const data = res?.data;
    if (data.success) {
      return setSplash(data?.data?.splashscreen);
    } else {
      return toast.error(data?.message);
    }
  };

  const getNotif = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const res = await axios.get(
      `https://apps-build.berkah-ts.my.id/api/notification/${username}`
    );
    const data = res?.data;
    if (data.success) {
      return setNotif(data?.data);
    } else {
      return toast.error(data?.message);
    }
  };

  console.log(splash);

  const checkVersion = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const res = await axios.get(
      `https://apps-build.berkah-ts.my.id/api/profile/${username}`
    );
    const data = res?.data;
    if (data.success) {
      setClient(data?.data?.client);
      setForm({
        ...form,
        name: data?.data?.client?.name,
        client: username,
        package: data?.data?.client?.package,
        version:
          form.type === "apk"
            ? data?.data?.client?.version ?? "0.0.00"
            : data?.data?.client?.version_aab ?? "0.0.00",
      });
      setLastApk(data?.data?.apk?.terbaru);
      setIcon(data?.data?.client?.icon);
    } else {
      return toast.error(data?.message);
    }
    console.log(data);
    // setVersion(res?.data?.data?.config);
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const buildAction = async (dataIcon) => {
    const res = await axios.post(
      "https://apps-build.berkah-ts.my.id/api/deploy",
      {
        ...form,
        icon: dataIcon ?? null,
      }
    );
    const data = res?.data;
    if (!data.success) {
      setLoading(false);
      return toast.error(data?.message);
    } else {
      setLoading(false);
      toast.success(data?.message);
      setResult(data?.message);
      getNotif();
    }
  };

  const getDownload = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/download/" + file
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      window.open(res?.data?.data?.file, "_blank");
    }
  };

  useEffect(() => {
    const app = lastApk;
    if (form.type === "aab") {
      setForm({
        ...form,
        version: app?.aab?.version ?? "0.0.00",
      });
    } else {
      setForm({
        ...form,
        version: app?.apk?.version ?? "0.0.00",
      });
    }
    console.log("a");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.type]);

  const [formImage, setFormImage] = useState(null);
  const [formIcon, setFormIcon] = useState(null);

  const editAction = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    let username = btoa(userSave);
    username = username.toLowerCase();
    var formData = new FormData();
    //validate if formImage is null
    formData.append("file", formImage, formImage?.name);
    formData.append("username", username);
    const res = await axios.post(
      `https://apps-build.berkah-ts.my.id/api/splashscreen`,
      formData
    );
    const data = res?.data;
    if (data.success) {
      window.location.reload();
      setFormImage(null);
      setFormIcon(null);
      toast.success(data?.message);
      return;
    } else {
      return toast.error(data?.message);
    }
  };

  console.log(splash, form);

  const changeIcon = async () => {
    if (form.version === "0.0.00")
      return toast.error("Version tidak boleh 0.0.00");
    setLoading(true);
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    let username = btoa(userSave);
    username = username.toLowerCase();
    var formData = new FormData();
    if (formIcon !== null) {
      formData.append("icon", formIcon, formIcon?.name);
    }
    formData.append("client", username);
    formData.append("name", form?.name);
    let res = null;
    if (!error) {
      res = await axios.post(
        `https://apps-build.berkah-ts.my.id/api/icon`,
        formData
      );
    }
    const data = res?.data;
    if (data.success) {
      await checkVersion();
      await setFormImage(null);
      await setFormIcon(null);
      await setIcon(data?.data?.icon);
      // toast.success(data?.message);
      await buildAction(data?.data?.icon);
      return;
    } else {
      toast.error(data?.message);
      setLoading(false);
      return;
    }
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = () => {
      // Validate the image dimensions
      if (img.width > 473 || img.height > 473) {
        setError("Icon tidak boleh lebih dari 473 x 473 pixels.");
        setFormIcon(null);
      } else {
        setError("");
        setFormIcon(file);
      }
      URL.revokeObjectURL(url); // Clean up the object URL
    };

    img.onerror = () => {
      setError("Icon file tidak valid.");
      setFormIcon(null);
    };

    img.src = url;
  };

  const getNotifBody = (data) => {
    if (data?.status == 0) {
      //return with loading status
      return (
        <div className="alert alert-info d-flex align-items-center">
          <div className="spinner-border text-light p-0" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="ml-3" />
          Build {data?.type} versi {data?.version} sedang diproses
        </div>
      );
    }

    if (data?.status == 1) {
      return (
        <div className="alert alert-success d-flex align-items-center">
          <i className="material-icons">check</i>
          <div className="ml-3">
            Build {data?.type} versi {data?.version} berhasil
          </div>
        </div>
      );
    }

    if (data?.status == 2) {
      return (
        <div className="alert alert-danger d-flex align-items-center">
          <i className="material-icons">error</i>
          <div className="ml-3">
            Build {data?.type} versi {data?.version} gagal
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="build-apk" />
          <Content>
            <PageHeading title="Build APK [Beta]" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <h4>SplashScreen (800x1226)</h4>
                  <h5 className="text-danger font-weight-bold">
                    PASTIKAN BACKGROUND TIDAK TRANSPARANT
                  </h5>
                  {splash ? (
                    <img
                      src={splash}
                      alt=""
                      className="img-thumbnail mb-2"
                      style={{
                        height: "auto",
                        width: "20rem",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <h3>Belum ada splashscreen</h3>
                  )}
                  <hr />
                  {formImage === null ? (
                    <Input
                      accept="image/*"
                      label="Ubah Gambar"
                      type="file"
                      onChange={(e) => setFormImage(e.target.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={formImage && URL.createObjectURL(formImage)}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => setFormImage(null)}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title="Submit"
                      type="warning"
                      onClick={() => editAction()}
                    />
                  </div>
                </Card>

                <Card>
                  <h5 className="font-weight-bold">Notifikasi Build</h5>
                  {notif != null ? getNotifBody(notif) : "Belum ada notifikasi"}
                </Card>

                <Card>
                  <>
                    <div className="alert alert-primary">
                      Client Env = {client?.name || "Belum ada"}
                    </div>
                    <div className="alert alert-warning d-flex align-items-center">
                      APK Terakhir = <b>{lastApk?.apk?.version || "0.0.0"} </b>,
                      Waktu:{" "}
                      {formatDate(
                        lastApk?.apk?.created_at,
                        "date monthName year, hour:minute"
                      )}
                      {lastApk?.apk && (
                        <Button
                          title="Download"
                          noMb={true}
                          type="danger"
                          block={false}
                          style={{
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            //open the link in new tab in response {lastApk.link}
                            getDownload(lastApk?.apk?.file);
                          }}
                        />
                      )}
                    </div>
                    <div className="alert alert-warning d-flex align-items-center">
                      AAB Terakhir = <b>{lastApk?.aab?.version || "0.0.0"} </b>,
                      Waktu:{" "}
                      {formatDate(
                        lastApk?.aab?.created_at,
                        "date monthName year, hour:minute"
                      )}
                      {lastApk?.aab && (
                        <Button
                          title="Download"
                          noMb={true}
                          type="danger"
                          block={false}
                          style={{
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            //open the link in new tab in response {lastApk.link}
                            getDownload(lastApk?.aab?.file);
                          }}
                        />
                      )}
                    </div>
                  </>
                  <Input
                    label="Nama APK"
                    value={form?.name}
                    onChange={(e) => {
                      setForm({ ...form, name: e.target.value });
                    }}
                  />
                  <label>Icon APK (432px x 432px)</label>
                  <br />
                  <label>Icon harus berupa format .png (WAJIB png)</label>
                  <br />
                  {icon ? (
                    <img
                      src={icon}
                      alt=""
                      className="img-thumbnail mb-2"
                      style={{
                        height: "auto",
                        width: "5rem",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <h3>Belum ada icon</h3>
                  )}
                  {formIcon === null ? (
                    <>
                      <Input
                        accept=".png"
                        label="Ubah Icon"
                        type="file"
                        onChange={handleImageChange}
                      />
                      {error && (
                        <div className="text-danger">{error}</div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={formIcon && URL.createObjectURL(formIcon)}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => setFormIcon(null)}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <Input
                    label="Masukkan Versi"
                    name="version"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    placeholder="Masukkan Versi"
                    value={form?.version}
                  />
                  <div className="form-group">
                    <label>Type</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      value={form?.type}
                    >
                      <option value="apk">APK (Untuk download)</option>
                      <option value="aab">AAB (Untuk upload playstore)</option>
                    </select>
                  </div>
                  <label>
                    Versi harus lebih besar dari versi sebelumnya dengan format{" "}
                    <b>x.x.xx</b> misal <b>1.0.25</b>
                  </label>
                  <label>
                    <i>
                      Versi tidak boleh memakai angka 0 semua misal{" "}
                      <b>0.0.00</b> Tolong isikan minimal versi <b>1.0.00</b>{" "}
                      atau <b>0.1.00</b> atau <b>0.0.10</b>
                    </i>
                  </label>
                  <Button
                    loading={loading}
                    title="Build"
                    type="warning"
                    onClick={() => changeIcon()}
                  />
                  {result && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result}</div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default BuildApk;
