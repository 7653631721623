/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { BtoA, changeSecondToDateFormatHms } from "../../helpers";
import axios from "axios";

function ChangeUUID({ history, location }) {
  let userAccount = useSelector((state) => state.auth);
  userAccount = userAccount?.user;
  console.table(userAccount);
  // let saldo = useSelector((state) => state.saldo);
  // saldo = saldo?.saldo;
  const userSave = localStorage.getItem("credential");
  const btoa = BtoA("kocak123");
  const username = btoa(userSave);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kodeAgen: "",
  });
  const [pinShowed, setPinShowed] = useState(false);
  const [difference, setDifference] = useState(120);
  const [uuid, setUuid] = useState([""]);

  console.log(form);

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      ...form,
      uuid: uuid.join("#"),
      clientid: username,
    };

    console.table(payload);
    // return;
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "update/uuid",
          payload: JSON.stringify(payload),
        })
      )
    );

    // if (res?.data?.code == 200) {
    //   toast.success(res?.data?.msg);
    // } else {
    //   toast.error(res?.data?.msg);
    // }
  };

  //   const [clients, setClients] = useState([]);
  //   const [selectedClient, setSelectedClient] = useState("");

  useEffect(() => {
    // getCLient();
  }, []);

  //   const getCLient = async (file) => {
  //     const res = await axios.get(
  //       "https://apps-build.berkah-ts.my.id/api/clients"
  //     );
  //     const data = res?.data;
  //     if (!data.success) {
  //       return toast.error(data?.message);
  //     } else {
  //       let client = data?.data;
  //       //add btsglobal to client
  //       client = [
  //         ...client,
  //         {
  //           name: "BTSGLOBAL",
  //           username: "btsglobal",
  //         },
  //       ];
  //       setClients(client);
  //     }
  //   };

  const sendOtp = async () => {
    try {
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: username,
          password: "password",
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      console.log(result.data);

      //   const token = result.data.access_token;

      const payload = {
        method: "post",
        key: "otp/admin",
        payload: JSON.stringify({ clientid: username }),
      };

      try {
        const resultUrl = await dispatch(
          _fetch(BridgeService.JbMarket(payload))
        );

        const response = resultUrl.data;
        const msg = response?.msg;
        console.log(response);
        if (response.code == 200) {
          toast.success("OTP berhasil dikirim");
          const time = new Date().getTime();
          localStorage.setItem("time_pin", time);
          setPinShowed(true);
        } else {
          toast.error(msg);
        }
      } catch (error) {
        toast.error(error?.response?.data?.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDifferenceTimeInHms = (time) => {
    const timeNow = new Date().getTime();
    const diff = timeNow - time;
    const diffInMinutes = diff / 1000;
    return diffInMinutes;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("time_pin")) return;
      const between = getDifferenceTimeInHms(localStorage.getItem("time_pin"));
      //hitung mundur
      setDifference(120 - between);
      //if 2 minutes passed, remove the pin
      if (between > 120) {
        setPinShowed(false);
        localStorage.removeItem("time_pin");
        setDifference(120);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const tambahUUID = () => {
    setUuid([...uuid, ""]);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="change-uuid" />
          <Content>
            <PageHeading title="Ubah UUID" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {/* <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                  <Input
                    label="Kode Agen"
                    onChange={handleChange("kodeAgen")}
                    placeholder="XXXX"
                    value={form?.kodeAgen}
                  />
                  <div className="form-group">
                    <label>UUID</label>
                    {uuid.map((item, index) => (
                      <input
                        className="form-control mb-1"
                        onChange={(e) => {
                          const newUuid = [...uuid];
                          newUuid[index] = e.target.value;
                          setUuid(newUuid);
                        }}
                        placeholder={`UUID ${index + 1}`}
                        value={item}
                      />
                    ))}
                  </div>
                  <h5
                    className="text-dark mt-0 mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => tambahUUID()}
                  >
                    + Tambah UUID
                  </h5>
                  <div className="d-flex align-items-end">
                    {pinShowed && (
                      <Input
                        label="OTP"
                        onChange={handleChange("otp")}
                        placeholder="contoh 083845"
                        value={form?.otp}
                      />
                    )}
                    <Button
                      title={`Kirim OTP (${changeSecondToDateFormatHms(
                        difference
                      )})`}
                      type="info"
                      onClick={() => sendOtp()}
                      block={false}
                      disabled={difference < 120}
                      style={{ marginLeft: "1rem" }}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Save"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ChangeUUID);
