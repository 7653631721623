/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
// import { _fetch } from "../../redux/actions/global";
// import { BridgeService } from "../../services";
import axios from "axios";
import { BtoA, formatDate } from "../../helpers";
function ListSSL({ history, location }) {
  //   const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    clientid: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [editDomain, setEditDomain] = useState([]);

  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");

  useEffect(() => {
    getSetting();
  }, []);

  const updateCategory = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      id: form?.id,
      clientid: form?.clientid,
      domain: editDomain,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "ssl/update",
        value: JSON.stringify(payload),
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(resultUrl?.data?.msg);
      getSetting();
      setIsEdit(false);
      cancelEdit();
    } else {
      toast.error(resultUrl?.data?.msg);
    }
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      id: "",
      clientid: "",
    });
    setEditDomain([]);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    if (form.clientid == "") {
      return toast.error("Client tidak boleh kosong");
    }
    if (editDomain.length == 0) {
      return toast.error("Domain tidak boleh kosong");
    }
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      clientid: form?.clientid,
      domain: editDomain,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "ssl/create",
        value: JSON.stringify(payload),
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(resultUrl?.data?.msg);
      getSetting();
      setIsEdit(false);
      cancelEdit();
    } else {
      toast.error(resultUrl?.data?.msg);
    }
  };

  const deleteAction = async (id) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      id,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "ssl/delete",
        value: JSON.stringify(payload),
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(resultUrl?.data?.msg);
      getSetting();
      setIsEdit(false);
      cancelEdit();
    } else {
      toast.error(resultUrl?.data?.msg);
    }
  };

  const [ssls, setSsls] = useState([]);

  const getSetting = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "ssl",
        value: JSON.stringify({ clientid: username }),
      }
    );
    let ssls = resultUrl?.data?.lobj[0]?.ssl;
    setSsls(ssls);
    setOldItem(ssls);
  };

  const addDomain = () => {
    setEditDomain([...editDomain, { name: "", expLeftinDays: "" }]);
  };

  console.log("editDomain", editDomain);
  console.log("ssl", ssls);

  const countExpired = (date) => {
    const today = new Date();
    const expirationDate = new Date(date);

    const timeDifference = expirationDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.clientid?.toLowerCase().includes(query.toLowerCase())
            );
            setSsls(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="list-ssl" />
          <Content>
            <PageHeading title="List SSL" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Client"
                    onChange={handleChange("clientid")}
                    placeholder=""
                    value={form?.clientid}
                    readonly={true}
                  />
                  {editDomain?.map((item, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <Input
                          label={"Domain #" + (index + 1)}
                          onChange={(e) => {
                            let newDomains = [...editDomain];
                            newDomains[index].name = e.target.value;
                            setEditDomain(newDomains);
                          }}
                          placeholder="contoh oke.com"
                          value={item?.name}
                          containerStyle={{ width: "100%" }}
                        />
                        <div style={{ width: 20 }} />
                        <Input
                          label="Tanggal Expired"
                          onChange={(e) => {
                            let newDomains = [...editDomain];
                            newDomains[index].expDays = e.target.value;
                            newDomains[index].expLeftinDays = countExpired(
                              e.target.value
                            );
                            setEditDomain(newDomains);
                          }}
                          type="date"
                          value={item?.expDays}
                          containerStyle={{ width: "100%" }}
                        />
                        <div style={{ width: 20 }} />
                        <Input
                          readOnly
                          label="Expired (hari)"
                          // onChange={(e) => {
                          //   let newDomains = [...editDomain];
                          //   newDomains[index].expLeftinDays = e.target.value;
                          //   setEditDomain(newDomains);
                          // }}
                          // placeholder="contoh 30"
                          value={item?.expLeftinDays}
                          containerStyle={{ width: "100%" }}
                        />
                      </div>
                    );
                  })}
                  <h5
                    className="text-dark mt-0 mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => addDomain()}
                  >
                    + Tambah domain
                  </h5>

                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Client </th>
                        <th className=""> Domain </th>
                        <th className=""> Expired </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ssls?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.clientid}</td>
                            <td className="">
                              {item?.domain?.map((it, idx) => {
                                return <div key={idx}>{it?.name}</div>;
                              })}
                            </td>
                            <td className="">
                              {item?.domain?.map((it, idx) => {
                                return (
                                  <div key={idx}>
                                    {formatDate(
                                      it?.expDays,
                                      "date monthName year"
                                    )}{" "}
                                    ({it?.expLeftinDays} hari)
                                  </div>
                                );
                              })}
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    id: item.id,
                                    clientid: item.clientid,
                                  });
                                  setEditDomain(item?.domain);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ListSSL);
