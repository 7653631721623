import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  AutoComplete,
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_nomsg } from "../../redux/actions/global";
import {
  BridgeService,
  GlobalService,
  InformationService,
} from "../../services";

function InformasiCreate({ history }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    judul: "",
    isi: "",
    image: "",
  });
  const [result, setResult] = useState("");

  const handleChange = (type) => (val) => {
    setForm({ ...form, [type]: val.target.value });
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, image: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(_fetch(GlobalService.deleteFoto(form?.image)));
    if (!res?.success) return;
    setForm({ ...form, image: "" });
  };

  const createAction = async () => {
    if (form.judul.length === 0) return toast.error("Masukkan Judul");
    if (form.isi.length === 0) return toast.error("Masukkan Isi");
    let payloadInfo;
    if (selectedSlider) {
      const to =
        // eslint-disable-next-line eqeqeq
        selectedSlider?.action == "link"
          ? selectedSlider?.to_link
          : menuEdit
          ? `${menuEdit?.to_menu}~${menuEdit?.name_menu}~${menuEdit?.nextpage_menu}`
          : `${selectedSlider.to_menu}~${selectedSlider.name_menu}~${selectedSlider.nextpage_menu}`;
      payloadInfo = {
        judul: form.judul,
        image: form.image,
        isi: form.isi,
        link: `${selectedSlider?.action}#${to}`,
      };
    } else {
      payloadInfo = {
        judul: form.judul,
        image: form.image,
        isi: form.isi,
        link: null,
      };
    }
    const res = await dispatch(
      _fetch(
        InformationService.createInformasi({
          payload: JSON.stringify(payloadInfo),
        })
      )
    );
    console.log("respon payload informasi", payloadInfo);
    console.log("respon buat informasi", res);
    setForm({ judul: "", isi: "", image: "" });
    setResult("Berhasil membuat informasi");
    setSelectedSlider(null);
    setMenuEdit(null);
    history.goBack();
  };

  const [selectedSlider, setSelectedSlider] = useState(null);
  const [menuEdit, setMenuEdit] = useState(null);
  const [modal, setModal] = useState(false);
  const [clickQuery, setClickQuery] = useState(false);
  const [resultMenu, setResultMenu] = useState([]);
  const [query, setQuery] = useState("");
  const handleChangeF = (type) => (val) => {
    setSelectedSlider({ ...selectedSlider, [type]: val });
  };
  useEffect(() => {
    getMenuSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const getMenuSearch = async () => {
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ nama: query }),
        }),
        false
      )
    );
    // console.log(res.data.lobj);
    setResultMenu(res?.data?.lobj);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="informasi" />
          <Content>
            <PageHeading title="Buat Informasi" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Judul"
                    onChange={handleChange("judul")}
                    placeholder="Masukkan Judul"
                    value={form.judul}
                  />
                  <Input
                    label="Isi"
                    onChange={handleChange("isi")}
                    placeholder="Masukkan Isi"
                    multiline
                    rows="30"
                    value={form.isi}
                  />
                  {form.image.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Gambar"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={form.image}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <label>Action</label>
                    <div
                      className="radio"
                      onClick={() => handleChangeF("action")("menu")}
                    >
                      <input
                        type="radio"
                        name="status"
                        id="menu"
                        value="menu"
                        checked={selectedSlider?.action === "menu"}
                        onChange={handleChangeF("action")}
                      />
                      <label htmlFor="optionsRadios1">Menu</label>
                    </div>
                    <div
                      className="radio"
                      onClick={() => handleChangeF("action")("link")}
                    >
                      <input
                        type="radio"
                        name="status"
                        id="link"
                        value="link"
                        checked={selectedSlider?.action === "link"}
                        onChange={handleChangeF("action")}
                      />
                      <label htmlFor="optionsRadios2">Link</label>
                    </div>
                  </div>
                  {selectedSlider?.action === "link" && (
                    <Input
                      label="Link"
                      onChange={(val) =>
                        handleChangeF("to_link")(val.target.value)
                      }
                      value={selectedSlider.to_link}
                    />
                  )}
                  {selectedSlider?.action === "menu" && (
                    <>
                      <h5
                        className="text-dark mt-0 mb-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => setModal(true)}
                      >
                        Edit menu
                      </h5>
                      <h5 className="text-dark mt-0 mb-3">
                        Menu Baru :{menuEdit?.name_menu}
                      </h5>
                    </>
                  )}

                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title="Submit"
                      type="warning"
                      onClick={() => createAction()}
                    />
                    <Button
                      title="Kembali"
                      type="danger"
                      onClick={() => history.goBack()}
                      style={{ width: 130, marginLeft: 10 }}
                    />
                  </div>
                  {result.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result}</div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <AutoComplete
            suggestions={resultMenu}
            onChangeText={(val) => setQuery(val)}
            onClickText={(val) => setClickQuery(val)}
          />
          <div className="d-flex justify-content-center align-items-end mt-4">
            <Button
              title="Simpan"
              type="warning"
              onClick={() => {
                console.log("clickquery", clickQuery);
                setMenuEdit({
                  to_menu: clickQuery?.id,
                  name_menu: clickQuery?.nama,
                  nextpage_menu: clickQuery?.nextpage,
                });
                setModal(false);
              }}
            />
            <Button
              title="Batal"
              type="danger"
              onClick={() => setModal(false)}
              style={{ width: 130, marginLeft: 10 }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(InformasiCreate);
