/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";

function MasterDeeplink() {
  const [clients, setClients] = useState([]);

  const getCLient = async () => {
    const res = await axios.get(
      "https://superapp.berkah-ts.my.id/deeplink/api/client/all"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      setClients(data?.data);
    }
  };

  useEffect(() => {
    getCLient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAction = async (id) => {
    const res = await axios.post(
      `https://superapp.berkah-ts.my.id/deeplink/api/client/delete?id=${id}`
    );

    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      toast.success(data?.message);
      getCLient();
    }
  };

  const [edit, setEdit] = useState(null);

  const [form, setForm] = useState({
    name: "",
    package_name: "",
  });

  const [isEdit, setIsEdit] = useState(false);

  const handleChange = (name) => (e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const saveAction = async () => {
    if (isEdit) {
      const res = await axios.post(
        `https://superapp.berkah-ts.my.id/deeplink/api/client/update/index.php`,
        { ...form, id: edit?.id }
      );

      const data = res?.data;
      if (!data.success) {
        return toast.error(data?.message);
      } else {
        toast.success(data?.message);
        getCLient();
        setForm({
          name: "",
          package_name: "",
        });
        setIsEdit(false);
      }
    } else {
      const res = await axios.post(
        `https://superapp.berkah-ts.my.id/deeplink/api/client/create/index.php`,
        form
      );

      const data = res?.data;
      if (!data.success) {
        return toast.error(data?.message);
      } else {
        toast.success(data?.message);
        getCLient();
        setForm({
          name: "",
          package_name: "",
        });
      }
    }
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      package_name: "",
    });
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="master-deeplink" />
          <Content>
            <PageHeading title="Master Deeplink" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Client"
                    onChange={handleChange("name")}
                    placeholder="Misal juber"
                    value={form?.name}
                  />
                  <Input
                    label="Package"
                    onChange={handleChange("package_name")}
                    placeholder="Misal id.juber.com"
                    value={form?.package_name}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> # </th>
                        <th className=""> Nama </th>
                        <th className=""> Package Name </th>
                        <th className="center"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">{item.name}</td>
                            <td className="">{item.package_name}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setEdit(item);
                                  setIsEdit(true);
                                  setForm({
                                    name: item?.name,
                                    package_name: item?.package_name,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default MasterDeeplink;
