/* eslint-disable eqeqeq */
import React from "react";
import { useSelector } from "react-redux";
import Footer from "../Footer";

function Wrapper({ children }) {
  const toggled = useSelector((state) => state.menuState.menuOpen);
  // const state_mode = localStorage.getItem("state");

  return (
    <>
      <div
        className={
          toggled
            ? "page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-indigo indigo-sidebar-color logo-indigo sidemenu-closed"
            : "page-header-fixed sidemenu-closed-hidelogo page-content-white page-md header-indigo indigo-sidebar-color logo-indigo"
        }
      >
        <div className="page-wrapper">{children}</div>
      </div>
      <Footer />
    </>
  );
}

export default Wrapper;
