import React from "react";

function Footer() {
  return (
    <div className="page-footer">
      <div className="page-footer-inner">
        {new Date().getFullYear()} &copy; SuperApps Admin by BTS Versi 1.0.40
      </div>
      <div className="scroll-to-top">
        <i className="material-icons">eject</i>
      </div>
    </div>
  );
}

export default Footer;
