/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  AutoComplete,
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function BlacklistMenu({ history, location }) {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kodeGroup: "",
    menus: "",
    menusAsli: [],
    allowed: true,
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "groupblock",
          method: "get",
        })
      )
    );
    console.log(res);
    const temp = [];
    let data = res?.data?.data;
    //loop the data then get the menus then push to menuParse
    data?.map((val) => {
      const menus = val?.menus?.split("#");
      menus?.map((val) => {
        temp.push({
          id: val,
        });
      });
    });

    console.log("temp", temp);
    //join the id with #
    const menuParse = temp?.map((val) => val?.id)?.join("#");
    const menuBaru = await getFullmenu(menuParse);
    //loop the data then get nama then join with #
    //match the menuBaru with id in temp then append the nama
    data?.map((val) => {
      const menus = val?.menus?.split("#");
      const nama = menuBaru?.filter((val) => menus?.includes(val?.id));
      val.menuNama = nama?.map((val) => val?.nama)?.join("#");
    });
    setResult(data);
  };

  console.log("menuparse", result);

  const updateCategory = async () => {
    const newResult = result;
    //edit by index
    newResult[index] = {
      kodeGroup: form?.kodeGroup,
      menus: form?.menus,
      allowed: form?.allowed,
    };
    const payload = {
      value: {
        data: newResult,
      },
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "groupblock/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      kodeGroup: "",
      menusAsli: [],
      menus: "",
      allowed: true,
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      kodeGroup: "",
      menusAsli: [],
      menus: "",
      allowed: true,
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const newResult = result;
    //append the form to result
    newResult.push({
      kodeGroup: form?.kodeGroup,
      menus: form?.menus,
      allowed: form?.allowed,
    });
    const payload = {
      value: {
        data: newResult,
      },
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "groupblock/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      kodeGroup: "",
      menusAsli: [],
      menus: "",
      allowed: true,
    });
  };

  const deleteAction = async (index) => {
    const payload = {
      value: {
        data: result.filter((val, i) => i != index),
      },
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "groupblock/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [clickQuery, setClickQuery] = useState("");
  const [resultMenu, setResultMenu] = useState([]);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    getMenuSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const addMenu = () => {
    let newMenu = form?.menusAsli;
    //append to array form.menus
    if (newMenu.length == 0) {
      //push array {} to [{}]
      newMenu.push(clickQuery);
    } else {
      //push to array []
      newMenu = newMenu.concat(clickQuery);
    }

    //get id
    let arrMenu = newMenu.map((val) => val.id);
    //join
    arrMenu = arrMenu.join("#");

    //set form.menus
    setForm({
      ...form,
      menusAsli: newMenu,
      menus: arrMenu,
    });
    setModal(false);
  };

  const getFullmenu = async (id) => {
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ id }),
        }),
        false
      )
    );

    //remove duplicate by id
    const unique = res?.data?.lobj?.filter(
      (val, index, self) => index === self.findIndex((t) => t.id === val.id)
    );

    return unique;
  };

  const getMenuSearch = async () => {
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ nama: query }),
        }),
        false
      )
    );
    // console.log(res.data.lobj);
    setResultMenu(res?.data?.lobj);
  };

  const deleteSubmenu = (item) => {
    let newMenu = form?.menusAsli;
    //remove from array
    newMenu = newMenu.filter((val) => val.id != item.id);

    //get id
    let arrMenu = newMenu.map((val) => val.id);
    //join
    arrMenu = arrMenu.join("#");
    setForm({
      ...form,
      menusAsli: newMenu,
      menus: arrMenu,
    });
  };

  console.log(form);

  const renderChips = (item) => {
    return item.length == 0 ? (
      <span className="mdl-chip mdl-chip--deletable mr-2">
        <span className="mdl-chip__text">Belum ada submenu</span>
        <button type="button" className="mdl-chip__action">
          <i className="material-icons">cancel</i>
        </button>
      </span>
    ) : (
      <span className="mdl-chip mdl-chip--deletable mr-2" key={item.id}>
        <span className="mdl-chip__text">{item.nama}</span>
        <button
          type="button"
          className="mdl-chip__action"
          onClick={() => deleteSubmenu(item)}
        >
          <i className="material-icons">cancel</i>
        </button>
      </span>
    );
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="blacklist-menu" />
          <Content>
            <PageHeading title="Whitelist/Blacklist Menu Group" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Kode Group"
                    onChange={handleChange("kodeGroup")}
                    placeholder="contoh A#B#C"
                    value={form?.kodeGroup}
                    readonly={true}
                  />
                  {form?.menusAsli?.map((val) => {
                    return renderChips(val);
                  })}
                  <h5
                    className="text-dark mt-0 mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => setModal(true)}
                  >
                    + Tambah menu
                  </h5>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="va"
                      value="option1"
                      checked={form.allowed}
                      onChange={() => {
                        setForm({
                          ...form,
                          allowed: !form.allowed,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="va">
                      Allowed
                    </label>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Kode Group </th>
                        <th className=""> Menu </th>
                        <th className=""> Allowed </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">
                              {item?.kodeGroup?.split("#")?.join(",")}
                            </td>
                            <td className="">
                              {item?.menuNama?.split("#")?.join(",")}
                            </td>
                            <td className="">
                              <i
                                className={`fa fa-${
                                  item?.allowed ? "check" : "times"
                                }`}
                              ></i>
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={async () => {
                                  setIsEdit(true);
                                  setForm({
                                    kodeGroup: item?.kodeGroup,
                                    menus: item?.menus,
                                    menusAsli: await getFullmenu(item?.menus),
                                    allowed: item?.allowed,
                                  });
                                  setIndex(index);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(index);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <AutoComplete
            suggestions={resultMenu}
            onChangeText={(val) => setQuery(val)}
            onClickText={(val) => setClickQuery(val)}
          />
          <div className="d-flex justify-content-center align-items-end mt-4">
            <Button
              title="Tambahkan"
              type="warning"
              onClick={() => addMenu()}
            />
            <Button
              title="Batal"
              type="danger"
              onClick={() => setModal(false)}
              style={{ width: 130, marginLeft: 10 }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(BlacklistMenu);
