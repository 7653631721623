/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  AutoCompleteClient,
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { AtoB, BtoA } from "../../helpers";
// import { _fetch } from "../../redux/actions/global";
// import { TransactionService } from "../../services";
import "moment/locale/id";

function ManageNextpage({ history, location }) {
  // const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [isadmin, setIsadmin] = useState(false);
  const [username, setUsername] = useState("");
  const [form, setForm] = useState({
    value: "",
  });
  const [formMutasi, setFormMutasi] = useState({
    clientid: "",
  });
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [resultSources, setResultSources] = useState([]);
  // const [type, setType] = useState(0);

  useEffect(() => {
    let atob = AtoB("kocak123");
    let key = atob("isadmin");
    let isadmin = localStorage.getItem(key);
    let valueIya = atob("IYA ADMIN");
    // let valueTidak = atob("TIDAK ADMIN");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    setUsername(username);
    if (isadmin == valueIya) {
      console.log("IYA");
      setIsadmin(true);
    } else {
      setIsadmin(false);
      setFormMutasi({ ...formMutasi, clientid: username });
      getMutasi();
    }
  }, []);

  console.log(username);
  // useEffect(() => {
  //   getMutasi();
  // }, [formMutasi.tglStart, formMutasi.tglEnd]);

  const getMutasi = async () => {
    if (formMutasi?.clientid == "" && isadmin) {
      toast.error("Client ID tidak boleh kosong");
      return;
    }
    const tglStart = moment(formMutasi.tglStart).format("YYYYMMDD");
    const tglEnd = moment(formMutasi.tglEnd).format("YYYYMMDD");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    axios
      .get(
        `https://api.setting.berkah-ts.my.id/juber/auth/mutasi/${formMutasi.clientid}?access_token=${token}&tglStart=${tglStart}&tglEnd=${tglEnd}`
      )
      .then((resultUrl) => {
        try {
          if (resultUrl?.data?.code == 200) {
            toast.success("Sukses fetch mutasi");
            setResult(resultUrl.data.lobj);
          } else {
            toast.error(resultUrl.data.msg);
          }
        } catch (error) {
          toast.error(error.message);
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        toast.error(error.response.data.msg);
      });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  // const handleChangeMutasi = (name) => (e) => {
  //   setFormMutasi({
  //     ...formMutasi,
  //     [name]: e.target.value,
  //   });
  // };

  const [clients, setClients] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getCLient();
  }, []);

  const getCLient = async (file) => {
    setLoading(true);
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
    setLoading(false);
  };

  const [asli, setAsli] = useState([]);
  const [clientQuery, setClientQuery] = useState(null);

  console.log(clientQuery, query);

  useEffect(() => {
    setFormMutasi({
      ...formMutasi,
      clientid: clientQuery?.username,
    });
  }, [clientQuery]);

  useEffect(() => {
    if (formMutasi?.clientid == "") return;
    getSetting(formMutasi?.clientid);
  }, [formMutasi?.clientid]);

  const getSetting = async (client) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "systemmenu",
        value: JSON.stringify({ clientId: client }),
      }
    );
    let resultMextPage = resultUrl?.data?.lobj[0]?.nextpage;

    //split nextpage with delimiter -
    resultMextPage = resultMextPage?.split("-");

    setResult(resultMextPage);
    setAsli(resultMextPage);
  };

  const saveEditACtion = async (payload) => {
    getSetting(formMutasi?.clientid);
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "systemmenu/update",
        value: JSON.stringify({
          clientId: formMutasi.clientid,
          value: { nextpage: payload },
        }),
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(resultUrl.data.msg);
    } else {
      toast.error(resultUrl.data.msg);
    }

    getSetting(formMutasi?.clientid);
  };

  const [isEdit, setIsEdit] = useState(false);

  const [itemEdit, setItemEdit] = useState({
    index: "",
    value: "",
  });

  const editAction = () => {
    //find index of result by itemEdit.index
    let indexResult = result.indexOf(itemEdit.value);

    //replace result with form.value
    result[indexResult] = form.value;

    //join with delimiter -
    const resFinal = result.join("-");

    console.log(resFinal);
    console.table(result);

    saveEditACtion(resFinal);
  };

  const deleteAction = (index) => {
    let resAsli = [...asli];

    //find index of result
    let indexResult = resAsli.indexOf(result[index]);

    //remove result
    resAsli.splice(indexResult, 1);

    //join with delimiter -
    const resFinal = resAsli.join("-");

    saveEditACtion(resFinal);
  };

  const [copyForm, setCopyForm] = useState({
    clientAsal: "",
    clientTujuan: "",
  });

  const copyAction = async () => {
    console.log(selectedItems);
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    let payload;

    if (selectedItems.length === 0) {
      payload = {
        existSetting: copyForm.clientAsal,
        newSetting: copyForm.clientTujuan,
      };
    } else {
      const items = selectedItems.join("#");
      payload = {
        existSetting: copyForm.clientAsal,
        newSetting: copyForm.clientTujuan,
        item: items,
      };
    }

    console.log(payload);
    // return;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "systemmenu/copy",
        value: JSON.stringify(payload),
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(resultUrl.data.msg);
    } else {
      toast.error(resultUrl.data.msg);
    }

    setModal(false);
  };

  useEffect(() => {
    getSettingAsal(copyForm?.clientAsal);
  }, [copyForm?.clientAsal]);

  console.log(selectedItems);

  const getSettingAsal = async (client) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "systemmenu",
        value: JSON.stringify({ clientId: client }),
      }
    );
    let resultMextPage = resultUrl?.data?.lobj[0]?.nextpage;

    //split nextpage with delimiter -
    resultMextPage = resultMextPage.split("-");

    setResultSources(resultMextPage);
    // setAsli(resultMextPage);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="manage-nextpage" />
          <Content>
            <PageHeading title="Manage Nextpage" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {/* <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={handleChangeMutasi("clientid")}
                      value={formMutasi?.clientid}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <AutoCompleteClient
                    suggestions={clients}
                    onChangeText={(val) => setQuery(val)}
                    onClickText={(val) => {
                      setClientQuery(val);
                    }}
                  />
                  <Input
                    label="Nama Nextpage"
                    onChange={handleChange("value")}
                    placeholder="Submenu, dll"
                    value={form?.value}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={isEdit ? "Simpan Perubahan" : "Tambah"}
                      type="warning"
                      disabled={loading}
                      onClick={async () => {
                        if (form?.value == "")
                          return toast.error(
                            "Nama Nextpage tidak boleh kosong"
                          );
                        if (formMutasi?.clientid == "" && isadmin)
                          return toast.error("Client ID tidak boleh kosong");
                        if (loading) return toast.error("Loading...");
                        if (isEdit) {
                          editAction();
                        } else {
                          //add to result
                          result.push(form.value);
                          //join with delimiter -
                          const resFinal = result.join("-");
                          saveEditACtion(resFinal);
                        }
                      }}
                    />
                  </div>
                </Card>

                <Card>
                  <h5>Copy Nextpage</h5>
                  <div className="form-group">
                    <label>Klien Asal</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setCopyForm({
                          ...copyForm,
                          clientAsal: e.target.value,
                        });
                      }}
                      value={copyForm?.clientAsal}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Klien Tujuan</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setCopyForm({
                          ...copyForm,
                          clientTujuan: e.target.value,
                        });
                      }}
                      value={copyForm?.clientTujuan}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Copy Nextpage"}
                      type={
                        !copyForm?.clientAsal || !copyForm?.clientTujuan
                          ? "disabled"
                          : "warning"
                      }
                      disabled={
                        !copyForm?.clientAsal || !copyForm?.clientTujuan
                      }
                      onClick={() => {
                        setModal(true);
                      }}
                    />
                  </div>
                </Card>

                <Card>
                  {/* <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={handleChangeMutasi("clientid")}
                      value={formMutasi?.clientid}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <AutoCompleteClient
                    suggestions={clients}
                    onChangeText={(val) => setQuery(val)}
                    onClickText={(val) => {
                      setClientQuery(val);
                    }}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Lihat Nextpage"}
                      type="warning"
                      onClick={() => getSetting(formMutasi?.clientid)}
                    />
                  </div>
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> Nama Nextpage </th>
                          <th className=""> Aksi </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          let date = moment(item.tgl);
                          date.locale("id");
                          date = date.format("dddd, DD MMMM YYYY, HH:mm");
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td className="">{item}</td>
                              <td className="center">
                                <a
                                  className="btn btn-tbl-edit-no-round btn-xs"
                                  onClick={() => {
                                    setIsEdit(true);
                                    setForm({
                                      ...form,
                                      value: item,
                                    });
                                    setItemEdit({
                                      index: index,
                                      value: item,
                                    });
                                  }}
                                >
                                  <i className="fa fa-pencil"></i> Edit
                                </a>
                                <a
                                  className="btn btn-tbl-delete btn-xs"
                                  onClick={() => {
                                    deleteAction(index);
                                  }}
                                >
                                  <i className="fa fa-trash-o "></i>
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          {/* <AutoComplete
            suggestions={resultMenu}
            onChangeText={(val) => setQuery(val)}
            onClickText={(val) => setClickQuery(val)}
          /> */}

          <div className="table-responsive">
            <table
              className="table table-hover table-checkable order-column full-width"
              id="example4"
            >
              <thead>
                <tr>
                  <th>Pilih Nextpage</th>
                </tr>
              </thead>
              <tbody>
                {resultSources?.map((item, index) => {
                  let date = moment(item.tgl);
                  date.locale("id");
                  date = date.format("dddd, DD MMMM YYYY, HH:mm");
                  return (
                    <tr className="odd gradeX" key={index}>
                      <td className="">
                        <input
                          type="checkbox"
                          id={item}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedItems([...selectedItems, item]);
                            } else {
                              let res = [...selectedItems];
                              res.splice(res.indexOf(item), 1);
                              setSelectedItems(res);
                            }
                          }}
                        />
                        <label className="form-check-label ml-2" htmlFor={item}>
                          {item}
                        </label>
                      </td>
                      {/* <td
                        className=""
                        onClick={() => {
                          if (selectedItems.includes(item)) {
                            let res = [...selectedItems];
                            res.splice(res.indexOf(item), 1);
                            setSelectedItems(res);
                          } else {
                            setSelectedItems([...selectedItems, item]);
                          }
                        }}
                      >
                        {item}
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-center align-items-end mt-4">
            <Button
              title="Tambahkan"
              type="warning"
              onClick={() => copyAction()}
            />
            <Button
              title="Batal"
              type="danger"
              onClick={() => setModal(false)}
              style={{ width: 130, marginLeft: 10 }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ManageNextpage);
