/* eslint-disable eqeqeq */
//eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { cipher } from "../../helpers";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

const enc = cipher("akuimuet");

function ListBlokirMarketplace() {
  // const history = useHistory();
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [resultAsli, setResultAsli] = useState(null);
  const [blockState, setBlockState] = useState({
    id: null,
    showModal: false,
    txtReason: null,
    item: null,
  });

  useEffect(() => {
    getStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStore = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "store",
          method: "get",
        })
      )
    );
    console.log(res.data.data);
    setResult(res?.data?.data);
    setResultAsli(res?.data?.data);
  };

  const blockAction = async () => {
    if (!blockState.txtReason || blockState.txtReason == "") {
      setBlockState({ ...blockState, showModal: true });
      return toast.error("Alasan harus diisi");
    }
    const payload = {
      id: blockState.id,
      block_reason: blockState.txtReason,
      blocked: blockState.item.blocked == 1 ? false : true,
    };
    console.log(payload);
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "admin/block/store",
          method: "put",
          payload: JSON.stringify(payload),
        })
      )
    );
    setBlockState({ showModal: false, txtReason: null, id: null });
    // console.log(res.data.lobj);
    getStore();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="blokir" />
          <Content>
            <PageHeading title="List Marketplace" />
            <div className="row">
              <div className="col-12">
                <Input
                  placeholder="Cari sesuatu..."
                  onChange={(e) =>
                    setResult(
                      resultAsli?.filter((it) =>
                        it.store_name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    )
                  }
                />
                <div className="row clearfix">
                  {result?.map((it, id) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-12 col-sm-6"
                        key={id}
                      >
                        <div className="card blogThumb">
                          <div
                            className="thumb-center"
                            style={{ backgroundColor: "lightgrey" }}
                          >
                            <img
                              className="img-responsive"
                              alt="user"
                              src={
                                it.picture ??
                                "https://www.hartlepoolwater.co.uk/globalassets/page-not-found.png"
                              }
                              style={{ height: "9rem", objectFit: "contain" }}
                            />
                          </div>
                          <div
                            className="vehicle-name bg-b-danger"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <div style={{ fontSize: 18 }}>{it.store_name}</div>
                          </div>
                          <div
                            className="vehicle-box"
                            style={{ textAlign: "justify", minHeight: 85 }}
                          >
                            {it.address}
                          </div>
                          <div className="center py-2">
                            <Link
                              className="btn btn-tbl-produk
                               btn-xs mr-2 btn-no-full-round"
                              to={{
                                pathname: `/list-products`,
                                search: `?id=${enc(
                                  JSON.stringify(it)
                                )}&type=market`,
                              }}
                            >
                              Produk
                              <i className="fa fa-shopping-bag"></i>
                            </Link>
                            <div
                              className={`btn ${
                                it.blocked == 1
                                  ? "btn-tbl-edit"
                                  : "btn-tbl-delete"
                              } btn-xs mr-2 btn-no-full-round`}
                              onClick={() =>
                                setBlockState({
                                  ...blockState,
                                  id: it.id,
                                  showModal: true,
                                  item: it,
                                })
                              }
                            >
                              {it.blocked == 1 ? "Unblokir" : "Blokir"}
                              <i
                                className={
                                  it.blocked == 1 ? "fa fa-check" : "fa fa-ban"
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
          <Modal
            show={blockState.showModal}
            onHide={() => setBlockState({ ...blockState, showModal: false })}
          >
            <Input
              label="Alasan"
              onChange={(val) =>
                setBlockState({ ...blockState, txtReason: val.target.value })
              }
              placeholder="Misal toko palsu"
              value={blockState.txtReason}
            />
            <Button
              title={blockState?.item?.blocked == 1 ? "Unblokir" : "Blokir"}
              type={blockState?.item?.blocked == 1 ? "warning" : "danger"}
              onClick={() => blockAction()}
            />
          </Modal>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListBlokirMarketplace;
