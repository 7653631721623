/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
// import axios from "axios";
// import moment from "moment";
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA, changeSecondToDateFormatHms } from "../../helpers";
import { _fetch_noerror, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import "moment/locale/id";
import { useDispatch } from "react-redux";
import axios from "axios";

function RefundAdmin({ history, location }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kodepesanan: "",
    alasan: "",
    pin: "",
  });

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    getCLient();
    // getData();
  }, []);

  const getCLient = async (file) => {
    try {
      const userSave = localStorage.getItem("credential");
      const btoa = BtoA("kocak123");
      const username = btoa(userSave);
      const password = "password";
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: username,
          password: password,
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      // console.log(result.data);

      const token = result.data.access_token;

      const resClient = await axios.post(
        `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
        {
          desc: "baseurl",
          value: JSON.stringify({
            clientid: "",
          }),
        }
      );

      // console.log(resClient?.data?.lobj);

      setClients(resClient?.data?.lobj[0]);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const forceTopUp = async () => {
    const payload = {
      kodepesanan: form.kodepesanan,
      alasan: form.alasan,
      pin: "123456",
    };
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbMarketCustom(selectedClient, {
          method: "post",
          key: "refund",
          payload: JSON.stringify(payload),
        })
      )
    );
    console.log(res);
    toast.info(res?.data?.msg);
    const time = new Date().getTime();
    localStorage.setItem("time_pin", time);
    setPinShowed(true);
  };

  const topupAction = async () => {
    const payload = {
      kodepesanan: form.kodepesanan,
      alasan: form.alasan,
      pin: form.pin,
    };
    const res = await dispatch(
      _fetch_noerror(
        BridgeService.JbMarketCustom(selectedClient, {
          method: "post",
          key: "refund",
          payload: JSON.stringify(payload),
        })
      )
    );
    console.log(res);
    if (res?.data?.success) {
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    setForm({
      kodepesanan: "",
      alasan: "",
      pin: "",
    });
    localStorage.removeItem("time_pin");
    setPinShowed(false);
    setDifference(120);
  };

  const getDifferenceTimeInHms = (time) => {
    const timeNow = new Date().getTime();
    const diff = timeNow - time;
    const diffInMinutes = diff / 1000;
    return diffInMinutes;
  };

  const [difference, setDifference] = useState(120);

  const [pinShowed, setPinShowed] = useState(localStorage.getItem("time_pin"));

  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("time_pin")) return;
      const between = getDifferenceTimeInHms(localStorage.getItem("time_pin"));
      //hitung mundur
      setDifference(120 - between);
      //if 2 minutes passed, remove the pin
      if (between > 120) {
        setPinShowed(false);
        localStorage.removeItem("time_pin");
        setDifference(120);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="cancel-thirdparty" />
          <Content>
            <PageHeading title="Cancel Thirdparty" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.value}>
                            {item?.idapps?.split(".")[2]}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {selectedClient && (
                    <>
                      <Input
                        label="Kode Pesanan"
                        onChange={handleChange("kodepesanan")}
                        placeholder="Kode Pesanan"
                        value={form?.kodepesanan}
                      />
                      <Input
                        label="Alasan"
                        onChange={handleChange("alasan")}
                        placeholder="contoh : pesanan tidak sesuai"
                        value={form?.alasan}
                        multiline
                      />
                      <div className="d-flex align-items-end">
                        {pinShowed && (
                          <Input
                            label="OTP"
                            onChange={handleChange("pin")}
                            placeholder="contoh 083845"
                            value={form?.pin}
                          />
                        )}
                        <Button
                          title={`Kirim OTP (${changeSecondToDateFormatHms(
                            difference
                          )})`}
                          type="info"
                          onClick={() => forceTopUp()}
                          block={false}
                          disabled={difference < 120}
                          style={{ marginLeft: !pinShowed ? 0 : "1rem" }}
                        />
                      </div>
                    </>
                  )}
                  {pinShowed && (
                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Refund"}
                        type="warning"
                        onClick={() => topupAction()}
                      />
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(RefundAdmin);
