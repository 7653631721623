/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import {
  Container,
  Content,
  ItemHome,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { Link } from "react-router-dom";
import items from "../../constants/menu.json";
import { ToastContainer } from "react-toastify";
import { AtoB } from "../../helpers";
import { excludedMenu } from "../../config";

function Dashboard() {
  let isadmin = localStorage.getItem(String("342e3c39303433"));
  let atob = AtoB("kocak123");
  let iya = atob("IYA ADMIN");
  const [query, setQuery] = React.useState("");

  isadmin = iya === isadmin ? true : false;

  const excluded = excludedMenu;

  console.log(excluded);

  const [newItem, setNewItem] = React.useState([]);
  const [oldItem, setOldItem] = React.useState([]);

  useEffect(() => {
    // let newItem = items;
    if (isadmin) {
      setOldItem(items);
      setNewItem(items);
    } else {
      setNewItem(items.filter((item) => !excluded.includes(item.active)));
      setOldItem(items.filter((item) => !excluded.includes(item.active)));
    }
  }, []);

  console.log(newItem);
  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item.name.toLowerCase().includes(query.toLowerCase())
            );
            setNewItem(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="dashboard" />
          <Content>
            <PageHeading title="Dashboard" />
            <div className="row clearfix">
              {newItem.map((item, index) => {
                return (
                  <Link
                    key={index}
                    className="col-xl-2 col-lg-3 col-md-6 col-sm-12 text-center text-dark"
                    to={item.to}
                  >
                    <ItemHome title={item.name} icon={item.icon} />
                  </Link>
                );
              })}
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default Dashboard;
