/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
function MasterKategori09({ history, location }) {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    nama: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "masterkategori",
          method: "get",
        })
      )
    );
    console.log(res);
    setResult(res?.data?.data?.data || []);
  };

  const updateCategory = async () => {
    const payload = {
      id: form?.id,
      nama: form?.nama,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "put",
          key: "masterkategori/updatenew",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      id: "",
      nama: "",
    });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      nama: form?.nama,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "masterkategori/create",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  const deleteAction = async (id) => {
    const payload = {
      id,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "delete",
          key: "masterkategori/delete",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="master-kategori-09" />
          <Content>
            <PageHeading title="Master Kategori FE09" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Kategori"
                    onChange={handleChange("nama")}
                    placeholder="contoh ewallet"
                    value={form?.nama}
                    readonly={true}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateCategory() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama Kategori </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.nama}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    id: item.id,
                                    nama: item.nama,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(MasterKategori09);
