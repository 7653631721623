/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function ListBankTopupVA() {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    nama: "",
    norek: "",
    logo: "",
    owner: "",
    instruksi: "",
    index: "",
    kodebank: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [asli, setAsli] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbMarket({ key: "listbankva", method: "get" }))
    );
    const result = res?.data?.data;
    const banks = result.banks;
    console.log(result);
    setResult(banks || []);
    setAsli(result);
  };

  const updateAction = async () => {
    const selected = selectedData;
    // change value by findItem
    const newResult = result.map((item, index) => {
      if (selected?.index == index) {
        return form;
      } else {
        return item;
      }
    });
    saveAction(newResult);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      nama: "",
      norek: "",
      logo: "",
      owner: "",
      instruksi: "1.2.3",
      index: "",
      kodebank: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async (pload, type) => {
    const method = "post";
    const jsonAsli = asli;
    const bankAsli = jsonAsli.banks || [];
    let payload;
    let key;
    if (pload) {
      console.log("edit, hapus");
      let banks = [];
      if (type == "delete") {
        banks = pload;
      } else {
        banks = bankAsli.map((item, index) => {
          if (item?.index == pload[index]?.index) {
            return pload[index];
          } else {
            return item;
          }
        });
      }

      console.log("hasil cuk", banks);
      console.log("pload cuk", pload);
      payload = {
        value: {
          mindeposit: jsonAsli.mindeposit,
          banks,
        },
      };
      key = "listbankva/update";
    } else {
      console.log("tambah");
      //find largest index in bankAsli
      const index = bankAsli?.reduce(
        (prev, current) => (prev.index > current.index ? prev : current),
        {}
      );

      console.log(index.index);
      // return;
      payload = {
        value: {
          mindeposit: jsonAsli.mindeposit,
          banks: [...bankAsli, { ...form, index: index?.index + 1 }],
        },
      };
      key = "listbankva/update";
    }
    console.log(payload);

    // payload = {
    //   value: {
    //     mindeposit: 10000,
    //     banks: [
    //       {
    //         nama: "VA Permata",
    //         norek: "7137487989743867",
    //         logo: "https://images-repo.berkah-ts.my.id/images/yx07y3GQjADrO9G6VkqNMg1ZMhWBQ8m28YKocfcw.png",
    //         owner: "PT. Berkah Tekno Solusi",
    //         instruksi: "1. xxxxx  2. yyyy",
    //         index: 2,
    //         kodebank: "013",
    //       },
    //       {
    //         nama: "Test doang",
    //         norek: "7137081335241314",
    //         logo: "https://juber.co.id/storage/images/YvRbHIHsUf4YMrGKE48HGXPCLxyjvNZRNffvmxbu.png",
    //         owner: "Test Owner Edit",
    //         instruksi: "1. Topup 2. Disini 3.Dimari",
    //         index: 3,
    //         kodebank: "013",
    //       },
    //       {
    //         nama: "PT.BTS TEST",
    //         norek: "7137087843584104",
    //         logo: "https://juber.co.id/storage/images/WL1ymam5aAGxICaFE8xA2IFSOBcIXzhqlwiAlQnT.png",
    //         owner: "Test 2",
    //         instruksi: "1. asda 2. wdqwd",
    //         index: 4,
    //         kodebank: "013",
    //       },
    //     ],
    //   },
    // };

    // return;
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method,
          key,
          payload: JSON.stringify(payload),
        })
      )
    );
    clearForm();
    getData();
    setIsEdit(false);
  };

  const deleteAction = async (item, index) => {
    //remove index
    let tempData = null;
    result.forEach((item, i) => {
      console.log(i);
      if (i == index) {
        tempData = item;
        console.log("masuk sini");
      }
    });
    console.log(tempData, index);
    const newResult = result.filter((item) => item !== tempData);
    console.table("newres", newResult);
    saveAction(newResult, "delete");
    // saveAction(data);
  };

  const clearForm = () => {
    setForm({
      nama: "",
      norek: "",
      logo: "",
      owner: "",
      instruksi: "",
      index: "",
      kodebank: "",
    });
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch_noerror(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, logo: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(
      _fetch_noerror(GlobalService.deleteFoto(form?.image))
    );
    // if (!res?.success) return;
    console.log(res);
    setForm({ ...form, logo: "" });
  };

  const [banks, setBanks] = useState([]);

  const getBanks = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "virtualacc",
          method: "post",
          payload: JSON.stringify({ accnumber: "all", stsaktif: true }),
        })
      )
    );
    let result = res?.data?.data;
    result = result?.map((item) => {
      let bankcode = item?.value?.["bank code"];
      return {
        ...item,
        kodebank: bankcode ?? "",
      };
    });
    console.log(result);
    setBanks(result || []);
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="list-bank-va" />
          <Content>
            <PageHeading title="List Bank Topup VA" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Virtual Account</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        const val = JSON.parse(e.target.value);
                        setForm({
                          ...form,
                          nama: val?.nama,
                          norek: val?.norek,
                          kodebank: val?.kodebank,
                        });
                      }}
                    >
                      <option value="">Pilih Bank</option>
                      {banks?.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={JSON.stringify({
                              nama: item?.value?.nmva,
                              norek: item?.value?.accnumber,
                              kodebank: item?.kodebank ?? "",
                            })}
                          >
                            {item?.value?.bank} ({item?.value?.accnumber}) -{" "}
                            {item?.value?.nmva}
                          </option>
                        );
                      })}
                    </select>
                    Belum ada data di list VA?{" "}
                    <Link to="/va-mitra">Tambah VA</Link>
                  </div>
                  <Input
                    label="Owner"
                    onChange={handleChange("owner")}
                    placeholder="Owner"
                    value={form?.owner}
                  />
                  <Input
                    label="Instruksi"
                    onChange={handleChange("instruksi")}
                    placeholder="Instruksi"
                    value={form?.instruksi}
                    multiline
                  />

                  {form.logo.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Ubah Gambar"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={form.logo}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}

                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() =>
                        isEdit ? updateAction() : saveAction(null)
                      }
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""># </th>
                        <th className=""> Logo </th>
                        <th className=""> Nama Bank </th>
                        <th className=""> No Rek </th>
                        <th className=""> Owner </th>
                        <th className=""> Kode Bank </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">
                              <img
                                src={item.logo}
                                alt=""
                                style={{ width: 50, height: 50 }}
                              />
                            </td>
                            <td className="">{item.nama}</td>
                            <td className="">{item.norek}</td>
                            <td className="">{item.owner}</td>
                            <td className="">{item.kodebank}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    ...item,
                                  });
                                  setSelectedData({ ...item, index });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item, index);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ListBankTopupVA);
