import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { BtoA } from "../../helpers";
import axios from "axios";

function ListColor() {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [owner, setOwner] = useState(null);
  const [inputOwner, setInputOwner] = useState("");
  const [colorLogin, setColorLogin] = useState(null);
  const [colorLoginAsli, setColorLoginAsli] = useState(null);

  useEffect(() => {
    getSetting();
    getSettingLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettingLogin = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "getwarna",
        value: JSON.stringify({ clientid: username }),
      }
    );
    const r = resultUrl?.data?.lobj[0]?.colorlogin;
    setColorLogin(r);
    setColorLoginAsli(r);
  };

  const getSetting = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbDelivery({ key: "getcolormaster" }))
    );
    let newData = [];
    let result = res?.data?.lobj || [];
    result
      .filter((cont) => cont.idapps !== "color.owner")
      .map((item) => {
        return newData.push({ ...item, isEditing: false });
      });
    setResult(newData);
    setOwner(result.filter((cont) => cont.idapps === "color.owner")[0]);
    setInputOwner(
      result.filter((cont) => cont.idapps === "color.owner")[0].value
    );
  };

  const saveAction = async (payload) => {
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "setcolor",
          payload,
        })
      )
    );
    getSetting();
  };

  const editAction = async (item) => {
    const newArr = result.map((p) =>
      p.idapps === item.idapps ? { ...p, isEditing: !p.isEditing } : p
    );
    setResult(newArr);
    if (item.isEditing === true) {
      const payload = JSON.stringify({
        idapps: item.idapps,
        value: item.value,
        desc: item.desc,
      });
      saveAction(payload);
    }
  };

  const saveOwner = async (item) => {
    const payload = JSON.stringify({
      idapps: owner.idapps,
      value: inputOwner,
      desc: owner.desc,
    });
    saveAction(payload);
  };

  const handleChange = async (item, type, value) => {
    const newArr = result.map((p) =>
      p.idapps === item.idapps ? { ...p, [type]: value } : p
    );
    setResult(newArr);
  };

  const handleChangeOwner = async (value) => {
    setInputOwner(value);
  };

  const handleChangeWarnaLogin = async (value) => {
    setColorLogin(value);
  };

  console.log(colorLogin);

  const saveWarnaLogin = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const res = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "setwarna",
        value: JSON.stringify({ clientid: username, colorlogin: colorLogin }),
      }
    );

    console.log(res.data);

    if (res?.data?.code === "200") {
      toast.success("Berhasil mengubah warna login");
    } else {
      toast.error("Gagal mengubah warna login");
    }

    getSettingLogin();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="color" />
          <Content>
            <PageHeading title="Warna Login" noLeft />
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                <div
                  className="card"
                  style={{ backgroundColor: colorLoginAsli }}
                >
                  <div
                    className="card-body no-padding height-9 mt-1 p-2"
                    style={{ backgroundColor: colorLoginAsli }}
                  >
                    <ul className="list-group list-group-unbordered">
                      <li
                        className="list-group-item px-3"
                        style={{
                          borderTop: "0px none",
                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                        }}
                      >
                        <b>colorlogin</b>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-3">
                        <div className="pull-right">Warna Login</div>
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center px-3"
                        style={{
                          borderBottomLeftRadius: 3,
                          borderBottomRightRadius: 3,
                        }}
                      >
                        <b>Value</b>
                        <input
                          type="color"
                          className="form-control text-right"
                          value={colorLogin}
                          onChange={(e) =>
                            handleChangeWarnaLogin(e.target.value)
                          }
                          style={{ width: "50%" }}
                        />
                      </li>
                    </ul>
                    <div className="profile-userbuttons">
                      <button
                        type="button"
                        className={`mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger`}
                        data-upgraded=",MaterialButton,MaterialRipple"
                        onClick={() => saveWarnaLogin()}
                      >
                        Simpan
                        <span className="mdl-button__ripple-container">
                          <span className="mdl-ripple"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PageHeading title="Warna Aplikasi" />
            <div className="row">
              <div className="col-12">
                <div className="row clearfix">
                  {result?.map((it, id) => {
                    return (
                      <div
                        className="col-lg-3 col-md-6 col-12 col-sm-6"
                        key={id}
                      >
                        <div
                          className="card"
                          style={{ backgroundColor: it.value }}
                        >
                          <div
                            className="card-body no-padding height-9 mt-1 p-2"
                            style={{ backgroundColor: it.value }}
                          >
                            <ul className="list-group list-group-unbordered">
                              <li
                                className="list-group-item px-3"
                                style={{
                                  borderTop: "0px none",
                                  borderTopLeftRadius: "3px",
                                  borderTopRightRadius: "3px",
                                }}
                              >
                                <b>{it.idapps}</b>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center px-3">
                                <div className="pull-right">{it.desc}</div>
                              </li>
                              <li
                                className="list-group-item d-flex justify-content-between align-items-center px-3"
                                style={{
                                  borderBottomLeftRadius: 3,
                                  borderBottomRightRadius: 3,
                                }}
                              >
                                <b>Value</b>
                                {it.isEditing ? (
                                  <input
                                    type="color"
                                    className="form-control text-right"
                                    value={it.value}
                                    onChange={(e) =>
                                      handleChange(it, "value", e.target.value)
                                    }
                                    style={{ width: "50%" }}
                                  />
                                ) : (
                                  <div className="pull-right">{it.value}</div>
                                )}
                              </li>
                            </ul>
                            <div className="profile-userbuttons">
                              <button
                                type="button"
                                className={`mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle ${
                                  it.isEditing ? "btn-danger" : "btn-warning"
                                }`}
                                data-upgraded=",MaterialButton,MaterialRipple"
                                onClick={() => editAction(it)}
                              >
                                {it.isEditing ? "Simpan" : "Edit"}
                                <span className="mdl-button__ripple-container">
                                  <span className="mdl-ripple"></span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <PageHeading title="Nama Owner Aplikasi" noLeft />
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                <div className="card" style={{ backgroundColor: "red" }}>
                  <div
                    className="card-body no-padding height-9 mt-1 p-2"
                    style={{ backgroundColor: "red" }}
                  >
                    <ul className="list-group list-group-unbordered">
                      <li
                        className="list-group-item px-3"
                        style={{
                          borderTop: "0px none",
                          borderTopLeftRadius: "3px",
                          borderTopRightRadius: "3px",
                        }}
                      >
                        <b>{owner?.idapps}</b>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center px-3">
                        <div className="pull-right">{owner?.desc}</div>
                      </li>
                      <li
                        className="list-group-item d-flex justify-content-between align-items-center px-3"
                        style={{
                          borderBottomLeftRadius: 3,
                          borderBottomRightRadius: 3,
                        }}
                      >
                        <b>Value</b>
                        <input
                          type="text"
                          className="form-control text-right"
                          value={inputOwner}
                          onChange={(e) => handleChangeOwner(e.target.value)}
                          style={{ width: "50%" }}
                        />
                      </li>
                    </ul>
                    <div className="profile-userbuttons">
                      <button
                        type="button"
                        className={`mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect m-b-10 btn-circle btn-danger`}
                        data-upgraded=",MaterialButton,MaterialRipple"
                        onClick={() => saveOwner(owner)}
                      >
                        Simpan
                        <span className="mdl-button__ripple-container">
                          <span className="mdl-ripple"></span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListColor;
