/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clickMenuOpen } from "../../../redux/actions";
import { logout } from "../../../redux/actions/auth";
import items from "../../../constants/menu.json";
import { AtoB } from "../../../helpers";
import Input from "../../Input";
import { excludedMenu } from "../../../config";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParams: "",
    };
  }
  // componentDidMount() {
  //   document.getElementById('body').classNameName = 'page-top';
  // }
  // state = {
  //   sidebarToggled: false,
  // }

  // handleSideBarToggle() {
  //   if (this.sidebarToogled === true) {
  //     this.setState({ sidebarToggled: !this.state.sidebarToggled });
  //     document.getElementById('body').classNameName = 'page-top sidebar-toggled';
  //   } else {
  //     this.setState({ sidebarToggled: !this.state.sidebarToggled });
  //     document.getElementById('body').classNameName = 'page-top';
  //   }

  // }

  doLogout() {
    const { logout, history } = this.props;
    logout();
    history.push("/login");
  }

  render() {
    const { toggled, active, user } = this.props;
    let isadmin = localStorage.getItem(String("342e3c39303433"));
    let atob = AtoB("kocak123");
    let iya = atob("IYA ADMIN");

    isadmin = iya === isadmin ? true : false;

    const excluded = excludedMenu;

    console.log(excluded, isadmin);

    let newItem = items;
    if (isadmin) {
      newItem = items;
    } else {
      newItem = items.filter((item) => !excluded.includes(item.active));
      console.log(newItem);
    }

    return (
      <div className="sidebar-container">
        <div
          className={
            toggled
              ? "sidemenu-container navbar-collapse collapse fixed-menu sidemenu-closed"
              : "sidemenu-container navbar-collapse collapse fixed-menu"
          }
        >
          <div id="remove-scroll">
            <ul
              className="sidemenu page-header-fixed p-t-20"
              data-keep-expanded="false"
              data-auto-scroll="true"
              data-slide-speed="200"
            >
              <li className="sidebar-toggler-wrapper hide">
                <div className="sidebar-toggler">
                  <span></span>
                </div>
              </li>
              <li className="sidebar-user-panel">
                <div className="user-panel">
                  <div className="pull-left image">
                    <img
                      src="https://juber.co.id/storage/images/WwkwKadPauideSLTHEZtW76PPmyOMXmeKA39pHm1.png"
                      className="img-circle user-img-circle"
                      alt="User"
                    />
                  </div>
                  <div className="pull-left info">
                    <p className="text-white"> {user?.namars}</p>
                    <a title="Inbox" href="email_inbox.html">
                      <i className="material-icons">email</i>
                    </a>
                    <a title="Profile" href="user_profile.html">
                      <i className="material-icons">person</i>
                    </a>
                    <button title="Logout" onClick={() => this.doLogout()}>
                      <i className="material-icons">power_settings_new</i>
                    </button>
                  </div>
                </div>
              </li>
              <li className="menu-heading">
                <span>-- Main</span>
              </li>
              <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <Input
                  placeholder="Cari menu"
                  onChange={(e) =>
                    this.setState({ searchParams: e.target.value })
                  }
                />
              </div>
              {newItem
                // eslint-disable-next-line array-callback-return
                .filter((item) => {
                  if (this.state.searchParams === "") {
                    return item;
                  } else if (
                    item.name
                      .toLowerCase()
                      .includes(this.state.searchParams.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <li
                      className={
                        active === item.active ? "nav-item active" : "nav-item"
                      }
                      key={index}
                    >
                      <Link className="nav-link nav-toggle" to={item.to}>
                        <i className="material-icons">{item.icon}</i>
                        <span className="title">{item.name}</span>
                      </Link>
                    </li>
                  );
                })}
              {/* <li className="nav-item">
                <a href="#" className="nav-link nav-toggle">
                  <i className="material-icons">airport_shuttle</i>
                  <span className="title">Vehicle</span>
                  <span className="arrow"></span>
                </a>
                <ul className="sub-menu">
                  <li className="nav-item">
                    <a href="add_vehicle.html" className="nav-link ">
                      <span className="title">Add Vehicle Details</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="all_vehicles.html" className="nav-link ">
                      <span className="title">View All Vehicle</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="edit_vehicle.html" className="nav-link ">
                      <span className="title">Edit Vehicle Details</span>
                    </a>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clickMenuOpen, logout }, dispatch);

const mapStateToProps = (store) => ({
  toggled: store.menuState.menuOpen,
  user: store.auth.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
