/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";
function UbahIcon({ history, location }) {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    value: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [nextPage, setNextPage] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "systemmenu",
          method: "get",
        })
      )
    );

    const data = res?.data?.data;
    const nextpage = data?.nextpage;
    setNextPage(nextpage);
    //remove the nextpage from the data
    delete data?.nextpage;
    delete data?.lokasiStatus;
    //set the data with new mapping {name: the key, value: the value} with object
    const newData = Object.keys(data).map((key) => ({
      name: key,
      value: data[key],
    }));
    setResult(newData || []);
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setForm({ ...form, value: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(
      _fetch_noerror(GlobalService.deleteFoto(form?.image))
    );
    console.log(res);
    // if (!res?.success) return;
    setForm({ ...form, value: "" });
  };

  const updateIcon = async () => {
    const data = {
      name: form?.id,
      value: form?.value,
    };
    //find the index of the data on the result then replace it with the new data
    const index = result.findIndex((item) => item.name === data.name);
    result[index] = data;
    setResult([...result]);
    //set the payload
    const payload = {
      value: {
        nextpage: nextPage,
        ...result.reduce((obj, item) => {
          obj[item.name] = item.value;
          return obj;
        }, {}),
      },
    };
    console.log("pload", payload);
    console.log("res", result);
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "systemmenu/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
    setForm({
      id: "",
      value: "",
    });
    setIsEdit(false);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  // const deleteAction = async (id) => {
  //   const payload = {
  //     id,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "delete",
  //         key: "masterkategori/delete",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  // };

  console.log("form", form);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="ubah-icon" />
          <Content>
            <PageHeading title="Ubah Icon Topup, QR, Saldo APK" />
            <div className="row">
              <div className="col-12">
                {isEdit && (
                  <Card>
                    <Input
                      label="Nama Icon"
                      onChange={handleChange("id")}
                      value={form?.id}
                      readonly={true}
                      disabled={true}
                    />
                    {form?.value?.length === 0 ? (
                      <Input
                        accept="image/*"
                        label="Gambar"
                        type="file"
                        onChange={(e) => uploadImage(e?.target?.files[0])}
                      />
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "9.5rem",
                          padding: 10,
                          border: "2px solid #d2d6de",
                          backgroundColor: "rgba(210, 210, 210, 0.25)",
                          color: "#ffffff",
                        }}
                        className="mb-3 d-flex flex-column"
                      >
                        <img
                          src={form.value}
                          alt=""
                          className="img-thumbnail mb-2"
                          style={{
                            height: "8rem",
                            width: "8rem",
                            objectFit: "contain",
                          }}
                        />
                        <div
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            borderRadius: 99,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className="text-center d-flex justify-content-center align-items-center"
                          onClick={() => deleteImage()}
                        >
                          <i
                            className="material-icons"
                            style={{ color: "#E44F56", cursor: "pointer" }}
                          >
                            cancel
                          </i>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Simpan"}
                        type="warning"
                        onClick={() => updateIcon()}
                      />
                      {isEdit && (
                        <Button
                          title="Batal Edit"
                          type="danger"
                          onClick={() => cancelEdit()}
                          style={{ width: 130, marginLeft: 10 }}
                        />
                      )}
                    </div>
                  </Card>
                )}
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama </th>
                        <th className=""> Gambar </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.name}</td>
                            <td className="">
                              <img
                                src={item.value}
                                alt="icon"
                                width="50px"
                                height="50px"
                              />
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  //set form from key and value to object
                                  setForm({
                                    id: item.name,
                                    value: item.value,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              {/* <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(UbahIcon);
