/* eslint-disable eqeqeq */
import axios from "axios";
import { BtoA } from "../helpers";

const getUuid = localStorage.getItem("active_user");

export const baseUrl = localStorage.getItem("url");
export const baseUrlSocket = localStorage.getItem("urlSocket");
export const uuid = JSON.parse(getUuid)?.uuid;
export const baseRequest = axios.create({ baseURL: baseUrl });
export const uploadRequest = axios.create({ baseURL: "https://juber.co.id" });
export const socketHost = baseUrlSocket;
export const socketHosts = axios.create({
  baseURL: baseUrlSocket,
});

export const baseHostUrl = async () => {
  const userSave = localStorage.getItem("credential");
  const btoa = BtoA("kocak123");
  const username = btoa(userSave);
  const password = "password";
  const resultApi = await axios.post(
    "https://api.setting.berkah-ts.my.id/oauth/token",
    new URLSearchParams({
      username: username,
      password: password,
      grant_type: "password",
    }),
    {
      headers: {
        Authorization:
          "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
      },
    }
  );

  console.log(resultApi.data);

  const token = resultApi.data.access_token;

  const resultUrl = await axios.get(
    `https://api.setting.berkah-ts.my.id/juber/auth/webadmin/${username}?access_token=${token}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const parse = JSON.parse(resultUrl.data?.lobj[0]);

  return parse?.baseurl;
};

export const generateHeaders = async (types) => {
  try {
    let headers = {};

    if (types.includes("content-json")) {
      headers["Content-Type"] = "application/json";
    }
    if (types.includes("content-urlencoded")) {
      headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    if (types.includes("content-formdata")) {
      headers["Content-Type"] = "multipart/form-data";
    }
    if (types.includes("authorization")) {
      const persist = await localStorage.getItem("user");
      const auth = JSON.parse(persist);
      headers["Authorization"] = "Bearer " + auth.auth.token;
    }
    if (types.includes("irsauth")) {
      let currentIrsauth = localStorage.getItem("active_user");
      currentIrsauth = JSON.parse(currentIrsauth);

      headers["irsauth"] = currentIrsauth?.auth;
    }

    if (types.includes("irsauth_header")) {
      headers["Access-Control-Allow-Headers"] = "irsauth";
    }

    return { headers };
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
  }
};

export const excludedMenu = [
  "product-thirdparty",
  "paket-menu",
  "paket-menu-client",
  "log-transaksi",
  "monitoring-client",
  "master-switch",
  "list-client",
  "master-deeplink",
  "master-kategori-09",
  "kategori-fe02",
  "kategori-produk-09",
  "manage-nextpage",
  "refund-admin",
  "cancel-thirdparty",
  "update-version",
  "block-menu-linkqu",
  "history-withdraw-manual",
  "history-topup-manual",
  "setting-topup-2",
  "setting-withdraw",
  "setting-transfer-bank",
  "block-menu-linkqu",
  "list-ssl",
  "setting-withdraw-indomaret",
  "setting-withdraw-manual",
  "switch-home",
  "cancel-cimb",
  "hit-ulang-nobu",
];
