/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import axios from "axios";
import { BtoA } from "../../helpers";

function CancelCimb() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kodepesanan: "",
    alasan: "",
    pin: "",
    otp: "",
  });
  const [resultStatus, setResultStatus] = useState("");
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [type, setType] = useState(0);

  const handleChange = (type) => (val) => {
    setForm({ ...form, [type]: val.target.value });
  };

  useEffect(() => {
    getCLient();
    // getData();
  }, []);

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  const saveAction = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    let tipe;
    if (type == 0) {
      tipe = "kereta";
    } else if (type == 1) {
      tipe = "hotel";
    } else if (type == 2) {
      tipe = "pbb";
    } else if (type == 3) {
      tipe = "samsat";
    } else if (type == 4) {
      tipe = "pelni";
    } else if (type == 5) {
      tipe = "pesawat";
    }

    const payload = {
      desc: "refund/thirdparty",
      value: JSON.stringify({
        kodePesanan: form.kodepesanan,
        clientId: selectedClient,
        type: tipe,
        otp: form.otp,
      }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );

    const code = resultUrl.data.code;
    if (code == 200) {
      await dispatch(
        _fetch(
          BridgeService.JbMarket({
            key: "refund/thirdparty",
            method: "post",
            payload: JSON.stringify({
              clientid: selectedClient,
              kodepesanan: form.kodepesanan,
            }),
          })
        )
      );
      return toast.success("Ok");
    } else {
      return toast.error("Gagal");
    }
  };

  const checkPesanan = async () => {
    if (selectedClient == "") return toast.error("Pilih client dahulu");
    if (form.kodepesanan == "")
      return toast.error("Masukkan Kode Pesanan/Invoice dahulu");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    let pload;
    let key;
    //kereta
    if (type == 0) {
      key = "statuskereta";
      pload = {
        clientId: selectedClient,
        kodebooking: form.kodepesanan,
      };
    } else if (type == 1) {
      key = "statushotel";
      pload = {
        clientId: selectedClient,
        kodepesanan: form.kodepesanan,
      };
    } else if (type == 2) {
      key = "statuspbb";
      pload = {
        clientId: selectedClient,
        invoice: form.kodepesanan,
      };
    } else if (type == 3) {
      key = "statussamsat";
      pload = {
        clientId: selectedClient,
        invoice: form.kodepesanan,
      };
    } else if (type == 4) {
      key = "statuspelni";
      pload = {
        clientId: selectedClient,
        kodepesanan: form.kodepesanan,
      };
    } else if (type == 5) {
      key = "statuspesawat";
      pload = {
        clientId: selectedClient,
        kodebooking: form.kodepesanan,
      };
    }

    const payload = {
      desc: key,
      value: JSON.stringify(pload),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );

    if (resultUrl?.data?.lobj[0]?.result == "no") {
      return toast.error(resultUrl?.data?.lobj[0]?.reason);
    }

    const datas = resultUrl.data.lobj[0];
    if (type == 0) {
      setResultStatus(datas?.train_statusbooking);
    } else if (type == 1) {
      setResultStatus(datas?.hotel_statusbooking);
    } else if (type == 2) {
      setResultStatus(datas?.status);
    } else if (type == 3) {
      setResultStatus(datas?.status);
    } else if (type == 4) {
      setResultStatus(datas?.ship_statusbooking);
    } else if (type == 5) {
      setResultStatus(datas?.status);
    }
  };

  const sendOtp = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      desc: "otprefund",
      value: JSON.stringify({ clientid: selectedClient }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );
    const response = resultUrl.data;
    if (response?.code == 200) {
      toast.success("OTP berhasil dikirim");
    } else {
      toast.error("OTP gagal dikirim");
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="cancel-thirdparty" />
          <Content>
            <PageHeading title="Cancel Thirdparty" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Tipe</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={type}
                    >
                      <option value="" disabled readOnly>
                        Pilih Tipe
                      </option>
                      <option value="0">Kereta</option>
                      <option value="1">Hotel</option>
                      <option value="2">PBB</option>
                      <option value="3">Samsat</option>
                      <option value="4">Pelni</option>
                      <option value="5">Pesawat</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Input
                    label="Kode Pesanan"
                    onChange={handleChange("kodepesanan")}
                    value={form.kodepesanan}
                    placeholder="Kode Pesanan"
                  />
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => checkPesanan(0)}
                  />
                  {resultStatus?.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{resultStatus}</div>
                    </>
                  )}
                </Card>
                <Card>
                  <div className="form-group">
                    <label>Tipe</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setType(e.target.value);
                      }}
                      value={type}
                    >
                      <option value="" disabled readOnly>
                        Pilih Tipe
                      </option>
                      <option value="0">Kereta</option>
                      <option value="1">Hotel</option>
                      <option value="2">PBB</option>
                      <option value="3">Samsat</option>
                      <option value="4">Pelni</option>
                      <option value="5">Pesawat</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Input
                    label="Kode Pesanan"
                    onChange={handleChange("kodepesanan")}
                    value={form.kodepesanan}
                    placeholder="Kode Pesanan"
                  />
                  <Input
                    label="OTP"
                    onChange={handleChange("otp")}
                    placeholder="OTP"
                    value={form.otp}
                  />
                  <div className="d-flex align-items-end">
                    <Button
                      title="Kirim OTP"
                      type="danger"
                      onClick={() => {
                        sendOtp();
                      }}
                      block={false}
                    />
                  </div>
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => saveAction()}
                  />
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default CancelCimb;
