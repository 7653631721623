/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";

import "moment/locale/id";
import { BtoA } from "../../helpers";

function HitUlangNobu({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");
  const [clients, setClients] = useState([]);
  const [form, setForm] = useState({
    clientId: "",
    tanggal: moment().add(-7, "days").format("yyyy-MM-DD"),
  });

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  useEffect(() => {
    // getLog("all");
    console.log(location);
    getCLient();
  }, []);

  const getHistory = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const tanggal = moment(form.tanggal).format("YYYYMMDD");

    let resultUrl;
    let oldData;
    resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "getqrisnobu",
        value: JSON.stringify({ clientId: form.clientId, tanggal: tanggal }),
      }
    );
    oldData = resultUrl.data.lobj[0];

    if (resultUrl.data.code == 200) {
      toast.success("Sukses menampilkan daftar");
    } else {
      toast.error("Gagal menampilkan daftar");
    }

    setResult(oldData);
    setOldItem(oldData);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const hitUlang = async (trx) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    // const tanggalNow = moment().format("YYYY-MM-DD");
    const tanggal = moment(form.tanggal).format("YYYYMMDD");

    let resultUrl;
    resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      {
        desc: "qrisnobu",
        value: JSON.stringify({
          clientId: form.clientId,
          tanggal: tanggal,
          transactionNo: trx,
        }),
      }
    );

    if (resultUrl.data.code == 200) {
      toast.success("Sukses");
    } else {
      toast.error("Gagal");
    }

    getHistory();
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.value1?.transactionNo
                ?.toLowerCase()
                .includes(query.toLowerCase())
            );
            console.log(item);
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="hit-ulang-nobu" />
          <Content>
            <PageHeading title="Hit Ulang Nobu" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={handleChange("clientId")}
                      value={form?.clientId}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Input
                    label="Tanggal Mulai"
                    onChange={handleChange("tanggal")}
                    placeholder="contoh 20221201"
                    value={form?.tanggal}
                    type="date"
                  />
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => getHistory()}
                  />
                  <div className="table-responsive">
                    <table
                      className="table table-hover table-checkable order-column full-width"
                      id="example4"
                    >
                      <thead>
                        <tr>
                          <th className=""> No </th>
                          <th className=""> Transaction No </th>
                          <th className=""> # </th>
                        </tr>
                      </thead>
                      <tbody>
                        {result?.map((item, index) => {
                          return (
                            <tr className="odd gradeX" key={index}>
                              <td className="">{index + 1}</td>
                              <td className="">{item.value1.transactionNo}</td>
                              <td className="">
                                <a
                                  className="btn btn-tbl-edit-no-round btn-xs"
                                  onClick={() => {
                                    hitUlang(item?.value1?.transactionNo);
                                  }}
                                >
                                  Hit Ulang
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(HitUlangNobu);
