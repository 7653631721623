/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  AutoComplete,
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { BtoA } from "../../helpers";

function PaketMenu({ history, location }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [form, setForm] = useState({
    desc: "",
    value: "",
    menu: [],
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getPaketMenu();
  }, []);

  const getMenu = async (result) => {
    //get menu by result.value
    const menu = await Promise.all(
      result.map(async (item) => {
        const child = await getChildMenu(item.value);
        return {
          ...item,
          menu: child,
        };
      })
    );
    setResult(menu);
  };

  console.log("result", result);

  const [stateMenu, setStateMenu] = useState(0);

  const getPaketMenu = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      idapps: "",
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/getpaket/?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = resultUrl?.data?.lobj || [];
    setResult(result);
    await getMenu(result);
  };

  const getChildMenu = async (id) => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ id }),
        }),
        false
      )
    );
    const result = res?.data?.lobj || [];

    //remove the duplicate result by id
    const unique = [...new Set(result.map((item) => item.id))];
    const filter = unique.map((id) => {
      return result.find((item) => item.id === id);
    });

    console.log("res child", id, filter);
    return filter;
  };

  const setEdit = (data) => {
    setIsEdit(true);
    setForm(data);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      desc: "",
      value: "",
      menu: [],
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    if (form.menu.length == 0) {
      toast.error("Menu tidak boleh kosong");
      return;
    }
    if (form.desc == "") {
      toast.error("Nama tidak boleh kosong");
      return;
    }
    let payload = {
      ...form,
    };
    if (isEdit) {
      payload = {
        ...payload,
        idapps: form.idapps,
      };
    } else {
      payload = {
        ...payload,
      };
    }
    //remove undefined value in array
    const newValue = payload?.menu?.filter((item) => item != undefined);

    payload = {
      ...payload,
      value: newValue?.map((item) => item?.id).join("#"),
    };

    console.log("payload", payload);

    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/createpaket?access_token=${token}`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (resultUrl?.data?.code == "200") {
      toast.success("Data berhasil disimpan");
      getPaketMenu();
      cancelEdit();
    }
  };

  console.log(form);

  const deleteMenu = (item, indexItem) => {
    const index = form.menu.findIndex((item, index) => index == indexItem);
    form.menu.splice(index, 1);
    setForm({
      ...form,
      menu: form.menu,
    });
  };

  const renderChips = (item, index, isDelete) => {
    return (
      <span
        className={`mdl-chip ${isDelete ? "mdl-chip--deletable" : ""} mr-2`}
        key={item?.id}
      >
        <span className="mdl-chip__text">{item?.nama ?? "-"}</span>
        {isDelete && (
          <button
            type="button"
            className="mdl-chip__action"
            onClick={() => deleteMenu(item, index)}
          >
            <i className="material-icons">cancel</i>
          </button>
        )}
      </span>
    );
  };

  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [resultMenu, setResultMenu] = useState([]);
  const [clickQuery, setClickQuery] = useState(null);

  useEffect(() => {
    getMenuSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getMenuSearch = async () => {
    const res = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ nama: query }),
        }),
        false
      )
    );
    // console.log(res.data.lobj);
    setResultMenu(res?.data?.lobj);
  };

  const addMenu = () => {
    if (clickQuery) {
      const index = form?.menu?.findIndex((item) => item?.id == clickQuery?.id);
      if (index == -1) {
        setForm({
          ...form,
          menu: [...form.menu, clickQuery],
        });
      }
    } else {
      toast.error("Pilih menu terlebih dahulu");
    }
    setQuery(null);
    setModal(false);
  };

  console.log(clickQuery);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="paket-menu" />
          <Content>
            <PageHeading title="Paket Menu" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {isEdit && (
                    <Input
                      label="ID"
                      placeholder="contoh paket hemat"
                      value={form?.idapps}
                      readonly={true}
                      disabled={true}
                    />
                  )}
                  <Input
                    label="Nama"
                    onChange={handleChange("desc")}
                    placeholder="contoh paket hemat"
                    value={form?.desc}
                  />
                  <label className="mx-2">Menu</label>
                  <div className="d-flex flex-wrap mb-2">
                    {form?.menu?.map((item, index) => {
                      return renderChips(item, index, true);
                    })}
                  </div>
                  <h5
                    className="text-dark mt-0 mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => setModal(true)}
                  >
                    + Tambah menu
                  </h5>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama Paket </th>
                        <th className=""> Menu </th>
                        <th className="center"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.desc}</td>
                            <td className="">
                              {item?.menu?.map((item) => {
                                return renderChips(item, false);
                              })}
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setEdit(item);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              {/* <a className="btn btn-tbl-delete btn-xs">
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <div className="form-group d-flex align-items-center">
            <div className="radio" onClick={() => setStateMenu(0)}>
              <input
                type="radio"
                name="tipe"
                id="food"
                value="Masukkan ID Menu"
                checked={stateMenu === 0}
                onChange={(val) => setStateMenu(0)}
              />
              <label htmlFor="optionsRadios1">Masukkan ID Menu</label>
            </div>
            <div className="radio" onClick={() => setStateMenu(1)}>
              <input
                type="radio"
                name="tipe"
                id="1"
                value="Pilih Menu"
                checked={stateMenu === 1}
                onChange={(val) => setStateMenu(1)}
              />
              <label htmlFor="optionsRadios2">Pilih Menu</label>
            </div>
          </div>
          {stateMenu == 0 ? (
            <Input
              label="Masukkan ID Menu"
              onChange={(val) =>
                setClickQuery({
                  id: val.target.value,
                  nama: val.target.value,
                })
              }
            />
          ) : (
            <AutoComplete
              suggestions={resultMenu}
              onChangeText={(val) => setQuery(val)}
              onClickText={(val) => setClickQuery(val)}
            />
          )}
          <div className="d-flex justify-content-center align-items-end mt-4">
            <Button
              title="Tambahkan"
              type="warning"
              onClick={() => addMenu()}
            />
            <Button
              title="Batal"
              type="danger"
              onClick={() => setModal(false)}
              style={{ width: 130, marginLeft: 10 }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(PaketMenu);
