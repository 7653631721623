/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { GlobalService } from "../../services";

function UploadPicture({ history, location }) {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [picture, setPicture] = useState(null);
  const [temporary, setTemporary] = useState("");

  console.log(image);

  const uploadImage = async () => {
    const data = image;
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setPicture(res.file);
  };

  const deleteImage = async () => {
    setImage("");
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="upload-picture" />
          <Content>
            <PageHeading title="Upload Gambar" />
            <div className="row">
              <div className="col-12">
                <Card>
                  {temporary?.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Gambar"
                      type="file"
                      onChange={(e) => {
                        let temporary = window.URL.createObjectURL(
                          e.target.files[0]
                        );
                        setTemporary(temporary);
                        setImage(e.target.files[0]);
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={temporary}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => uploadImage()}
                    />
                  </div>
                  {picture && (
                    <div class="alert alert-success mt-3" role="alert">
                      Upload sukses, link gambar: {picture}{" "}
                      <i
                        style={{ cursor: "pointer" }}
                        class="ml-3 fa fa-copy"
                        aria-hidden="true"
                        onClick={() => {
                          navigator.clipboard.writeText(picture);
                        }}
                      ></i>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(UploadPicture);
