/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clickMenuOpen } from "../../../redux/actions";
import { BtoA, formatDate, formatRupiah } from "../../../helpers";
import axios from "axios";
import { toast } from "react-toastify";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saldo: this.props.saldo,
      loading: false,
      expired: this.props.expired,
    };
  }

  async getSaldo() {
    try {
      this.setState({ loading: true });
      const userSave = localStorage.getItem("credential");
      const btoa = BtoA("kocak123");
      const username = btoa(userSave);
      const password = "password";
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: username,
          password: password,
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      // console.log(result.data);

      const token = result.data.access_token;

      const resultUrl = await axios.get(
        `https://api.setting.berkah-ts.my.id/juber/auth/webadmin/${username}?access_token=${token}`
      );

      const resExpired = await axios.post(
        `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
        {
          desc: "getexpdate",
          value: JSON.stringify({
            clientid: username,
          }),
        }
      );

      let saldo = resultUrl?.data?.value;
      let exp = resExpired?.data?.lobj[0]?.value;

      localStorage.setItem("saldo", saldo);
      localStorage.setItem("expired", exp);
      this.setState({ saldo: saldo, loading: false, expired: exp });
    } catch (error) {
      toast.error(error.message);
      this.setState({ loading: false });
    }
  }
  // get api tiap 30000 ms"
  async componentDidMount() {
    setInterval(async () => {
      this.getSaldo();
    }, 30000);
  }

  render() {
    const { onSearch, onChangeSearch, saldo } = this.props;
    console.warn("saldo sekarang ~> ", saldo);
    const onChange = (val) => {
      onChangeSearch(val);
    };

    return (
      <div className="page-header navbar navbar-fixed-top">
        <div className="page-header-inner ">
          <div className="page-logo d-flex justify-content-center">
            <Link to="/">
              {/* <img
                alt=""
                src={require("../../../assets/img/sup.jpg")}
                style={{ borderRadius: 0, width: 100 }}
              /> */}
              <span className="logo-default ml-2">Webadmin</span>
            </Link>
          </div>
          <div className="search-form-opened">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                name="query"
                onChange={(val) => onChange(val.target.value)}
                onKeyDown={(e) => e.key === "Enter" && onSearch()}
              />
              <span className="input-group-btn search-btn">
                <a className="btn submit" onClick={onSearch}>
                  <i className="fa fa-search"></i>
                </a>
              </span>
            </div>
          </div>

          <a
            className="menu-toggler responsive-toggler"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <span></span>
          </a>

          <div className="top-menu">
            <ul className="nav navbar-nav pull-right">
              <li className="mt-3 text-white mr-4">
                Expired :{" "}
                {this.state.loading
                  ? "-"
                  : formatDate(this.state.expired, "date monthName year")}
              </li>
              <li className="mt-3 text-white mr-4">
                Saldo :{" "}
                {this.state.loading ? "-" : formatRupiah(this.state.saldo)}
              </li>
              {this.state.loading && (
                <li>
                  <div
                    className="spinner-border text-light p-0 mt-3 mr-3"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </li>
              )}
              <li
                className="dropdown dropdown-extended dropdown-notification"
                id="header_notification_bar"
              >
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  data-hover="dropdown"
                  data-close-others="true"
                >
                  <i className="fa fa-bell-o"></i>
                  <span className="notify"></span>
                  <span className="heartbeat"></span>
                </a>
                <ul className="dropdown-menu pullDown">
                  <li className="external">
                    <h3>
                      <span className="bold">Notifications</span>
                    </h3>
                    <span className="notification-label purple-bgcolor">
                      New 6
                    </span>
                  </li>
                  <li>
                    <ul
                      className="dropdown-menu-list small-slimscroll-style"
                      data-handle-color="#637283"
                    >
                      <li>
                        <a>
                          <span className="time">just now</span>
                          <span className="details">
                            <span className="notification-icon circle deepPink-bgcolor box-shadow-1">
                              <i className="fa fa-check"></i>
                            </span>{" "}
                            Congratulations!.{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="time">3 mins</span>
                          <span className="details">
                            <span className="notification-icon circle purple-bgcolor box-shadow-1">
                              <i className="fa fa-user o"></i>
                            </span>
                            <b>John Micle </b>is now following you.{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="time">7 mins</span>
                          <span className="details">
                            <span className="notification-icon circle blue-bgcolor box-shadow-1">
                              <i className="fa fa-comments-o"></i>
                            </span>
                            <b>Sneha Jogi </b>sent you a message.{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="time">12 mins</span>
                          <span className="details">
                            <span className="notification-icon circle pink box-shadow-1">
                              <i className="fa fa-heart"></i>
                            </span>
                            <b>Ravi Patel </b>like your photo.{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="time">15 mins</span>
                          <span className="details">
                            <span className="notification-icon circle yellow box-shadow-1">
                              <i className="fa fa-warning"></i>
                            </span>{" "}
                            Warning!{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a>
                          <span className="time">10 hrs</span>
                          <span className="details">
                            <span className="notification-icon circle red box-shadow-1">
                              <i className="fa fa-times"></i>
                            </span>{" "}
                            Application error.{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="dropdown-menu-footer">
                      <a> All notifications </a>
                    </div>
                  </li>
                </ul>
              </li>

              <li
                className="dropdown dropdown-extended dropdown-inbox"
                id="header_inbox_bar"
              >
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  data-hover="dropdown"
                  data-close-others="true"
                >
                  <i className="fa fa-envelope-o"></i>
                  <span className="notify"></span>
                  <span className="heartbeat"></span>
                </a>
                <ul className="dropdown-menu animated pullDown">
                  <li className="external">
                    <h3>
                      <span className="bold">Messages</span>
                    </h3>
                    <span className="notification-label cyan-bgcolor">
                      New 2
                    </span>
                  </li>
                  <li>
                    <ul
                      className="dropdown-menu-list small-slimscroll-style"
                      data-handle-color="#637283"
                    >
                      <li>
                        <a href="#">
                          <span className="photo">
                            <img
                              src="../../assets/img/user/user2.jpg"
                              className="img-circle"
                              alt=""
                            />{" "}
                          </span>
                          <span className="subject">
                            <span className="from"> Sarah Smith </span>
                            <span className="time">Just Now </span>
                          </span>
                          <span className="message">
                            {" "}
                            Jatin I found you on LinkedIn...{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="photo">
                            <img
                              src="../../assets/img/user/user3.jpg"
                              className="img-circle"
                              alt=""
                            />{" "}
                          </span>
                          <span className="subject">
                            <span className="from"> John Deo </span>
                            <span className="time">16 mins </span>
                          </span>
                          <span className="message">
                            {" "}
                            Fwd: Important Notice Regarding Your Domain Name...{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="photo">
                            <img
                              src="../../assets/img/user/user1.jpg"
                              className="img-circle"
                              alt=""
                            />{" "}
                          </span>
                          <span className="subject">
                            <span className="from"> Rajesh </span>
                            <span className="time">2 hrs </span>
                          </span>
                          <span className="message">
                            {" "}
                            pls take a print of attachments.{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="photo">
                            <img
                              src="../../assets/img/user/user8.jpg"
                              className="img-circle"
                              alt=""
                            />{" "}
                          </span>
                          <span className="subject">
                            <span className="from"> Lina Smith </span>
                            <span className="time">40 mins </span>
                          </span>
                          <span className="message">
                            {" "}
                            Apply for Ortho Surgeon{" "}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="photo">
                            <img
                              src="../../assets/img/user/user5.jpg"
                              className="img-circle"
                              alt=""
                            />{" "}
                          </span>
                          <span className="subject">
                            <span className="from"> Jacob Ryan </span>
                            <span className="time">46 mins </span>
                          </span>
                          <span className="message">
                            {" "}
                            Request for leave application.{" "}
                          </span>
                        </a>
                      </li>
                    </ul>
                    <div className="dropdown-menu-footer">
                      <a href="#"> All Messages </a>
                    </div>
                  </li>
                </ul>
              </li>
              <li className="dropdown dropdown-user">
                <a
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  data-hover="dropdown"
                  data-close-others="true"
                >
                  <img
                    alt=""
                    className="img-circle "
                    src="https://juber.co.id/storage/images/WwkwKadPauideSLTHEZtW76PPmyOMXmeKA39pHm1.png"
                  />
                </a>
                <ul className="dropdown-menu dropdown-menu-default animated jello">
                  <li>
                    <a href="user_profile.html">
                      <i className="fa fa-user-o"></i> Profile{" "}
                    </a>
                  </li>
                  <li>
                    <Link to="withdraw">
                      <i className="fa fa-balance-scale"></i> Withdraw
                    </Link>
                  </li>
                  <li>
                    <Link to="topup">
                      <i className="fa fa-money"></i> Topup
                    </Link>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-question-circle"></i> Help
                    </a>
                  </li>
                  <li className="divider"> </li>
                  <li>
                    <a href="lock_screen.html">
                      <i className="fa fa-lock"></i> Lock
                    </a>
                  </li>
                  <li>
                    <a href="login.html">
                      <i className="fa fa-sign-out"></i> Log Out{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ clickMenuOpen }, dispatch);

const mapStateToProps = (store) => ({
  toggled: store.menuState.menuOpen,
  saldo: store.saldo.saldo,
  expired: store.expired.expired,
});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
