/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { cipher } from "../../helpers";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { withRouter } from "react-router-dom";

const status = [
  { id: 0, name: "Tidak Aktif" },
  { id: 1, name: "Aktif" },
  { id: 2, name: "Expired" },
  { id: 3, name: "Tidak Valid" },
  { id: 4, name: "Terverifikasi" },
  { id: 5, name: "Belum Terverifikasi" },
  { id: 6, name: "Blokir" },
];

function Verification({ history }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [docsts, setDocsts] = useState(4);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");

  console.log("docsts", docsts);

  const enc = cipher("akuimuet");

  useEffect(() => {
    getSetting();
    console.log("docsts ganti");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docsts]);

  const getSetting = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "adminlistuser",
          payload: JSON.stringify({ doksts: docsts }),
        })
      )
    );
    const data = res?.data?.lobj;
    setOldItem(data);
    setResult(data);
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="verifikasi" />
          <Content>
            <PageHeading title="Manage Verification" />
            <div className="row">
              <div className="col-12">
                {/* create dropdown */}
                <div className="form-group">
                  <label htmlFor="docsts">Status</label>
                  <select
                    className="form-control"
                    onChange={(e) => setDocsts(e.target.value)}
                    style={{ width: "50%" }}
                  >
                    <option value="">Pilih Status</option>
                    {status.map((it, id) => {
                      return (
                        <option
                          key={id}
                          value={it.id}
                          selected={docsts === it.id}
                        >
                          {it.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="row clearfix">
                  {result?.length === 0 ? (
                    <div className="col-12 text-center">
                      <h6>Tidak ada data</h6>
                    </div>
                  ) : (
                    result?.map((it, id) => {
                      return (
                        <Link
                          key={id}
                          className="col-xl-4 col-lg-4 col-md-6 col-sm-12 text-center text-dark"
                          to={{
                            pathname: `/verification-details`,
                            search: `?id=${enc(it)}`,
                          }}
                        >
                          <div className="card">
                            <div className="panel-body">
                              <h6>{it.replace("#", " - ")}</h6>
                            </div>
                          </div>
                        </Link>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Verification);
