/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import axios from "axios";
import "./style.css";
import { BtoA } from "../../helpers";
import { Tab, Tabs } from "react-bootstrap";

function Topup({ history, location }) {
  let userAccount = useSelector((state) => state.auth);
  userAccount = userAccount?.user;
  console.table(userAccount);
  // let saldo = useSelector((state) => state.saldo);
  // saldo = saldo?.saldo;
  const userSave = localStorage.getItem("credential");
  const btoa = BtoA("kocak123");
  const username = btoa(userSave);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    customer_name: userAccount?.namars,
    amount: "",
    kode_agen: userAccount?.idrs,
    email: "",
    nomer_hp: "",
    retail_code: "INDOMARET",
    idmenu: "1687434067459",
    clientid: username,
  });
  const [modal, setModal] = useState(false);
  const [formManual, setFormManual] = useState({
    norek: "",
    namaBank: "",
    namaPemilikRek: "",
    jumlah: 0,
  });
  const [resultManual, setResultManual] = useState("");

  console.log(form);

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const handleChangeManual = (name) => (e) => {
    setFormManual({
      ...formManual,
      [name]: e.target.value,
    });
  };

  const topupManual = async () => {
    try {
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: username,
          password: "password",
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      console.log(result.data);

      const token = result.data.access_token;

      const payload = {
        ...formManual,
      };

      const resultUrl = await axios.post(
        `https://api.setting.berkah-ts.my.id/juber/auth/topupmanual/${username}?access_token=${token}`,
        payload
      );

      const response = resultUrl.data;
      const msg = response?.msg;
      // const replacedString = msg.replace(/\[enter\]/g, "\n");
      const replacedString = msg.split("[enter]").map((item, index) => (
        <React.Fragment key={index}>
          {item}
          {index !== msg.split("[enter]").length - 1 && <br />}
        </React.Fragment>
      ));
      console.log(response);
      setResultManual(replacedString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = async () => {
    try {
      const result = await axios.post(
        "https://api.setting.berkah-ts.my.id/oauth/token",
        new URLSearchParams({
          username: username,
          password: "password",
          grant_type: "password",
        }),
        {
          headers: {
            Authorization:
              "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
          },
        }
      );

      console.log(result.data);

      const token = result.data.access_token;

      const resultUrl = await axios.get(
        `https://api.setting.berkah-ts.my.id/juber/auth/base/${username}?access_token=${token}`
      );

      let response = resultUrl.data.lobj[0];
      console.log(response);
      setForm({
        ...form,
        customer_name: response.compName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [msg, setMsg] = useState("");

  const topupAction = async () => {
    //validasi jika nominal melebihi 1jt
    if (form?.amount > 1000000) {
      toast.error("Maksimal Withdraw Rp. 1.000.000");
      return;
    }

    //validasi jika saldo kurang dari nominal
    // if (form?.amount > saldo) {
    //   toast.error("Saldo tidak cukup");
    //   return;
    // }

    const payload = {
      ...form,
    };

    console.log(payload);
    // return;
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "databank/retailpaymentwebadmin",
          payload: JSON.stringify(payload),
        })
      )
    );

    if (res?.data?.success) {
      const payment_code = res.data.data.payment_code;
      const msg = `Topup via indomaret berhasil.\nKode bayar: ${payment_code}.\nSilahkan bayar ke kasir.`;
      setMsg(msg);
      setModal(true);
      return;
    }
  };

  //replace \n with <h6></h6>
  const replaceNewLine = (str) => {
    const split = str.split("\n");
    const replacedString = split.map((item, index) => (
      <React.Fragment key={index}>
        {item}
        {index !== split.length - 1 && <br />}
      </React.Fragment>
    ));
    return replacedString;
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="" />
          <Content>
            <PageHeading title="Topup" />
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab eventKey={1} title="Topup Manual">
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <Input
                        label="No. Rekening"
                        onChange={handleChangeManual("norek")}
                        placeholder="No. Rekening"
                        value={formManual?.norek}
                      />
                      <Input
                        label="Nama Bank"
                        onChange={handleChangeManual("namaBank")}
                        placeholder="Nama Bank"
                        value={formManual?.namaBank}
                      />
                      <Input
                        label="Nama Pemilik Rekening"
                        onChange={handleChangeManual("namaPemilikRek")}
                        placeholder="Nama Pemilik Rekening"
                        value={formManual?.namaPemilikRek}
                      />
                      <Input
                        label="Jumlah"
                        onChange={handleChangeManual("jumlah")}
                        placeholder="10000"
                        value={formManual?.jumlah}
                      />
                      {/* <div className="d-flex flex-column text-left">
                    <div
                      className="badge badge-danger mb-3 text-left"
                      style={{ width: "max-content" }}
                    >
                      Pastikan Email dan No HP sudah benar dikarenakan kode akan
                      dikirimkan ke email dan no hp
                    </div>
                    <div
                      className="badge badge-info mb-3"
                      style={{ width: "max-content" }}
                    >
                      Maksimal Withdraw Rp. 1.000.000
                    </div>
                  </div> */}
                      <div className="d-flex justify-content-center align-items-end">
                        <Button
                          title={"Topup"}
                          type="warning"
                          onClick={() => topupManual()}
                        />
                      </div>
                      {resultManual?.length !== 0 && (
                        <>
                          <hr />
                          <div className="alert alert-success">
                            {resultManual}
                          </div>
                        </>
                      )}
                    </Card>
                  </div>
                </div>
              </Tab>
              <Tab eventKey={2} title="Topup Indomaret">
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <Input
                        label="Jumlah"
                        onChange={handleChange("amount")}
                        placeholder="10000"
                        value={form?.amount}
                      />
                      <Input
                        label="Email"
                        onChange={handleChange("email")}
                        placeholder="example@gmail.com"
                        value={form?.email}
                      />
                      <Input
                        label="No HP"
                        onChange={handleChange("nomer_hp")}
                        placeholder="081xxxxxx"
                        value={form?.nomer_hp}
                      />
                      {/* <div className="d-flex flex-column text-left">
                    <div
                      className="badge badge-danger mb-3 text-left"
                      style={{ width: "max-content" }}
                    >
                      Pastikan Email dan No HP sudah benar dikarenakan kode akan
                      dikirimkan ke email dan no hp
                    </div>
                    <div
                      className="badge badge-info mb-3"
                      style={{ width: "max-content" }}
                    >
                      Maksimal Withdraw Rp. 1.000.000
                    </div>
                  </div> */}
                      <div className="d-flex justify-content-center align-items-end">
                        <Button
                          title={"Topup"}
                          type="warning"
                          onClick={() => topupAction()}
                        />
                      </div>
                    </Card>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Content>
        </Container>
        <Modal show={modal} onHide={() => setModal(false)}>
          <div>
            <h6>{replaceNewLine(msg)}</h6>
            <Button
              title="OK"
              onClick={() => {
                setModal(true);
                history.push("/dashboard");
              }}
            />
          </div>
        </Modal>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Topup);
