import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function ManageUser() {
  const dispatch = useDispatch();
  const [kodeAgen, setKodeAgen] = useState("");
  const [pin, setPin] = useState("");
  const [result, setResult] = useState("");

  const forceAction = async (type) => {
    if (kodeAgen.length === 0) return toast.error("Masukkan Kode Agen");

    let key = type === 0 ? "nonaktifuser" : "aktifuser";
    const res = await dispatch(
      _fetch_noerror(
        BridgeService.JbDelivery({
          key,
          payload: JSON.stringify({ pin: "1234567", kodeAgen }),
        }),
        false
      )
    );
    if (res?.data?.msg?.includes("tidak berhak")) {
      return toast.error(res?.data?.msg);
    } else {
      toast.success(res?.data?.msg);
    }
  };

  const submitAction = async (type) => {
    if (pin.length === 0) return toast.error("Masukkan PIN");

    let key = type === 0 ? "nonaktifuser" : "aktifuser";
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key,
          payload: JSON.stringify({ pin, kodeAgen }),
        }),
        false
      )
    );
    setResult(res?.data?.msg);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="manage-user" />
          <Content>
            <PageHeading title="Nonaktifkan & Aktifkan User" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Kode Agen"
                    onChange={(e) => setKodeAgen(e.target.value)}
                    placeholder="Masukkan Kode Agen"
                  />
                  <Input
                    label="OTP"
                    onChange={(e) => setPin(e.target.value)}
                    placeholder="Masukkan OTP"
                  />
                  <div className="d-flex justify-content-end align-items-end">
                    <Button
                      title="Kirim OTP (Nonaktifkan)"
                      type="danger"
                      onClick={() => forceAction(0)}
                      noMb
                    />
                    <div className="ml-3" />
                    <Button
                      title="Kirim OTP (Aktifkan)"
                      type="warning"
                      onClick={() => forceAction(1)}
                      noMb
                    />
                  </div>
                  <div className="mt-2 d-flex justify-content-end align-items-end">
                    <Button
                      title="Nonaktifkan"
                      type="danger"
                      onClick={() => submitAction(0)}
                      noMb
                    />
                    <div className="ml-3" />
                    <Button
                      title="Aktifkan"
                      type="warning"
                      onClick={() => submitAction(1)}
                      noMb
                    />
                  </div>
                  {result?.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result}</div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ManageUser;
