import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService, GlobalService } from "../../services";

function VoucherCreate({ history }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    namapromo: "",
    kodepromo: "",
    quota: "",
    diskon: "",
    tglexp: moment().format("YYYY-MM-DD"),
    type: "BOLEH_TRX_NOL",
  });

  const [json, setJson] = useState({
    service: "FOOD",
    imgurl: "",
    payment: "cash",
  });
  const [result, setResult] = useState("");

  const handleChange = (type) => (val) => {
    setForm({ ...form, [type]: val.target.value });
  };

  const handleChangeJson = (type) => (val) => {
    setJson({ ...json, [type]: val.target.value });
  };

  const uploadImage = async (data) => {
    const res = await dispatch(_fetch(GlobalService.uploadFoto(data)));
    if (!res?.success) return;
    setJson({ ...json, imgurl: res.file });
  };

  const deleteImage = async () => {
    const res = await dispatch(_fetch(GlobalService.deleteFoto(json?.imgurl)));
    if (!res?.success) return;
    setJson({ ...json, imgurl: "" });
  };

  console.log(json);

  const createAction = async () => {
    if (form?.namapromo?.length === 0)
      return toast.error("Masukkan Nama Voucher");
    if (form?.kodepromo?.length === 0)
      return toast.error("Masukkan Kode Promo");
    if (form?.quota?.length === 0) return toast.error("Masukkan Quota");
    if (form?.diskon?.length === 0) return toast.error("Masukkan Potongan");
    if (json?.service?.length === 0) return toast.error("Masukkan Service");
    if (json?.imgurl?.length === 0) return toast.error("Masukkan Gambar");

    const payload = {
      ...form,
      diskon: Number(form.diskon),
      quota: Number(form.quota),
      json: `service#${json.service};imgurl#${json.imgurl};payment#${json.payment}`,
    };

    console.log("json", json);
    console.log(payload);
    // return;

    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "createvoucher",
          payload: JSON.stringify(payload),
        })
      )
    );
    setForm({
      namapromo: "",
      kodepromo: "",
      quota: "",
      diskon: "",
      tglexp: moment().format("YYYY-MM-DD"),
    });
    setJson({
      service: "",
      imgurl: "",
      payment: "cash",
    });
    setResult("Berhasil membuat voucher");
    history.goBack();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="voucher" />
          <Content>
            <PageHeading title="Buat Voucher" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Voucher"
                    onChange={handleChange("namapromo")}
                    placeholder="Hari Kemerdekaan"
                    value={form.namapromo}
                  />
                  <Input
                    label="Kode Promo"
                    onChange={handleChange("kodepromo")}
                    placeholder="MERDEKA"
                    value={form.kodepromo}
                  />
                  <Input
                    label="Quota"
                    onChange={handleChange("quota")}
                    placeholder="100"
                    value={form.quota}
                  />
                  <Input
                    label="Potongan"
                    onChange={handleChange("diskon")}
                    placeholder="5000"
                    value={form.diskon}
                  />
                  <Input
                    type="date"
                    label="Expire"
                    onChange={handleChange("tglexp")}
                    value={form.tglexp}
                  />
                  <div className="form-group mb-3">
                    <label>Type</label>
                    <select
                      className="form-control"
                      onChange={handleChange("type")}
                    >
                      <option value="BOLEH_TRX_NOL">BOLEH_TRX_NOL</option>
                      <option value="ONGKIR">ONGKIR</option>
                      <option value="BARANG">BARANG</option>
                      <option value="ALL">ALL</option>
                    </select>
                  </div>
                  <div className="form-group mb-3">
                    <label>Service</label>
                    <select
                      className="form-control"
                      onChange={handleChangeJson("service")}
                    >
                      <option value="FOOD">FOOD</option>
                      <option value="MARKET">MARKET</option>
                      <option value="SERVICE">SERVICE</option>
                      <option value="RIDE">RIDE</option>
                    </select>
                  </div>
                  {json?.imgurl?.length === 0 ? (
                    <Input
                      accept="image/*"
                      label="Pilih Gambar"
                      type="file"
                      onChange={(e) => uploadImage(e?.target?.files[0])}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "9.5rem",
                        padding: 10,
                        border: "2px solid #d2d6de",
                        backgroundColor: "rgba(210, 210, 210, 0.25)",
                        color: "#ffffff",
                      }}
                      className="mb-3 d-flex flex-column"
                    >
                      <img
                        src={json.imgurl}
                        alt=""
                        className="img-thumbnail mb-2"
                        style={{
                          height: "8rem",
                          width: "8rem",
                          objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          borderRadius: 99,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className="text-center d-flex justify-content-center align-items-center"
                        onClick={() => deleteImage()}
                      >
                        <i
                          className="material-icons"
                          style={{ color: "#E44F56", cursor: "pointer" }}
                        >
                          cancel
                        </i>
                      </div>
                    </div>
                  )}
                  <div className="form-group mb-3">
                    <label>Payment</label>
                    <select
                      className="form-control"
                      onChange={handleChangeJson("payment")}
                    >
                      <option value="cash">cash</option>
                      <option value="emoney">emoney</option>
                    </select>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title="Submit"
                      type="warning"
                      onClick={() => createAction()}
                    />
                    <Button
                      title="Kembali"
                      type="danger"
                      onClick={() => history.goBack()}
                      style={{ width: 130, marginLeft: 10 }}
                    />
                  </div>
                  {result?.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result}</div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(VoucherCreate);
