/* eslint-disable no-control-regex */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { changeSecondToDateFormatHms } from "../../helpers";
import axios from "axios";

function SwitchHome({ history, location }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [form, setForm] = useState({
    value: "",
    otp: "",
  });
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    getSwitchHome();
    // getCurrentSetting();
  }, []);

  useEffect(() => {
    if (!selectedClient) return;
    // getData();
  }, [selectedClient]);

  useEffect(() => {
    getCLient();
    // getData();
  }, []);

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  const getCurrentSetting = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "switchhome",
          method: "get",
        })
      )
    );
    const result = res?.data?.data[0];
    setForm({ ...form, value: result?.value });
  };

  const getSwitchHome = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "masterswitchhome",
          method: "get",
        })
      )
    );
    const result = res?.data?.lobj || [];
    setResult(result);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });

    console.log(e.target.value);
  };

  const saveAction = async (force) => {
    const key = "switchhome/update";
    const method = "post";

    // const userSave = localStorage.getItem("credential");
    // const btoa = BtoA("kocak123");
    // const username = btoa(userSave);

    let pload = {
      ...form,
      otp: force ? "123456" : form.otp,
      client: selectedClient,
    };

    let res;

    if (force) {
      res = await dispatch(
        _fetch_noerror(
          BridgeService.JbMarket({
            key,
            method,
            payload: JSON.stringify(pload),
          })
        )
      );
    } else {
      res = await dispatch(
        _fetch(
          BridgeService.JbMarket({
            key,
            method,
            payload: JSON.stringify(pload),
          })
        )
      );
    }

    if (force) {
      const time = new Date().getTime();
      localStorage.setItem("time_pin", time);
      setPinShowed(true);
    } else {
      setDifference(120);
      localStorage.removeItem("time_pin");
      setPinShowed(false);
    }

    if (res?.data?.code == 200) {
      toast.success(`Success Update Switch Home`);
      getSwitchHome();
      getCurrentSetting();
      setForm({
        value: "",
        otp: "",
      });
    }
  };

  const getDifferenceTimeInHms = (time) => {
    const timeNow = new Date().getTime();
    const diff = timeNow - time;
    const diffInMinutes = diff / 1000;
    return diffInMinutes;
  };

  const [difference, setDifference] = useState(120);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!localStorage.getItem("time_pin")) return;
      const between = getDifferenceTimeInHms(localStorage.getItem("time_pin"));
      //hitung mundur
      setDifference(120 - between);
      //if 2 minutes passed, remove the pin
      if (between > 120) {
        setPinShowed(false);
        localStorage.removeItem("time_pin");
        setDifference(120);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [pinShowed, setPinShowed] = useState(localStorage.getItem("time_pin"));

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="switch-home" />
          <Content>
            <PageHeading title="Setting Switch Home" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Pilih Master Switch Home</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        handleChange("value")(e);
                      }}
                      value={form.value}
                    >
                      <option value="">Pilih Master Switch Home</option>
                      {result?.map((it, id) => {
                        return (
                          <option key={it.idapps} value={it.idapps}>
                            {`${it.value} | ${it.desc}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="d-flex align-items-end">
                    {pinShowed && (
                      <Input
                        label="OTP"
                        onChange={(e) =>
                          setForm({ ...form, otp: e.target.value })
                        }
                        placeholder="083845"
                        value={form.otp}
                        containerStyle={{ marginRight: "1rem" }}
                      />
                    )}
                    <Button
                      title={`Kirim OTP (${changeSecondToDateFormatHms(
                        difference
                      )})`}
                      type="info"
                      onClick={() => saveAction(true)}
                      block={false}
                      disabled={difference < 120}
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Update"}
                      type="warning"
                      onClick={() => saveAction(false)}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(SwitchHome);
