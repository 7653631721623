import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Container,
  Content,
  ItemMenu,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function ListMenu() {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMenu = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbDelivery({ key: "admingethome" }), false)
    );
    let resMenu = res?.data?.lobj;
    //change json in index 0 with "{\"update\":\"false\",\"icon\":\"https://juber.co.id/storage/images/pzyK1jMVqdzFo6WMaibn0VIa1YoZuX7egEAMQ1lO.png\",\"posisi\":\"1\",\"isKyc\":\"0\",\"frame\":\"0\"}"
    // resMenu[0].json =
    //   '{"update":"false","icon":"https://juber.co.id/storage/images/pzyK1jMVqdzFo6WMaibn0VIa1YoZuX7egEAMQ1lO.png","posisi":"1","isKyc":"0","frame":"0"}';

    // resMenu[188].json =
    //   '{"update":"false","icon":"https://juber.co.id/storage/images/pzyK1jMVqdzFo6WMaibn0VIa1YoZuX7egEAMQ1lO.png","posisi":"1","isKyc":"0","frame":"0"}';

    //show only index 0
    // resMenu = resMenu.filter((item, index) => index === 16);

    console.log("resmenu", resMenu);
    // return;
    setResult(resMenu);
    const pilihanMenu = res?.data?.msg?.split("#");
    console.log("pilihanMenu", pilihanMenu);
    localStorage.setItem("pilihanMenu", JSON.stringify(pilihanMenu));
  };

  const cariMenu = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "carimenu",
          payload: JSON.stringify({ nama: query }),
        }),
        false
      )
    );
    console.log(res.data.lobj);
    setResult(res?.data?.lobj);
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => cariMenu()}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="menu" />
          <Content>
            <PageHeading title="List Menu" add to="/create-menu" />
            <div className="alert alert-danger mb-3" role="alert">
              Menu yang berwarna merah yaitu menu yang tidak AKTIF
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row clearfix">
                  {result?.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        className="col-xl-2 col-lg-3 col-md-6 col-sm-12 text-center text-dark"
                        to={{
                          pathname: "/edit-menu",
                          query: { item, state: "edit" },
                        }}
                      >
                        <ItemMenu item={item} />
                      </Link>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListMenu;
