/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { useDispatch } from "react-redux";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function MaintenanceApk() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    status: false,
    pesan: "",
    dateEnd: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (type) => (val) => {
    setForm({ ...form, [type]: val.target.value });
  };

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "get",
          key: "systemmaintenance",
        })
      )
    );
    const datas = res.data.data;
    // setAsli(datas);
    setForm({
      status: datas.status == "on" ? true : false,
      pesan: datas.pesan,
      dateEnd: datas.dateEnd,
    });
  };

  const saveAction = async (pload) => {
    const payload = {
      status: form.status ? "on" : "off",
      dateEnd: form.dateEnd,
      //   dateEnd: "2023-12-16 21:00",
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "systemmaintenance/update",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="maintenance-apk" />
          <Content>
            <PageHeading title="Maintenance APK" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="va"
                      value="option1"
                      checked={form.status}
                      onChange={() => {
                        setForm({
                          ...form,
                          status: !form.status,
                        });
                      }}
                    />
                    <label className="custom-control-label" for="va">
                      Status (ON/OFF) Aplikasi
                    </label>
                  </div>
                  {form.status == false && (
                    <Input
                      type="datetime-local"
                      value={form.dateEnd}
                      onChange={handleChange("dateEnd")}
                      label="Waktu Selesai"
                    />
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(MaintenanceApk);
