import axios from "axios";
import { generateHeaders, uuid, baseHostUrl } from "../config";

export default {
  async getProductByStore(id) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "authorization",
      "irsauth",
      "content-json",
    ]);
    return axios.post(
      `${baseUrl}/apps/jbmarket/get`,
      {
        uuid,
        key: "product/store",
        method: "GET",
        payload: JSON.stringify({ id: id }),
      },
      { ...config }
    );
  },
  async deleteProduct(id) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "authorization",
      "irsauth",
      "content-json",
    ]);
    return axios.post(
      `${baseUrl}/apps/jbmarket/post`,
      {
        uuid,
        key: "product",
        method: "delete",
        payload: JSON.stringify({ id: id }),
      },
      { ...config }
    );
  },
};
