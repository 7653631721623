/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { cipher, formatDate } from "../../helpers";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function PengembalianBarang() {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);

  const enc = cipher("akuimuet");

  useEffect(() => {
    getReturn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReturn = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "return/get/admin",
          method: "get",
        })
      )
    );
    setResult(res?.data?.data);
  };

  const getStoreName = async (id) => {
    if (!id) return;
    let data = await dispatch(
      _fetch_noerror(
        BridgeService.JbMarket({
          key: "store",
          method: "get",
          payload: JSON.stringify({ id: id }),
        })
      )
    );
    console.log("asdf", data.data.success);
    if (!data?.data?.success) return { store_name: "-" };
    if (data.data.hasOwnProperty("store_name")) return data.data;
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="pengembalian-barang" />
          <Content>
            <PageHeading title="Pengembalian Barang" />
            <div className="row">
              <div className="col-12">
                {result?.map((item, id) => {
                  return (
                    <div className="col-lg-3 col-md-6 col-12 col-sm-6" key={id}>
                      <Link
                        to={{
                          pathname: `/detail-sales`,
                          search: `?id=${enc(JSON.stringify(item))}`,
                        }}
                      >
                        <div className="card blogThumb">
                          <div className="thumb-center">
                            <img
                              className="img-responsive"
                              alt="user"
                              src={
                                item.profile.profile_picture ??
                                "https://juber.co.id/storage/images/vn89khHW9Foo9GgZMZWVbdnZTXwvzWPECWvrUCh2.png"
                              }
                              style={{
                                height: "9rem",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <div
                            className="vehicle-box"
                            style={{
                              textAlign: "justify",
                            }}
                          >
                            {item.profile.name ?? "Error"} (Pembeli) &{" "}
                            {getStoreName(item.trans_head.store_id).store_name}
                          </div>
                          <div
                            className="vehicle-box"
                            style={{
                              textAlign: "justify",
                            }}
                          >
                            {formatDate(
                              item.created_at,
                              "date monthName year hour:minute"
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default PengembalianBarang;
