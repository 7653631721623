import axios from "axios";
import { generateHeaders, baseRequest, uuid, baseHostUrl } from "../config";

export default {
  async createInformasi(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return axios.post(
      `${baseUrl}/apps/jbdelivery/post`,
      { ...payload, uuid, key: "createinfo" },
      config
    );
  },

  async getInformasi(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return baseRequest.post(
      `${baseUrl}/apps/jbdelivery/post`,
      { ...payload, uuid, key: "getallinfo" },
      config
    );
  },

  async deleteInformasi(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "content-json",
      "authorization",
    ]);
    return baseRequest.post(
      `${baseUrl}/apps/jbdelivery/post`,
      { ...payload, uuid, key: "deleteinfo" },
      config
    );
  },

  async editInformasi(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "authorization",
      "irsauth",
      "content-json",
    ]);
    return baseRequest.post(
      `${baseUrl}/apps/jbdelivery/post`,
      { ...payload, uuid, key: "createinfo" },
      config
    );
  },
};
