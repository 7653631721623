import axios from "axios";
import { generateHeaders, uuid, baseHostUrl } from "../config";

export default {
  async loginOtp(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "irsauth",
      "irsauth_header",
      "content-json",
    ]);
    return axios.post(`${baseUrl}/apps/users/loginotp`, { ...payload }, config);
  },
  async verifyRegisterOtp(payload) {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders(["irsauth", "content-json"]);
    return axios.post(
      `${baseUrl}/apps/users/verifyotp`,
      { ...payload },
      config
    );
  },
  async logout() {
    const baseUrl = await baseHostUrl();
    const config = await generateHeaders([
      "authorization",
      "irsauth",
      "content-json",
    ]);
    return axios.post(`${baseUrl}/apps/users/logoutjb`, { uuid }, config);
  },
};
