let saldo = localStorage.getItem("saldo");
const initialState = saldo ? { saldo } : { saldo: 0 };

export function Saldo(state = initialState, action) {
  switch (action.type) {
    case "SET_SALDO":
      return {
        ...state,
        saldo: action.payload,
      };
    default:
      return state;
  }
}
