import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";

//Pages
import {
  SignIn,
  Dashboard,
  NotFound,
  CheckAccount,
  Broadcast,
  Informasi,
  InformasiCreate,
  InformasiEdit,
  UpdateVersi,
  Voucher,
  VoucherCreate,
  Pengaturan,
  PengaturanAllIn,
  BannerPromo,
  DriverManual,
  ListMenu,
  ListLokasi,
  ListLokasiMenu,
  MenuCreate,
  ListColor,
  AddColor,
  MonitorBroadcast,
  ForceSuccess,
  ForceCancel,
  Verification,
  VerificationDetails,
  CancelAdmin,
  BonusReferal,
  UpdateUser,
  ListMenuTop,
  TopMarket,
  TopMarketCreate,
  TopProdukFood,
  TopProdukFoodCreate,
  Penjualan,
  PenjualanDetail,
  PeriksaChat,
  PeriksaChatSee,
  PeriksaChatDetail,
  ChatAdmin,
  ListBlokir,
  ListBlokirDriver,
  Slider,
  MappingOto,
  OtpSms,
  ListBlokirMarketplace,
  ListBlokirMerchant,
  CheckTransaction,
  Fee,
  Deposit,
  Layanan,
  ListKategori,
  ListKategoriDetail,
  UploadPicture,
  PhoneAdmin,
  ThirdParty,
  ReasonReturn,
  BuildApk,
  Whitelist,
  PaketMenu,
  SetPaketMenu,
  Refund,
  ManageUser,
  LogTransaksi,
  MasterBank,
  MasterSwitch,
  SwitchHome,
  MonitoringClient,
  ListClient,
  MasterDeeplink,
  SettingLogo,
  IntruksiTopup,
  CetakStruk,
  KategoriPembukuan,
  PengaturanTopup,
  ListProducts,
  KategoriFE02,
  ListBankTopupVA,
  VAMitra,
  ManageNextpage,
  MasterSosmed,
  Withdraw,
  BulkVoucher,
  MasterKategori09,
  KategoriProduk09,
  BlacklistMenu,
  HargaCoret,
  UbahIcon,
  Topup,
  RefundAdmin,
  ManagementCache,
  BlockMenuLinkqu,
  SettingWithdraw,
  HistoryWithdrawManual,
  HistoryTopupManual,
  SettingTopup,
  MaintenanceApk,
  SettingTransferBank,
  ChangeUUID,
  ListSSL,
  SettingWithdrawIndomaret,
  SettingWithdrawManual,
  SystemKata,
  PengembalianBarang,
  MarketKurir,
  CancelCimb,
  Hadiah,
  LogLogin,
  HitUlangNobu,
  SimulasiTransaksi,
  HideMenuLokasi,
} from "./pages";
import { LoginRoute } from "./components/LoginRoute";
import ScrollToTop from "./ScrollToTop";

const Routes = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Switch>
      <LoginRoute exact path="/login" component={SignIn} />
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute path="/home" component={Dashboard} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/check-account" component={CheckAccount} />
      <PrivateRoute path="/broadcast" component={Broadcast} />
      <PrivateRoute path="/information" component={Informasi} />
      <PrivateRoute path="/create-information" component={InformasiCreate} />
      <PrivateRoute path="/edit-information" component={InformasiEdit} />
      <PrivateRoute path="/update-version" component={UpdateVersi} />
      <PrivateRoute path="/voucher" component={Voucher} />
      <PrivateRoute path="/create-voucher" component={VoucherCreate} />
      <PrivateRoute path="/setting" component={Pengaturan} />
      <PrivateRoute path="/setting-all-in" component={PengaturanAllIn} />
      <PrivateRoute path="/banner-promo" component={BannerPromo} />
      <PrivateRoute path="/driver-manual" component={DriverManual} />
      <PrivateRoute path="/list-menu" component={ListMenu} />
      <PrivateRoute path="/list-location" component={ListLokasi} />
      <PrivateRoute path="/list-locmenu" component={ListLokasiMenu} />
      <PrivateRoute path="/create-menu" component={MenuCreate} />
      <PrivateRoute path="/edit-menu" component={MenuCreate} />
      <PrivateRoute path="/list-color" component={ListColor} />
      <PrivateRoute path="/add-color" component={AddColor} />
      <PrivateRoute path="/monitor-broadcast" component={MonitorBroadcast} />
      <PrivateRoute path="/force-success" component={ForceSuccess} />
      <PrivateRoute path="/force-cancel" component={ForceCancel} />
      <PrivateRoute path="/cancel-admin" component={CancelAdmin} />
      <PrivateRoute path="/verification" component={Verification} />
      <PrivateRoute path="/bonus-referal" component={BonusReferal} />
      <PrivateRoute path="/update-user" component={UpdateUser} />
      <PrivateRoute
        path="/verification-details"
        component={VerificationDetails}
      />
      <PrivateRoute path="/list-menu-top" component={ListMenuTop} />
      <PrivateRoute path="/top-market" component={TopMarket} />
      <PrivateRoute path="/create-top-market" component={TopMarketCreate} />
      <PrivateRoute path="/edit-top-market" component={TopMarketCreate} />
      <PrivateRoute path="/top-product-food" component={TopProdukFood} />
      <PrivateRoute
        path="/create-top-product-food"
        component={TopProdukFoodCreate}
      />
      <PrivateRoute
        path="/edit-top-product-food"
        component={TopProdukFoodCreate}
      />
      <PrivateRoute path="/sales" component={Penjualan} />
      <PrivateRoute path="/detail-sales" component={PenjualanDetail} />
      <PrivateRoute path="/check-chat" component={PeriksaChat} />
      <PrivateRoute path="/see-check-chat" component={PeriksaChatSee} />
      <PrivateRoute path="/detail-check-chat" component={PeriksaChatDetail} />
      <PrivateRoute path="/chat-admin" component={ChatAdmin} />
      <PrivateRoute path="/list-block" component={ListBlokir} />
      <PrivateRoute path="/list-driver-block" component={ListBlokirDriver} />
      <PrivateRoute path="/sliders" component={Slider} />
      <PrivateRoute path="/mapping-oto" component={MappingOto} />
      <PrivateRoute path="/otp-sms" component={OtpSms} />
      <PrivateRoute
        path="/list-market-block"
        component={ListBlokirMarketplace}
      />
      <PrivateRoute path="/list-food-block" component={ListBlokirMerchant} />
      <PrivateRoute path="/list-service-block" component={ListBlokirMerchant} />
      <PrivateRoute path="/check-transaction" component={CheckTransaction} />
      <PrivateRoute path="/fee" component={Fee} />
      <PrivateRoute path="/deposit" component={Deposit} />
      <PrivateRoute path="/layanan" component={Layanan} />
      <PrivateRoute path="/list-kategori" component={ListKategori} />
      <PrivateRoute path="/list-food-kategori" component={ListKategoriDetail} />
      <PrivateRoute
        path="/list-service-kategori"
        component={ListKategoriDetail}
      />
      <PrivateRoute path="/upload-picture" component={UploadPicture} />
      <PrivateRoute path="/phone-admin" component={PhoneAdmin} />
      <PrivateRoute path="/product-thirdparty" component={ThirdParty} />
      <PrivateRoute path="/reason-return" component={ReasonReturn} />
      <PrivateRoute path="/build-apk" component={BuildApk} />
      <PrivateRoute path="/logout-paksa" component={Whitelist} />
      <PrivateRoute path="/paket-menu" component={PaketMenu} />
      <PrivateRoute path="/paket-menu-client" component={SetPaketMenu} />
      <PrivateRoute path="/refund" component={Refund} />
      <PrivateRoute path="/manage-user" component={ManageUser} />
      <PrivateRoute path="/log-transaksi" component={LogTransaksi} />
      <PrivateRoute path="/master-bank" component={MasterBank} />
      <PrivateRoute path="/master-switch" component={MasterSwitch} />
      <PrivateRoute path="/switch-home" component={SwitchHome} />
      <PrivateRoute path="/monitoring-client" component={MonitoringClient} />
      <PrivateRoute path="/list-client" component={ListClient} />
      <PrivateRoute path="/master-deeplink" component={MasterDeeplink} />
      <PrivateRoute path="/setting-logo" component={SettingLogo} />
      <PrivateRoute path="/intruksi-topup" component={IntruksiTopup} />
      <PrivateRoute path="/cetak-struk" component={CetakStruk} />
      <PrivateRoute path="/kategori-pembukuan" component={KategoriPembukuan} />
      <PrivateRoute path="/setting-topup" component={PengaturanTopup} />
      <PrivateRoute path="/list-products" component={ListProducts} />
      <PrivateRoute path="/kategori-fe02" component={KategoriFE02} />
      <PrivateRoute path="/list-bank-va" component={ListBankTopupVA} />
      <PrivateRoute path="/va-mitra" component={VAMitra} />
      <PrivateRoute path="/manage-nextpage" component={ManageNextpage} />
      <PrivateRoute path="/master-sosmed" component={MasterSosmed} />
      <PrivateRoute path="/withdraw" component={Withdraw} />
      <PrivateRoute path="/bulk-voucher" component={BulkVoucher} />
      <PrivateRoute path="/master-kategori-09" component={MasterKategori09} />
      <PrivateRoute path="/kategori-produk-09" component={KategoriProduk09} />
      <PrivateRoute path="/blacklist-menu" component={BlacklistMenu} />
      <PrivateRoute path="/harga-coret" component={HargaCoret} />
      <PrivateRoute path="/ubah-icon" component={UbahIcon} />
      <PrivateRoute path="/topup" component={Topup} />
      <PrivateRoute path="/cancel-thirdparty" component={RefundAdmin} />
      <PrivateRoute path="/management-cache" component={ManagementCache} />
      <PrivateRoute path="/block-menu-linkqu" component={BlockMenuLinkqu} />
      <PrivateRoute path="/setting-withdraw" component={SettingWithdraw} />
      <PrivateRoute
        path="/setting-withdraw-indomaret"
        component={SettingWithdrawIndomaret}
      />
      <PrivateRoute
        path="/setting-withdraw-manual"
        component={SettingWithdrawManual}
      />
      <PrivateRoute path="/setting-topup-2" component={SettingTopup} />
      <PrivateRoute
        path="/history-topup-manual"
        component={HistoryTopupManual}
      />
      <PrivateRoute
        path="/history-withdraw-manual"
        component={HistoryWithdrawManual}
      />
      <PrivateRoute path="/maintenance-apk" component={MaintenanceApk} />
      <PrivateRoute
        path="/setting-transfer-bank"
        component={SettingTransferBank}
      />
      <PrivateRoute path="/change-uuid" component={ChangeUUID} />
      <PrivateRoute path="/list-ssl" component={ListSSL} />
      <PrivateRoute path="/system-kata" component={SystemKata} />
      <PrivateRoute
        path="/pengembalian-barang"
        component={PengembalianBarang}
      />
      <PrivateRoute path="/market-kurir" component={MarketKurir} />
      <PrivateRoute path="/cancel-cimb" component={CancelCimb} />
      <PrivateRoute path="/hadiah" component={Hadiah} />
      <PrivateRoute path="/log-login" component={LogLogin} />
      <PrivateRoute path="/hit-ulang-nobu" component={HitUlangNobu} />
      <PrivateRoute path="/simulasi-transaksi" component={SimulasiTransaksi} />
      <PrivateRoute path="/hide-menu-lokasi" component={HideMenuLokasi} />
      <Route path="*" component={NotFound} />
    </Switch>
    <button
      style={{
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: 1,
        backgroundColor: "white",
        border: "none",
        cursor: "pointer",
        height: 50,
        width: 50,
        borderRadius: 50,
      }}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <i className="fa fa-arrow-up"></i>
    </button>
  </BrowserRouter>
);

export default Routes;
