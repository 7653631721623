/* eslint-disable no-func-assign */
/* eslint-disable eqeqeq */
import moment from "moment";
import { toast } from "react-toastify";

export const handleFetchError = (e, source) => {
  console.log("error asli", JSON.stringify(e));
  if (!e) return;
  // const alert = useAlert();
  console.log(e);
  let msg = e;
  // if (isArray(e)) e = e[0];
  if (e.msg) msg = e.msg;
  if (typeof msg === "object") msg = JSON.stringify(msg);
  // console.log('asdf', e.message);
  if (e === "reqcanceled") return;
  if (e.message === "Network Error")
    msg = "Network Error atau Jaringan Bermasalah";
  if (e.hasOwnProperty("message")) {
    if (e.message.includes("code 504")) msg = e.name;
    // if (e.name.toLowerCase().includes('error')) msg = 'Error!';
  }

  if (process.env && process.env.NODE_ENV === "development") {
    console.log("error", e);
    console.log("error formatted", JSON.parse(JSON.stringify(e)));
    console.log("error msg", msg);

    if (e.request) console.log("request", e.request);
    if (e.response) console.log("response", e.response);
  }

  // alert('Error: ' + msg + (e.config?.url ? ` | url: ${e.config.url}` : ''));
  toast.error(msg);
  // LocalNotification('Gagal', msg);
};

export const stringifyNumber = (val) =>
  Number(val) < 10 ? "0" + val : "" + val;

// supported format: day, date, month, monthName, year, hour, minute. example: 'day, date month year'
export const formatDate = (val = new Date(), format = "year-month-date") => {
  const days = [
    "Ahad / Minggu",
    "Senin",
    "Selasa",
    "Rabu",
    "Kamis",
    "Jum'at",
    "Sabtu",
  ];
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const monthsLess = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];

  if (typeof val == "string") val = new Date(val);

  let res = format;
  if (format.includes("minute"))
    res = res.replace("minute", stringifyNumber(val.getMinutes()));
  if (format.includes("hour"))
    res = res.replace("hour", stringifyNumber(val.getHours()));
  if (format.includes("day")) res = res.replace("day", days[val.getDay()]);
  if (format.includes("date"))
    res = res.replace("date", stringifyNumber(val.getDate()));
  if (format.includes("monthName"))
    res = res.replace("monthName", months[val.getMonth()]);
  if (format.includes("monthLess"))
    res = res.replace("monthLess", monthsLess[val.getMonth()]);
  else if (format.includes("month"))
    res = res.replace("month", stringifyNumber(parseInt(val.getMonth()) + 1));
  if (format.includes("year")) res = res.replace("year", val.getFullYear());

  return res;
};

export const emergencyDate = (date) => {
  if (!date) return "-";
  return moment(date).format("DD MMMM YYYY, HH:mm");
};

export const batasiKata = (str, max = 50, suffix = "...") =>
  str.length < max
    ? str
    : `${str.substr(
        0,
        str.substr(0, max - suffix.length).lastIndexOf(" ")
      )}${suffix}`;

export const formatRupiah = (angka) => {
  if (angka == null) {
    return "Rp.-";
  }
  angka = parseInt(angka);
  angka = angka.toString();
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return "Rp. " + rupiah;
};

export const formatNumber = (val) =>
  !val ? 0 : val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

export const formatRupiahBolehMinus = (angka) => {
  if (angka == null) {
    return `Rp. -`;
  }

  let uang = parseInt(angka);

  return `Rp. ${formatNumber(uang)}`;
};

export const cipher = (salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);

  return (text) =>
    text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};

export const decipher = (salt) => {
  const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code);
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join("");
};

export const getJenisDok = (id) => {
  switch (id) {
    case "01":
      return "KTP";
    case "02":
      return "SIM";
    case "03":
      return "PASSPORT";
    case "04":
      return "STNK";
    case "05":
      return "BPKB";
    case "06":
      return "SKCK";
    case "07":
      return "SELFIE";
    case "09":
      return "Foto Terima";
    case "10":
      return "Foto Antar";
    default:
      return "";
  }
};

export const jsonDokumen = () => {
  const data = [
    { no: "01", name: "KTP" },
    { no: "02", name: "SIM" },
    { no: "03", name: "PASSPORT" },
    { no: "04", name: "STNK" },
    { no: "05", name: "BPKB" },
    { no: "06", name: "SKCK" },
    { no: "07", name: "SELFIE" },
    { no: "09", name: "Foto Terima" },
    { no: "10", name: "Foto Antar" },
  ];

  return data;
};

export const getStatus = (status) => {
  switch (status) {
    case 0:
      return "Tidak Aktif";
    case 1:
      return "Aktif";
    case 2:
      return "Expired";
    case 3:
      return "Tidak Valid (Ditolak)";
    case 4:
      return "Terverifikasi";
    case 5:
      return "Belum Terverifikasi";
    case 6:
      return "Terblokir";
    default:
      return null;
  }
};

export const isExpired = (date) => {
  var end = moment(new Date()); //todays date
  var now = moment(date); // another date
  var duration = moment.duration(now.diff(end));
  var days = duration.asDays();
  if (days < 0) {
    return true;
  } else {
    return false;
  }
};

export const fckingDateDiff = (date) => {
  var end = moment(new Date()); //todays date
  var now = moment(date); // another date
  var duration = moment.duration(now.diff(end));
  var days = duration.asDays();
  var month = duration.asMonths();
  if (days <= 0) return "0 Hari";
  if (days > 30) return Math.floor(month) + " Bulan";
  return Math.floor(days) + " Hari";
};

(function (_0x383d71, _0x36e9e4) {
  const _0x1d6110 = _0x5cf0,
    _0x817c75 = _0x383d71();
  while (!![]) {
    try {
      const _0x3aaf76 =
        (-parseInt(_0x1d6110(0x151)) / 0x1) *
          (parseInt(_0x1d6110(0x153)) / 0x2) +
        -parseInt(_0x1d6110(0x14f)) / 0x3 +
        (-parseInt(_0x1d6110(0x15d)) / 0x4) *
          (parseInt(_0x1d6110(0x159)) / 0x5) +
        parseInt(_0x1d6110(0x15a)) / 0x6 +
        (parseInt(_0x1d6110(0x15b)) / 0x7) *
          (parseInt(_0x1d6110(0x158)) / 0x8) +
        parseInt(_0x1d6110(0x152)) / 0x9 +
        parseInt(_0x1d6110(0x15e)) / 0xa;
      if (_0x3aaf76 === _0x36e9e4) break;
      else _0x817c75["push"](_0x817c75["shift"]());
    } catch (_0x5b9f12) {
      _0x817c75["push"](_0x817c75["shift"]());
    }
  }
})(_0x114d, 0x674c7);
export const AtoB = (_0x3937a9) => {
  const _0x4cbcaf = _0x5cf0,
    _0x18afc5 = (_0x54f48c) =>
      _0x54f48c["split"]("")[_0x4cbcaf(0x155)]((_0xab827b) =>
        _0xab827b[_0x4cbcaf(0x15c)](0x0)
      ),
    _0x102d93 = (_0x82ac53) =>
      ("0" + Number(_0x82ac53)[_0x4cbcaf(0x154)](0x10))["substr"](-0x2),
    _0x483eaa = (_0x2245ff) =>
      _0x18afc5(_0x3937a9)[_0x4cbcaf(0x156)](
        (_0x483a75, _0x2babb5) => _0x483a75 ^ _0x2babb5,
        _0x2245ff
      );
  return (_0x57cf98) =>
    _0x57cf98[_0x4cbcaf(0x150)]("")
      ["map"](_0x18afc5)
      [_0x4cbcaf(0x155)](_0x483eaa)
      [_0x4cbcaf(0x155)](_0x102d93)
      [_0x4cbcaf(0x14e)]("");
};
export const BtoA = (_0xc5250d) => {
  const _0x39eef3 = _0x5cf0,
    _0x2ac724 = (_0x24ffa9) =>
      _0x24ffa9["split"]("")[_0x39eef3(0x155)]((_0x192808) =>
        _0x192808[_0x39eef3(0x15c)](0x0)
      ),
    _0x323170 = (_0xc71337) =>
      _0x2ac724(_0xc5250d)[_0x39eef3(0x156)](
        (_0x3bf65f, _0x5e3a1e) => _0x3bf65f ^ _0x5e3a1e,
        _0xc71337
      );
  return (_0x5436e2) =>
    _0x5436e2[_0x39eef3(0x157)](/.{1,2}/g)
      [_0x39eef3(0x155)]((_0x1eb542) => parseInt(_0x1eb542, 0x10))
      ["map"](_0x323170)
      [_0x39eef3(0x155)]((_0x527a1b) => String["fromCharCode"](_0x527a1b))
      [_0x39eef3(0x14e)]("");
};
function _0x5cf0(_0xab631e, _0x17980b) {
  const _0x114d0a = _0x114d();
  return (
    (_0x5cf0 = function (_0x5cf055, _0x3bd379) {
      _0x5cf055 = _0x5cf055 - 0x14e;
      let _0x3409ac = _0x114d0a[_0x5cf055];
      return _0x3409ac;
    }),
    _0x5cf0(_0xab631e, _0x17980b)
  );
}
function _0x114d() {
  const _0x522466 = [
    "1998610kRABEY",
    "join",
    "464655BHSbuF",
    "split",
    "244208lWvdbV",
    "5160627Uqadwv",
    "4WDucwR",
    "toString",
    "map",
    "reduce",
    "match",
    "4648DaRFAC",
    "1880VHQaHt",
    "1477830edDPuE",
    "6825gBOEoP",
    "charCodeAt",
    "5528KvmWoW",
  ];
  _0x114d = function () {
    return _0x522466;
  };
  return _0x114d();
}

export const changeSecondToDateFormatHms = (seconds) => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getSeconds();
  let timeString;
  if (seconds > 3600) {
    timeString = `${hh.toString().padStart(2, "0")}:${mm
      .toString()
      .padStart(2, "0")}:${ss.toString().padStart(2, "0")}`;
  } else if (seconds > 60) {
    timeString = `${mm.toString().padStart(2, "0")}:${ss
      .toString()
      .padStart(2, "0")}`;
  } else {
    timeString = `${ss.toString().padStart(2, "0")} detik`;
  }
  return timeString;
};
