/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function Refund() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    kodepesanan: "",
    alasan: "",
    pin: "",
  });
  const [result, setResult] = useState("");
  const [statePin, setStatePin] = useState(0);

  const handleChange = (type) => (val) => {
    setForm({ ...form, [type]: val.target.value });
  };

  const saveAction = async (state) => {
    if (form.alasan.length === 0) return toast.error("Masukkan Alasan");
    if (form.kodepesanan.length === 0) return toast.error("Pilih Trx Id");
    if (form.pin.length === 0 && statePin === 1)
      return toast.error("Masukkan PIN");
    if (state === 0 && statePin === 0) return toast.error("Kirim Pin Dulu");
    const payload = {
      ...form,
      pin: statePin === 1 ? form.pin : "123456",
    };
    const res = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "adminforcecancelthirdparty",
          payload: JSON.stringify(payload),
        })
      )
    );
    if (res.data.msg.toLowerCase().includes("silahkan cek pin otp")) {
      setForm({ ...form, pin: "" });
      setStatePin(1);
    } else {
      setForm({ kodepesanan: "", alasan: "", pin: "" });
    }
    setResult(res.data.msg);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="cancel-thirdparty" />
          <Content>
            <PageHeading title="Cancel Thirdparty" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Kode Pesanan"
                    onChange={handleChange("kodepesanan")}
                    value={form.kodepesanan}
                    placeholder="Kode Pesanan"
                  />
                  <Input
                    label="Alasan"
                    onChange={handleChange("alasan")}
                    placeholder="Sys Error"
                    value={form.alasan}
                  />
                  <div className="d-flex align-items-end">
                    {statePin === 1 && (
                      <Input
                        label="PIN"
                        onChange={handleChange("pin")}
                        placeholder="123456"
                        value={form.pin}
                        noMb={true}
                        containerStyle={{ marginBottom: 10, marginRight: 10 }}
                      />
                    )}
                    {statePin === 0 && (
                      <Button
                        title="Kirim Pin"
                        type="danger"
                        onClick={() => {
                          saveAction(1);
                        }}
                        block={false}
                      />
                    )}
                  </div>
                  <Button
                    title="Submit"
                    type="warning"
                    onClick={() => saveAction(0)}
                  />
                  {result.length !== 0 && (
                    <>
                      <hr />
                      <div className="alert alert-success">{result}</div>
                    </>
                  )}
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default Refund;
