/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Card,
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch, _fetch_noerror } from "../../redux/actions/global";
import { BridgeService, ProductService } from "../../services";
import { decipher, formatRupiah } from "../../helpers";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ListProducts({ history, location }) {
  const dec = decipher("akuimuet");
  const { search } = useLocation();
  const id = new URLSearchParams(search).get("id");
  const decrypt = dec(id);
  const parseDecrypt = JSON.parse(decrypt);
  const idStore = parseDecrypt?.id;
  let query = useQuery();
  const [result, setResult] = useState([]);
  const jenis = query.get("type");
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(ProductService.getProductByStore(idStore))
    );
    console.log(res);
    setResult(res?.data?.data || []);
  };

  const deleteProduct = async (id) => {
    const res = await dispatch(
      _fetch_noerror(ProductService.deleteProduct(id))
    );

    if (res?.data?.success) {
      toast.success("Berhasil menghapus produk");
    } else {
      toast.error(res?.data?.message);
    }

    getData();
  };

  const blockUnblockProduct = async (item) => {
    let payload = {
      id: item.id,
      blocked: item.blocked == 0 || item.blocked == false ? true : false,
      block_reason: "",
    };
    const res = await dispatch(
      _fetch_noerror(
        BridgeService.JbMarket({
          method: "put",
          key: "admin/block/product",
          payload: JSON.stringify(payload),
        })
      )
    );

    if (res?.data?.success) {
      toast.success(res?.data?.message);
    } else {
      toast.error(res?.data?.message);
    }

    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="blokir" />
          <Content>
            <PageHeading title={`List Produk ${jenis}`} />
            <div className="row">
              <div className="col-12">
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> Nama Produk </th>
                        <th className=""> Deskripsi </th>
                        <th className=""> Harga </th>
                        <th className=""> Gambar </th>
                        <th>Status</th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.name}</td>
                            <td className="">{item.description}</td>
                            <td className="">
                              {formatRupiah(item.selling_price)}
                            </td>
                            <td className="">
                              <img
                                src={item.picture}
                                alt=""
                                style={{ width: 50, height: 50 }}
                              />
                            </td>
                            <td className="">
                              {item.is_shown == "1" ? "AKTIF" : "TIDAK AKTIF"}
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteProduct(item.id);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  blockUnblockProduct(item);
                                }}
                              >
                                <i
                                  className={
                                    item.blocked == 0
                                      ? "fa fa-ban"
                                      : "fa fa-check"
                                  }
                                ></i>
                                {item.blocked == 0 ? "Blokir" : "Buka Blokir"}
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(ListProducts);
