let expired = localStorage.getItem("expired");
const initialState = expired ? { expired } : { expired: null };

export function Expired(state = initialState, action) {
  switch (action.type) {
    case "SET_EXPIRED":
      return {
        ...state,
        expired: action.payload,
      };
    default:
      return state;
  }
}
