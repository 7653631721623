/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { useDispatch } from "react-redux";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function CetakStruk() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    headerprint: "",
    footer1print: "",
    footer2print: "",
  });

  const getCetakStruk = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "cetakstruk",
          method: "get",
        })
      )
    );
    const data = res?.data?.data?.value;
    const parseData = JSON.parse(data);
    setForm(parseData);
  };

  useEffect(() => {
    getCetakStruk();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name) => (e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const saveAction = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "cetakstruk/update",
          method: "post",
          payload: JSON.stringify({
            value: form,
          }),
        })
      )
    );

    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      toast.success(data?.message);
      getCetakStruk();
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="cetak-struk" />
          <Content>
            <PageHeading title="Edit Cetak Struk" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Header"
                    onChange={handleChange("headerprint")}
                    placeholder="Misal Cara Topup"
                    value={form?.headerprint}
                    multiline
                  />
                  <Input
                    label="Footer 1"
                    onChange={handleChange("footer1print")}
                    placeholder="Misal Cara Topup"
                    value={form?.footer1print}
                    multiline
                  />
                  <Input
                    label="Footer 2"
                    onChange={handleChange("footer2print")}
                    placeholder="Misal Cara Topup"
                    value={form?.footer2print}
                    multiline
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default CetakStruk;
