import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA, emergencyDate, formatRupiah } from "../../helpers";

function ListClient() {
  const [clients, setClients] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [query, setQuery] = React.useState("");

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let clients = data?.data;
      let usernames = clients.map((client) => client.username);
      usernames = usernames.join("#");
      getSaldo(clients, usernames);
    }
  };

  const getSaldo = async (clients, usernames) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      value: usernames,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/getsaldo?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = resultUrl?.data?.lobj || [];
    const newClients = clients?.map((client) => {
      const saldo = result.find((item) => item?.idapps === client.username);
      return {
        ...client,
        saldo: saldo?.value,
      };
    });
    setClients(newClients);
    setOldItem(newClients);
  };

  console.log(clients);

  useEffect(() => {
    getCLient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState([]);

  const getDownload = async (name, index) => {
    setLoading({
      ...loading,
      [index]: true,
    });
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/download/client/" + name
    );
    const data = res?.data;
    if (!data.success) {
      //find index and set loading to false
      const newLoading = loading.map((item, i) => {
        if (i === index) {
          return false;
        } else {
          return item;
        }
      });

      setLoading(newLoading);
      return toast.error(data?.message);
    } else {
      //find index and set loading to false
      const newLoading = loading.map((item, i) => {
        if (i === index) {
          return false;
        } else {
          return item;
        }
      });

      setLoading(newLoading);
      window.open(res?.data?.data?.file, "_blank");
    }
  };

  console.log(loading);

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.name?.toLowerCase().includes(query.toLowerCase())
            );
            setClients(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="list-client" />
          <Content>
            <PageHeading title="List Client (Nama APK, dll)" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> # </th>
                        <th className=""> Nama APK </th>
                        <th className=""> Download APK </th>
                        <th className=""> Terakhir Build </th>
                        <th className=""> Nama Folder di FE </th>
                        <th className=""> Package ID </th>
                        <th className=""> Versi APK </th>
                        <th className=""> Versi AAB </th>
                        <th className=""> Saldo </th>
                      </tr>
                    </thead>
                    <tbody>
                      {clients?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">{item?.name}</td>
                            <td className="">
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  getDownload(item.username, index)
                                }
                              >
                                {loading[index] ? (
                                  <div
                                    className="spinner-border spinner-border-sm text-light"
                                    role="status"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </div>
                                ) : (
                                  "Download"
                                )}
                              </button>
                            </td>
                            <td className="">
                              APK :{" "}
                              {emergencyDate(item?.latest_apk?.updated_at)}{" "}
                              <br />
                              AAB :{" "}
                              {emergencyDate(item?.latest_aab?.updated_at)}
                            </td>
                            <td className="">{item?.folder}</td>
                            <td className="">{item?.package}</td>
                            <td className="">{item?.version}</td>
                            <td className="">{item?.version_aab}</td>
                            <td className="">
                              {formatRupiah(Number(item?.saldo))}
                            </td>
                          </tr>
                        );
                      })}
                      <tr className="odd gradeX">
                        <td colSpan={7} className="text-right font-weight-bold">
                          Total
                        </td>
                        <td className="text-start font-weight-bold">
                          {formatRupiah(
                            clients?.reduce(
                              (acc, curr) => acc + Number(curr?.saldo ?? 0),
                              0
                            )
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListClient;
