import React from "react";

function Button({
  title,
  type = "primary",
  small = false,
  large = false,
  rounded = false,
  block = true,
  noMb,
  loading,
  ...otherProps
}) {
  return (
    <button
      {...otherProps}
      type="button"
      className={`mdl-button mdl-button--raised mdl-js-ripple-effect ${
        noMb ? "" : "m-b-10"
      } btn-${type} ${block ? "btn-block" : ""} ${
        (small || large) && `btn-${small ? "sm" : large ? "lg" : ""}`
      } ${rounded ? "rounded-pill" : ""}`}
    >
      {loading ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        title
      )}
    </button>
  );
}

export default Button;
