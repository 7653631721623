/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function KategoriFE02() {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    idmenu: "",
    nama: "",
    nextpage: "",
    urlgambar: "",
    hargamulai: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [asli, setAsli] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    getData();
    setAsli([]);
    setResult([]);
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({ key: "rekomendasiproduk", method: "get" })
      )
    );
    const result = res?.data?.data;
    console.log(result);
    // setResult(data || []);
  };

  const updateAction = async () => {
    const selected = selectedData;
    // change value by findItem
    const newResult = result.map((item, index) => {
      if (selected?.index == index) {
        return form;
      } else {
        return item;
      }
    });
    saveAction(newResult);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      idmenu: "",
      nama: "",
      nextpage: "",
      urlgambar: "",
      hargamulai: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async (pload) => {
    let kategori;
    if (pload) {
      kategori = pload;
    } else {
      kategori = [...result, form];
    }
    const value = JSON.stringify({ kategori });
    const payload = JSON.stringify({
      list: [{ idapps: asli.idapps, desc: asli.desc, value }],
    });
    console.log(payload);

    // return;
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "savesetting",
          payload,
        })
      )
    );
    clearForm();
    getData();
    setIsEdit(false);
  };

  const deleteAction = async (item, index) => {
    //remove index
    let tempData = null;
    result.forEach((item, i) => {
      console.log(i);
      if (i == index) {
        tempData = item;
        console.log("masuk sini");
      }
    });
    console.log(tempData, index);
    const newResult = result.filter((item) => item !== tempData);
    // console.table(newResult);
    saveAction(newResult);
    // saveAction(data);
  };

  const clearForm = () => {
    setForm({
      idmenu: "",
      nama: "",
      nextpage: "",
      urlgambar: "",
      hargamulai: "",
    });
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="kategori-fe02" />
          <Content>
            <PageHeading title="Kategori FE02" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="ID"
                    onChange={handleChange("id")}
                    placeholder="ID"
                    value={form?.id}
                  />
                  <Input
                    label="Nama Kategori"
                    onChange={handleChange("nama")}
                    placeholder="Nama Kategori"
                    value={form?.nama}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => (isEdit ? updateAction() : saveAction())}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> ID </th>
                        <th className=""> Nama Kategori </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item.id}</td>
                            <td className="">{item.nama}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    ...item,
                                    uuid: item.uuid,
                                    idreseller: item.idreseller,
                                  });
                                  setSelectedData({ ...item, index });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item, index);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(KategoriFE02);
