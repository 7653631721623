/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Container,
  Content,
  Input,
  ItemMenu,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { getStatus } from "../../helpers";
import { _fetch, _fetch_nomsg } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function ListBlokirDriver() {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [resultAsli, setResultAsli] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const payload = {
      doksts: "6",
      jnsUser: "driver",
    };
    let data = [];
    let dataAcc = [];
    let dataTolak = [];
    let dataBelum = [];
    const resBelum = await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "adminlistuserbysts",
          payload: JSON.stringify({
            doksts: "5",
            jnsUser: "driver",
          }),
        }),
        false
      )
    );
    const resBlock = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "adminlistuserbysts",
          payload: JSON.stringify(payload),
        }),
        false
      )
    );
    const resAcc = await dispatch(
      _fetch_nomsg(
        BridgeService.JbDelivery({
          key: "adminlistuserbysts",
          payload: JSON.stringify({
            doksts: "4",
            jnsUser: "driver",
          }),
        }),
        false
      )
    );
    dataBelum = resBelum?.data?.lobj?.map((it) => {
      return {
        ...it,
        status: 5,
      };
    });
    dataTolak = resBlock?.data?.lobj?.map((it) => {
      return {
        ...it,
        status: 6,
      };
    });
    dataAcc = resAcc?.data?.lobj?.map((it) => {
      return {
        ...it,
        status: 4,
      };
    });
    data = [...dataTolak, ...dataAcc];
    //order by sim
    data.sort((a, b) => {
      return a.sim.localeCompare(b.sim);
    });
    console.log(resBlock.data);
    setResult(data);
    setResultAsli(data);
  };

  const blockAction = async (item, type) => {
    let sts;
    if (type == "block") {
      sts = 6;
    } else {
      sts = 4;
    }

    const payload = {
      idrs: item.kodeAgen,
      doksts: `02-${sts}`,
    };
    console.log(payload);
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "adminverdok",
          payload: JSON.stringify(payload),
        })
      )
    );
    // setBlockState({ showModal: false, txtReason: null, id: null });
    // console.log(res.data.lobj);
    toast.success(`Berhasil ${sts == 6 ? "Blokir" : "Unblokir"} driver`);
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="blokir" />
          <Content>
            <PageHeading title="List Driver" />
            <div className="row">
              <div className="col-12">
                <Input
                  placeholder="Cari sesuatu..."
                  onChange={(e) =>
                    setResult(
                      resultAsli?.filter((it) =>
                        it.nama
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    )
                  }
                />
                <div className="row clearfix">
                  {result?.map((it, id) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-12 col-sm-6"
                        key={id}
                      >
                        <div className="card blogThumb">
                          <div
                            className="thumb-center"
                            style={{ backgroundColor: "lightgrey" }}
                          >
                            <img
                              className="img-responsive"
                              alt="user"
                              src={
                                it.image != "" || it.image
                                  ? it.image
                                  : "https://www.hartlepoolwater.co.uk/globalassets/page-not-found.png"
                              }
                              style={{ height: "9rem", objectFit: "contain" }}
                            />
                          </div>
                          <div
                            className="vehicle-name bg-b-danger"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <div style={{ fontSize: 18 }}>{it.nama}</div>
                          </div>
                          <div
                            className="vehicle-box"
                            style={{ textAlign: "justify", minHeight: 85 }}
                          >
                            Status : {getStatus(it?.status)}
                            <h5>Merk : {it?.merk}</h5>
                            <h5>Plat Nomor : {it?.nopol}</h5>
                            <h5>No Telp : {it?.telp}</h5>
                          </div>
                          <div className="center py-2">
                            {it.status == 5 ? (
                              <>
                                <div
                                  className={`btn btn-tbl-delete btn-xs mr-2 btn-no-full-round`}
                                  onClick={() => blockAction(it, "block")}
                                >
                                  Tolak
                                  <i className={"fa fa-ban"}></i>
                                </div>
                                <div
                                  className={`btn btn-tbl-edit btn-xs mr-2 btn-no-full-round`}
                                  onClick={() => blockAction(it, "unblock")}
                                >
                                  Terima <i className={"fa fa-check"}></i>
                                </div>
                              </>
                            ) : (
                              <div
                                className={`btn ${
                                  it.status == 6
                                    ? "btn-tbl-edit"
                                    : "btn-tbl-delete"
                                } btn-xs mr-2 btn-no-full-round`}
                                onClick={() =>
                                  blockAction(
                                    it,
                                    it.status == 6 ? "unblock" : "block"
                                  )
                                }
                              >
                                {it.status == 6 ? "Unblokir" : "Blokir"}
                                <i
                                  className={
                                    it.status == 6 ? "fa fa-check" : "fa fa-ban"
                                  }
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListBlokirDriver;
