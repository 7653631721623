/* eslint-disable no-control-regex */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { formatDate } from "../../helpers";

function MonitoringClient({ history, location }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  // const [asli, setAsli] = useState([]);
  const [query, setQuery] = React.useState("");

  const [timeRefresh, setTimeRefresh] = useState(60);

  useEffect(() => {
    getMonitoring();
  }, []);

  //create interval every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      getMonitoring();
    }, timeRefresh * 1000);
    return () => clearInterval(interval);
  }, [timeRefresh]);

  const getMonitoring = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "clientmonitoring",
          method: "get",
        })
      )
    );
    const result = res?.data?.data;
    const value = result?.value;
    const clients = JSON.parse(value)?.clients || [];
    console.log(clients);
    setResult(clients);
    setOldItem(clients);
    // setAsli(clients);
  };

  const getColorClass = (date) => {
    const expiredDate = new Date(); // Ganti dengan tanggal expired yang diinginkan

    if (date > expiredDate) {
      return "danger";
    } else if (
      date >=
      new Date(
        expiredDate.getFullYear(),
        expiredDate.getMonth(),
        expiredDate.getDate() - 7
      )
    ) {
      return "warning";
    } else {
      return "success";
    }
  };

  const getColorStatus = (status) => {
    if (status?.toLowerCase() == "offline") {
      return "danger";
    } else if (status?.toLowerCase() == "online") {
      return "success";
    } else {
      return "warning";
    }
  };

  const [form, setForm] = useState({
    nama: "",
    ip: "",
    portal: "",
    exp: "",
  });

  const [isEdit, setIsEdit] = useState(false);
  const [temp, setTemp] = useState(null);

  const setEdit = (data, index) => {
    setIsEdit(true);
    setForm(data);
    setTemp({ ...data, index });
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      nama: "",
      ip: "",
      portal: "",
      exp: "",
    });
    setTemp(null);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    let pload = {};

    let key = isEdit ? "clientmonitoring/updatenew" : "clientmonitoring/create";
    let method = isEdit ? "put" : "post";

    if (isEdit) {
      pload = { ...form, id: temp.id };
    } else {
      pload = { ...form };
    }

    const payload = JSON.stringify({ ...pload });
    // console.log(payload);
    // return;
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key,
          method,
          payload,
        })
      )
    );

    if (res?.data?.code == 200) {
      toast.success(`Success ${isEdit ? "Update" : "Tambah"} Client`);
      getMonitoring();
      if (isEdit) {
        cancelEdit();
      }
      setForm({
        nama: "",
        ip: "",
        portal: "",
        exp: "",
      });
    }
  };

  const deleteAction = async (item) => {
    const pload = {
      id: item.id,
    };

    const payload = JSON.stringify({ ...pload });
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "clientmonitoring/delete",
          method: "delete",
          payload,
        })
      )
    );

    if (res?.data?.code == 200) {
      toast.success("Berhasil Menghapus Client");
      getMonitoring();
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.nama?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="monitoring-client" />
          <Content>
            <PageHeading title="Monitoring Client" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Nama Client"
                    onChange={handleChange("nama")}
                    placeholder="Misal Juber, dll"
                    value={form?.nama}
                    readOnly={isEdit}
                  />
                  <Input
                    label="IP"
                    onChange={handleChange("ip")}
                    placeholder="Misal 192.168.0.1"
                    value={form?.ip}
                  />
                  <Input
                    label="Portal"
                    onChange={handleChange("portal")}
                    placeholder="Misal webadmin.com"
                    value={form?.portal}
                  />
                  <div style={{ width: "30%" }}>
                    <Input
                      label="Expired"
                      onChange={handleChange("exp")}
                      placeholder=""
                      value={form?.exp}
                      type="date"
                    />
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="card-title">Monitoring Client</h4>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="mr-2">Refresh</span>
                        <select
                          className="form-control"
                          style={{ width: 100 }}
                          onChange={(e) => setTimeRefresh(e.target.value)}
                          value={timeRefresh}
                        >
                          <option value={5}>5 Detik</option>
                          <option value={10}>10 Detik</option>
                          <option value={15}>15 Detik</option>
                          <option value={30}>30 Detik</option>
                          <option value={60}>1 Menit</option>
                          <option value={120}>2 Menit</option>
                          <option value={180}>3 Menit</option>
                          <option value={240}>4 Menit</option>
                          <option value={300}>5 Menit</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> # </th>
                        <th className=""> Nama Client </th>
                        <th className=""> Template FE </th>
                        <th className=""> Expired </th>
                        <th className=""> Portal </th>
                        <th className=""> IP </th>
                        <th className=""> Status </th>
                        <th className=""> Status Portal</th>
                        <th className=""> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">{item?.nama}</td>
                            <td className="">{item?.home}</td>
                            <td>
                              <span
                                className={`badge bg-${getColorClass(
                                  item?.exp
                                )}`}
                              >
                                {item?.exp
                                  ? formatDate(item?.exp, "date monthName year")
                                  : "-"}
                              </span>
                            </td>
                            <td className="">
                              <a
                                href={item?.portal}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item?.portal}
                              </a>
                            </td>
                            <td className="">{item?.ip}</td>
                            <td className="">
                              <div
                                className={`bg-${getColorStatus(item?.status)}`}
                                style={{
                                  height: 30,
                                  width: 30,
                                  borderRadius: 99,
                                  margin: "0 auto",
                                }}
                              />
                            </td>
                            <td className="">
                              <div
                                className={`bg-${getColorStatus(
                                  item?.statusportal
                                )}`}
                                style={{
                                  height: 30,
                                  width: 30,
                                  borderRadius: 99,
                                  margin: "0 auto",
                                }}
                              />
                            </td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setEdit(item, index);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  Keterangan
                  <br />
                  <div className="d-flex align-items-center mt-2">
                    <div
                      className="bg-danger mr-2"
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 99,
                      }}
                    />
                    <b>Offline</b>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <div
                      className="bg-success mr-2"
                      style={{
                        height: 30,
                        width: 30,
                        borderRadius: 99,
                      }}
                    />
                    <b>Online</b>
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(MonitoringClient);
