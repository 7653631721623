import React from "react";

function ItemMenu({ item }) {
  // let parseJson = JSON.parse(item.json || {});
  //fix SyntaxError: Bad control character in string literal in JSON at position 400
  // eslint-disable-next-line no-control-regex
  const parseJson = JSON.parse(item.json.replace(/[\u0000-\u0019]+/g, ""));
  // console.log(parseJson);
  const { status } = item;
  return (
    <div className={`card ${status === "AKTIF" ? "bg-white" : "bg-danger"}`}>
      <div className="panel-body mt-3">
        <img
          src={parseJson?.icon}
          style={{ height: "3rem", width: "3rem", objectFit: "contain" }}
          alt="i"
        />
        <h6>{item.nama}</h6>
        <h6>({item.id})</h6>
        <h6>Lokasi {item?.lokasi ?? "-"}</h6>
        <h6>Posisi {parseJson?.posisi ?? "-"}</h6>
      </div>
    </div>
  );
}

export default ItemMenu;
