/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function Whitelist() {
  const [result, setResult] = useState([]);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    idreseller: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [desc, setDesc] = useState("");
  const [asli, setAsli] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [logoutAll, setLogoutAll] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(BridgeService.JbDelivery({ key: "allsetting" }))
    );
    const result = res?.data?.lobj || [];
    const obj = result.filter((item) => item?.idapps == "apps.whitelist")[0];
    setAsli(obj);
    setDesc(obj?.desc);
    const data = JSON.parse(obj?.value).data;
    const active = JSON.parse(obj?.value).isActive;
    const logoutAll = JSON.parse(obj?.value).isAllLogout;
    setResult(data || []);
    setActive(active);
    setLogoutAll(logoutAll);
  };

  const updateAction = async () => {
    const selected = selectedData;
    // change value by findItem
    const newResult = result.map((item, index) => {
      if (selected?.index == index) {
        return form;
      } else {
        return item;
      }
    });
    saveAction(newResult);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async (pload) => {
    let data;
    if (pload) {
      data = pload;
    } else {
      data = [...result, form];
    }
    const value = JSON.stringify({
      data,
      isActive: active,
      isAllLogout: logoutAll,
    });
    const payload = JSON.stringify({
      list: [{ idapps: asli.idapps, desc: asli.desc, value }],
    });
    console.log(payload);
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "savesetting",
          payload,
        })
      )
    );
    clearForm();
    getData();
    setIsEdit(false);
  };

  const deleteAction = async (item, index) => {
    //remove index
    let tempData = null;
    result.forEach((item, i) => {
      console.log(i);
      if (i == index) {
        tempData = item;
        console.log("masuk sini");
      }
    });
    console.log(tempData, index);
    const newResult = result.filter((item) => item !== tempData);
    // console.table(newResult);
    saveAction(newResult);
    // saveAction(data);
  };

  const clearForm = () => {
    setForm({
      idreseller: "",
    });
  };

  const changeActive = async () => {
    let data = result;
    const isActive = active;
    const value = JSON.stringify({
      data,
      isActive: !isActive,
      isAllLogout: false,
    });
    const payload = JSON.stringify({
      list: [{ idapps: asli.idapps, desc: asli.desc, value }],
    });
    console.log(payload);
    // return;
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "savesetting",
          payload,
        })
      )
    );
    clearForm();
    getData();
    setIsEdit(false);
  };

  const changeLogout = async () => {
    let data = result;
    const isAllLogout = logoutAll;
    const value = JSON.stringify({
      data,
      isActive: true,
      isAllLogout: !isAllLogout,
    });
    const payload = JSON.stringify({
      list: [{ idapps: asli.idapps, desc: asli.desc, value }],
    });
    console.log(payload);
    // return;
    await dispatch(
      _fetch(
        BridgeService.JbDelivery({
          key: "savesetting",
          payload,
        })
      )
    );
    clearForm();
    getData();
    setIsEdit(false);
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="whitelist" />
          <Content>
            <PageHeading title="Logout Paksa" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      id="id"
                      value="option1"
                      checked={active}
                      onChange={() => {
                        setActive(!active);
                        changeActive();
                      }}
                    />
                    <label className="custom-control-label" for="id">
                      Aktifkan?
                    </label>
                  </div>
                  {active && (
                    <>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="id2"
                          value="option1"
                          checked={logoutAll}
                          onChange={() => {
                            setLogoutAll(!logoutAll);
                            changeLogout();
                          }}
                        />
                        <label className="custom-control-label" for="id2">
                          Logout Semua?
                        </label>
                      </div>
                      <div className="alert alert-danger d-flex align-items-center">
                        Aktifkan logout semua jika ingin menglogout semua
                        client, nonaktifkan jika ingin menglogout client
                        tertentu lalu isikan pada kolom dibawah
                      </div>
                    </>
                  )}
                </Card>
                {active && !logoutAll && (
                  <>
                    <Card>
                      <Input
                        label="IDRS"
                        onChange={handleChange("idreseller")}
                        placeholder="IDRS"
                        value={form?.idreseller}
                      />
                      <div className="d-flex justify-content-center align-items-end">
                        <Button
                          title={"Simpan"}
                          type="warning"
                          onClick={() =>
                            isEdit ? updateAction() : saveAction()
                          }
                        />
                        {isEdit && (
                          <Button
                            title="Batal Edit"
                            type="danger"
                            onClick={() => cancelEdit()}
                            style={{ width: 130, marginLeft: 10 }}
                          />
                        )}
                      </div>
                    </Card>
                    <Card>
                      <div className="alert alert-danger d-flex align-items-center">
                        {desc}. Silahkan masukkan IDRS yang akan dilogout paksa.
                      </div>
                      <table
                        className="table table-hover table-checkable order-column full-width"
                        id="example4"
                      >
                        <thead>
                          <tr>
                            <th className=""> IDRS </th>
                            <th className="text-center">Aksi</th>
                          </tr>
                        </thead>
                        <tbody>
                          {result?.map((item, index) => {
                            return (
                              <tr className="odd gradeX" key={index}>
                                <td className="">{item.idreseller}</td>
                                <td className="center">
                                  <a
                                    className="btn btn-tbl-edit-no-round btn-xs"
                                    onClick={() => {
                                      setIsEdit(true);
                                      setForm({
                                        ...item,
                                        idreseller: item.idreseller,
                                      });
                                      setSelectedData({ ...item, index });
                                    }}
                                  >
                                    <i className="fa fa-pencil"></i> Edit
                                  </a>
                                  <a
                                    className="btn btn-tbl-delete btn-xs"
                                    onClick={() => {
                                      deleteAction(item, index);
                                    }}
                                  >
                                    <i className="fa fa-trash-o "></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Card>
                  </>
                )}
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(Whitelist);
