/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function SystemKata() {
  const [form, setForm] = useState({
    saldo: "Saldo",
    pay: "Pay",
    matauang: "Rp.",
    downline: "downline",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "GET",
          key: "systemkata",
        })
      )
    );
    console.log(res);
    setForm(res?.data?.data);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    const payload = {
      ...form,
    };
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "POST",
          key: "systemkata/update",
          payload: JSON.stringify({ value: payload }),
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="system-kata" />
          <Content>
            <PageHeading title="System Kata (Rp, Pay, Saldo)" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Saldo"
                    onChange={handleChange("saldo")}
                    placeholder="default : Saldo"
                    value={form?.saldo}
                  />
                  <Input
                    label="Pay"
                    onChange={handleChange("pay")}
                    placeholder="default : Pay"
                    value={form?.pay}
                  />
                  <Input
                    label="Mata Uang"
                    onChange={handleChange("matauang")}
                    placeholder="default : Rp."
                    value={form?.matauang}
                  />
                  <Input
                    label="Downline"
                    onChange={handleChange("downline")}
                    placeholder="default : downline"
                    value={form?.downline}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(SystemKata);
