/* eslint-disable eqeqeq */
//eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Container,
  Content,
  Input,
  Modal,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function ListBlokirMerchant() {
  const dispatch = useDispatch();
  let pathname = window.location.pathname;
  pathname = pathname.split("-")[1];
  const [result, setResult] = useState(null);
  const [resultAsli, setResultAsli] = useState(null);
  const [blockState, setBlockState] = useState({
    id: null,
    showModal: false,
    txtReason: null,
    item: null,
  });

  useEffect(() => {
    getStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStore = async () => {
    let key = pathname == "food" ? "food" : "jasa";
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: `merchant/${key}`,
          method: "get",
        })
      )
    );
    console.log(res.data);
    setResult(res?.data?.lobj);
    setResultAsli(res?.data?.lobj);
  };

  const blockAction = async (item) => {
    const payload = {
      id: item.id,
      isvisible: item.isvisible == 1 ? 0 : 1,
    };
    console.log(payload);
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "merchant/update",
          method: "post",
          payload: JSON.stringify(payload),
        })
      )
    );
    setBlockState({ showModal: false, txtReason: null, id: null });
    // console.log(res.data.lobj);
    getStore();
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="blokir" />
          <Content>
            <PageHeading
              title={`List Merchant ${
                pathname == "food" ? "Food" : "Layanan Jasa"
              }`}
            />
            <div className="row">
              <div className="col-12">
                <Input
                  placeholder="Cari sesuatu..."
                  onChange={(e) =>
                    setResult(
                      resultAsli?.filter((it) =>
                        it.nama
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    )
                  }
                />
                <div className="row clearfix">
                  {result?.map((it, id) => {
                    return (
                      <div
                        className="col-lg-4 col-md-6 col-12 col-sm-6"
                        key={id}
                      >
                        <div className="card blogThumb">
                          <div
                            className="thumb-center"
                            style={{ backgroundColor: "lightgrey" }}
                          >
                            <img
                              className="img-responsive"
                              alt="user"
                              src={
                                it.gambar != "" || it.gambar
                                  ? it.gambar
                                  : "https://www.hartlepoolwater.co.uk/globalassets/page-not-found.png"
                              }
                              style={{ height: "9rem", objectFit: "contain" }}
                            />
                          </div>
                          <div
                            className="vehicle-name bg-b-danger"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <div style={{ fontSize: 18 }}>{it.nama}</div>
                          </div>
                          <div
                            className="vehicle-box"
                            style={{ textAlign: "justify", minHeight: 85 }}
                          >
                            {it.alamat}
                          </div>
                          <div className="center py-2">
                            <div
                              className={`btn ${
                                it.isvisible == 0
                                  ? "btn-tbl-edit"
                                  : "btn-tbl-delete"
                              } btn-xs mr-2 btn-no-full-round`}
                              onClick={() => blockAction(it)}
                            >
                              {it.isvisible == 0 ? "Unblokir" : "Blokir"}
                              <i
                                className={
                                  it.isvisible == 0
                                    ? "fa fa-check"
                                    : "fa fa-ban"
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Content>
          <Modal
            show={blockState.showModal}
            onHide={() => setBlockState({ ...blockState, showModal: false })}
          >
            <Input
              label="Alasan"
              onChange={(val) =>
                setBlockState({ ...blockState, txtReason: val.target.value })
              }
              placeholder="Misal toko palsu"
              value={blockState.txtReason}
            />
            <Button
              title={blockState?.item?.isvisible == 1 ? "Unblokir" : "Blokir"}
              type={blockState?.item?.isvisible == 1 ? "warning" : "danger"}
              onClick={() => blockAction(2)}
            />
          </Modal>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default ListBlokirMerchant;
