/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Content,
  MarkupText,
  PageHeading,
  Pagination,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA, cipher } from "../../helpers";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
import { withRouter } from "react-router-dom";
import chat_api, { getSocketApi } from "../../api/websocket";

function PeriksaChat({ history }) {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = React.useState("");

  const enc = cipher("akuimuet");

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getList = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    let data = await chat_api({
      method: getSocketApi.chat.list_chat_admin.method,
      url: getSocketApi.chat.list_chat_admin.url,
      payload: {
        have_admin: false,
        page: parseInt(page),
        client_id: username,
        search: query,
      },
    });
    if (data?.success) {
      toast.success("Data berhasil diambil");
      console.log(data);
      setResult(data?.data?.data);
      setTotalPage(data?.data?.data?.length);
      setCount(data?.data?.count);
    } else {
      toast.error("Data gagal diambil");
    }
    setLoading(false);
  };

  const removeChat = async (id) => {
    let data = await chat_api({
      method: getSocketApi.chat.delete_chat_admin.method,
      url: getSocketApi.chat.delete_chat_admin.url,
      payload: {
        id,
      },
    });
    if (data?.success) {
      toast.success(data?.msg);
    } else {
      toast.error("Data gagal dihapus");
    }
    getList();
  };

  const onPageChange = (data) => {
    count > 0 ? setTotalPage(data.currentPage) : setTotalPage(1);
    // setPage(data.currentPage);
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onChangeSearch={(val) => setQuery(val)}
          onSearch={() => getList()}
        />
        <Container>
          <Sidebar active="periksachat" />
          <Content>
            <PageHeading title="Periksa Chat" />
            <div className="row">
              <div className="col-12">
                <div className="row clearfix">
                  {result?.map((it, id) => {
                    return (
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 text-center text-dark">
                        <Link
                          key={id}
                          to={{
                            pathname: `/see-check-chat`,
                            search: `?id=${enc(JSON.stringify(it))}`,
                          }}
                        >
                          <div className="card">
                            <div className="panel-body">
                              <h6>
                                {it.chat_idrs} -{" "}
                                <MarkupText msg={it.chat_nama} />
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <i
                          className="fa fa-trash"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            position: "absolute",
                            right: "20px",
                            top: "5px",
                            zIndex: 999,
                          }}
                          onClick={() => removeChat(it.chat_id)}
                        ></i>
                      </div>
                    );
                  })}
                </div>
                {!loading && (
                  <Pagination
                    totalRecords={count}
                    pageLimit={totalPage}
                    pageNeighbours={2}
                    onPageChanged={(f) => onPageChange(f)}
                  />
                )}
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(PeriksaChat);
