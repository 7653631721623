/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Button,
  // Button,
  Card,
  Container,
  Content,
  Input,
  // Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";
function HideMenuLokasi({ history, location }) {
  const [result, setResult] = useState([]);
  const [oldItem, setOldItem] = useState([]);
  const [asli, setAsli] = useState([]);
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    idlokasi: "",
    isHidden: "1",
    menualternatif: "",
  });
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "systemmenu/lokasi",
          method: "get",
        })
      )
    );
    console.log(res);
    const data = res?.data?.data?.lokasiStatus;
    setResult(data);
    setOldItem(data);
    setAsli(data);
  };

  // const updateCategory = async () => {
  //   const payload = {
  //     id: form?.id,
  //     name: form?.name,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "put",
  //         key: "ref_courier",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  //   setForm({
  //     id: "",
  //     name: "",
  //   });
  // };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      idlokasi: "",
      isHidden: "",
      menualternatif: "",
    });
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  // const saveAction = async () => {
  //   const payload = {
  //     name: form.name,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "post",
  //         key: "ref_courier",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  //   setForm({ name: "" });
  // };

  // const deleteAction = async (id) => {
  //   const payload = {
  //     id,
  //   };
  //   await dispatch(
  //     _fetch(
  //       BridgeService.JbMarket({
  //         method: "delete",
  //         key: "ref_courier",
  //         payload: JSON.stringify(payload),
  //       })
  //     )
  //   );
  //   getData();
  // };

  const setAction = async () => {
    const payload = {
      idlokasi: form.idlokasi,
      isHidden: form.isHidden == 1 ? true : false,
      menualternatif: form.isHidden == 1 ? form.menualternatif : "",
    };

    // console.log(payload);
    // return;
    await dispatch(
      _fetch(
        BridgeService.JbMarket({
          method: "post",
          key: "systemmenu/update/lokasi",
          payload: JSON.stringify(payload),
        })
      )
    );
    getData();
  };

  return (
    <>
      <Wrapper>
        <Topbar
          onSearch={() => {
            const item = oldItem.filter((item) =>
              item?.idlokasi?.toLowerCase().includes(query.toLowerCase())
            );
            setResult(item);
          }}
          onChangeSearch={(val) => setQuery(val)}
        />
        <Container>
          <Sidebar active="hide-menu-lokasi" />
          <Content>
            <PageHeading title="Hide Menu Lokasi" />
            <div className="row">
              <div className="col-12">
                {isEdit && (
                  <Card>
                    <Input
                      label="ID Lokasi"
                      onChange={handleChange("idlokasi")}
                      placeholder="contoh ID Lokasi"
                      value={form?.idlokasi}
                      readOnly
                    />
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        name="type"
                        onChange={(e) => {
                          handleChange("isHidden")(e);
                        }}
                        value={form?.isHidden}
                      >
                        <option value="0">Tampil</option>
                        <option value="1">Sembunyi</option>
                      </select>
                    </div>
                    {form.isHidden == "1" && (
                      <div className="form-group">
                        <label>Menu Alternatif</label>
                        <select
                          className="form-control"
                          name="type"
                          onChange={(e) => {
                            handleChange("menualternatif")(e);
                          }}
                          value={form?.menualternatif}
                        >
                          <option value="">Tidak ada</option>
                          {asli
                            ?.filter((x) => x.idlokasi != form.idlokasi)
                            ?.map((item, index) => {
                              return (
                                <option key={index} value={item?.idlokasi}>
                                  {item?.idlokasi}
                                </option>
                              );
                            })}
                        </select>
                        <small className="text-muted">
                          Pilih tidak ada jika tidak ingin mengganti lokasi ini
                          dengan lokasi lain, artinya nanti lokasi itu
                          kosong/sembunyi. Jika ingin mengganti lokasi ini
                          dengan lokasi lain, maka pilih lokasi lain pada
                          pilihan diatas
                        </small>
                      </div>
                    )}
                    <div className="d-flex justify-content-center align-items-end">
                      <Button
                        title={"Simpan"}
                        type="warning"
                        onClick={() => (isEdit ? setAction() : setAction())}
                      />
                      {isEdit && (
                        <Button
                          title="Batal Edit"
                          type="danger"
                          onClick={() => cancelEdit()}
                          style={{ width: 130, marginLeft: 10 }}
                        />
                      )}
                    </div>
                  </Card>
                )}
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> ID Lokasi </th>
                        <th className=""> Status </th>
                        <th className=""> Lokasi Menu Pengganti </th>
                        <th className="text-center">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{item?.idlokasi}</td>
                            <td className="">
                              <span
                                className={`badge badge-pill ${
                                  item.isHidden == false
                                    ? "badge-warning"
                                    : "badge-danger"
                                }`}
                              >
                                {item?.isHidden == true ? "Sembuyi" : "Tampil"}
                              </span>
                            </td>
                            <td className="">{item?.menualternatif}</td>
                            <td className="center">
                              {/* <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setIsEdit(true);
                                  setForm({
                                    id: item?.id,
                                    name: item?.name,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(item?.id);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a> */}
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  // setAction(item);
                                  setIsEdit(true);
                                  setForm({
                                    idlokasi: item?.idlokasi,
                                    isHidden: item?.isHidden ? "1" : "0",
                                    menualternatif: item?.menualternatif,
                                  });
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(HideMenuLokasi);
