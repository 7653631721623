/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { BtoA } from "../../helpers";

function PhoneAdmin({ history, location }) {
  const [result, setResult] = useState([]);
  const [form, setForm] = useState({
    phone: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [temp, setTemp] = useState(null);

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const resultUrl = await axios.get(
      `https://api.setting.berkah-ts.my.id/juber/auth/client/profile/${username}?access_token=${token}`
    );
    const oldData = resultUrl.data.lobj;
    const datas = oldData?.map((item) => {
      return {
        phone: item,
      };
    });
    setResult(datas);
  };

  const setEdit = (data) => {
    setIsEdit(true);
    setForm(data);
    setTemp(data);
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      value: "",
    });
    setTemp(null);
  };

  const handleChange = (name) => (e) => {
    setForm({
      ...form,
      [name]: e.target.value,
    });
  };

  const saveAction = async () => {
    let phones = [];
    if (isEdit) {
      //find index
      const index = result.findIndex((item) => item.phone == temp.phone);
      //change the value by index
      result[index] = form;
      setResult([...result]);
      phones = result.map((item) => {
        return item.phone;
      });
    } else {
      const resPhone = result;

      resPhone.push(form);

      phones = result.map((item) => {
        return item.phone;
      });
    }

    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const body = {
      listPhone: phones,
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/client/profile/save/${username}?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success(`Success ${isEdit ? "Update" : "Tambah"} No Admin`);
      getSetting();
      if (isEdit) {
        cancelEdit();
      }
      setForm({
        phone: "",
      });
    }
  };

  const deleteAction = async (index) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const resultApi = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(resultApi.data);

    const token = resultApi.data.access_token;

    const phones = result.map((item) => {
      return item.phone;
    });

    //remove by index
    phones.splice(index, 1);

    const body = {
      listPhone: phones,
    };

    console.log(body);

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/client/profile/save/${username}?access_token=${token}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (resultUrl?.data?.code == 200) {
      toast.success("Success Hapus No Admin");
      getSetting();
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="phone-admin" />
          <Content>
            <PageHeading title="Nomor HP Admin" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="No HP"
                    onChange={handleChange("phone")}
                    placeholder="081xxxxxxxx"
                    value={form?.phone}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
                <Card>
                  <table
                    className="table table-hover table-checkable order-column full-width"
                    id="example4"
                  >
                    <thead>
                      <tr>
                        <th className=""> # </th>
                        <th className=""> No HP </th>
                        <th className="center"> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.map((item, index) => {
                        return (
                          <tr className="odd gradeX" key={index}>
                            <td className="">{index + 1}</td>
                            <td className="">{item.phone}</td>
                            <td className="center">
                              <a
                                className="btn btn-tbl-edit-no-round btn-xs"
                                onClick={() => {
                                  setEdit(item);
                                }}
                              >
                                <i className="fa fa-pencil"></i> Edit
                              </a>
                              <a
                                className="btn btn-tbl-delete btn-xs"
                                onClick={() => {
                                  deleteAction(index);
                                }}
                              >
                                <i className="fa fa-trash-o "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(PhoneAdmin);
