/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import axios from "axios";
import { BtoA } from "../../helpers";

function BlockMenuLinkqu() {
  const [form, setForm] = useState({
    nextpage: "",
    blockLinkQuWithdraw: false,
    blockLinkQuTrfBank: false,
    blockLinkQuTrfVA: false,
    blockLinkQuQR: false,
  });

  // const userSave = localStorage.getItem("credential");
  //     const btoa = BtoA("kocak123");
  //     const username = btoa(userSave);

  //   const [asli, setAsli] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    if (!selectedClient) return;
    getData();
  }, [selectedClient]);

  useEffect(() => {
    getCLient();
    // getData();
  }, []);

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  const getData = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      desc: "systemmenu",
      value: JSON.stringify({ clientId: selectedClient }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );
    const datas = resultUrl.data.lobj[0];
    // setAsli(datas);
    setForm({
      nextpage: datas?.nextpage,
      blockLinkQuWithdraw: datas?.blockLinkQuWithdraw,
      blockLinkQuTrfBank: datas?.blockLinkQuTrfBank,
      blockLinkQuTrfVA: datas?.blockLinkQuTrfVA,
      blockLinkQuQR: datas?.blockLinkQuQR,
    });
  };

  const saveAction = async (pload) => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      nextpage: form.nextpage,
      blockLinkQuWithdraw: form.blockLinkQuWithdraw,
      blockLinkQuTrfBank: form.blockLinkQuTrfBank,
      blockLinkQuTrfVA: form.blockLinkQuTrfVA,
      blockLinkQuQR: form.blockLinkQuQR,
    };

    const body = {
      desc: "systemmenu/update",
      value: JSON.stringify({ clientId: selectedClient, value: payload }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      body
    );

    if (resultUrl.data.msg == "ok") {
      toast.success("Berhasil");
      getData();
      return;
    } else {
      return toast.error("Gagal");
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="block-menu-linkqu" />
          <Content>
            <PageHeading title="Block Menu Topup" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {selectedClient && (
                    <>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="va"
                          value="option1"
                          checked={form.blockLinkQuWithdraw}
                          onChange={() => {
                            setForm({
                              ...form,
                              blockLinkQuWithdraw: !form.blockLinkQuWithdraw,
                            });
                          }}
                        />
                        <label className="custom-control-label" for="va">
                          Block Withdraw
                        </label>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="qr"
                          value="option1"
                          checked={form.blockLinkQuTrfBank}
                          onChange={() => {
                            setForm({
                              ...form,
                              blockLinkQuTrfBank: !form.blockLinkQuTrfBank,
                            });
                          }}
                        />
                        <label className="custom-control-label" for="qr">
                          Block Transfer Bank
                        </label>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="id"
                          value="option1"
                          checked={form.blockLinkQuTrfVA}
                          onChange={() => {
                            setForm({
                              ...form,
                              blockLinkQuTrfVA: !form.blockLinkQuTrfVA,
                            });
                          }}
                        />
                        <label className="custom-control-label" for="id">
                          Block Transfer VA
                        </label>
                      </div>
                      <div className="custom-control custom-switch">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                          id="asd"
                          value="option1"
                          checked={form.blockLinkQuQR}
                          onChange={() => {
                            setForm({
                              ...form,
                              blockLinkQuQR: !form.blockLinkQuQR,
                            });
                          }}
                        />
                        <label className="custom-control-label" for="asd">
                          Block QR
                        </label>
                      </div>
                    </>
                  )}
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(BlockMenuLinkqu);
