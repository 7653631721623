/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import { useDispatch } from "react-redux";
import { _fetch } from "../../redux/actions/global";
import { BridgeService } from "../../services";

function IntruksiTopup() {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    header: "",
  });
  const [isi, setIsi] = useState([]);
  const [formIsi, setFormIsi] = useState({
    no: "",
    value: "",
  });

  const getTopup = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "carabayartopup",
          method: "get",
        })
      )
    );
    const data = res?.data?.data?.value;
    const parseData = JSON.parse(data);
    console.table(parseData?.isi);
    setForm({ header: parseData?.header });
    setIsi(parseData?.isi);
  };

  useEffect(() => {
    getTopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [edit, setEdit] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const handleChangeHeader = (name) => (e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleChange = (name) => (e) => {
    setFormIsi({ ...formIsi, [name]: e.target.value });
  };

  const saveAction = async () => {
    const res = await dispatch(
      _fetch(
        BridgeService.JbMarket({
          key: "carabayartopup/update",
          method: "post",
          payload: JSON.stringify({
            value: {
              header: form?.header,
              isi: isi,
            },
          }),
        })
      )
    );

    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      toast.success(data?.message);
      getTopup();
      setFormIsi({
        no: "",
        value: "",
      });
      setTambah(false);
      setIsEdit(false);
    }
  };

  const cancelEdit = () => {
    setIsEdit(false);
    setForm({
      name: "",
      package_name: "",
    });
  };

  const [tambah, setTambah] = useState(false);

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="intruksi-topup" />
          <Content>
            <PageHeading title="Instruksi Topup" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <Input
                    label="Header"
                    onChange={handleChangeHeader("header")}
                    placeholder="Misal Cara Topup"
                    value={form?.header}
                  />
                  <label>Isi</label>
                  {isi?.map((item, index) => (
                    <div className="d-flex align-items-center" key={index}>
                      <small key={index}>
                        {item?.no}. {item?.value}
                      </small>
                      <i
                        className="fas fa-edit ml-3 cursor-pointer"
                        onClick={() => {
                          setEdit(index);
                          setFormIsi({
                            no: item?.no,
                            value: item?.value,
                          });
                          setIsEdit(true);
                        }}
                      ></i>
                      <i
                        className="fas fa-trash ml-3 cursor-pointer"
                        onClick={() => {
                          const newData = isi.filter((item, i) => i !== index);
                          setIsi(newData);
                        }}
                      ></i>
                    </div>
                  ))}
                  <h5
                    className="text-dark mt-2 mb-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTambah(!tambah);
                    }}
                  >
                    {tambah ? "- Sembunyikan" : "+ Tambahkan"}
                  </h5>
                  {tambah && (
                    <div className="d-flex align-items-end">
                      <Input
                        label="No"
                        onChange={handleChange("no")}
                        placeholder="Misal 1"
                        value={formIsi?.no}
                        style={{ width: 100 }}
                      />
                      <div className="ml-3 mr-3"></div>
                      <Input
                        label="Value"
                        onChange={handleChange("value")}
                        placeholder="Intruskinya"
                        value={formIsi?.value}
                        style={{ width: 400 }}
                      />
                      <div className="ml-3 mr-3"></div>
                      <Button
                        title="Tambahkan"
                        type="primary"
                        block={false}
                        onClick={() => {
                          setIsi([...isi, formIsi]);
                          setFormIsi({
                            no: "",
                            value: "",
                          });
                        }}
                      />
                    </div>
                  )}
                  {isEdit && (
                    <div className="d-flex align-items-end w-100">
                      <Input
                        label="No"
                        onChange={handleChange("no")}
                        placeholder="Misal 1"
                        value={formIsi?.no}
                        style={{ width: 100 }}
                      />
                      <div className="ml-3 mr-3"></div>
                      <Input
                        label="Value"
                        onChange={handleChange("value")}
                        placeholder="Intruskinya"
                        value={formIsi?.value}
                        style={{ width: 400 }}
                      />
                      <div className="ml-3 mr-3"></div>
                      <Button
                        title="Simpan"
                        type="primary"
                        block={false}
                        onClick={() => {
                          const newIsi = isi;
                          newIsi[edit] = formIsi;
                          setIsi(newIsi);
                          setFormIsi({
                            no: "",
                            value: "",
                          });
                          setIsEdit(false);
                        }}
                      />
                    </div>
                  )}
                  <h5
                    className="text-warning mt-2 mb-3"
                    style={{ cursor: "pointer" }}
                  >
                    Ketika sudah selesai baik menambahkan, mengedit dan
                    menghapus klik simpan untuk menyimpan perubahan
                  </h5>
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                    {isEdit && (
                      <Button
                        title="Batal Edit"
                        type="danger"
                        onClick={() => cancelEdit()}
                        style={{ width: 130, marginLeft: 10 }}
                      />
                    )}
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default IntruksiTopup;
